import { METHOD_TYPES } from 'constants/index'
import axiosInstance from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'

export const getSiteDataService = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'site-detail', {}, {
    server: microServices.SERVICE_URL_1
  })
}

export const getSiteSettingDataService = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'settings', {}, {
    server: microServices.SERVICE_URL_1
  })
}
