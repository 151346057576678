export default {
  404: '404',
  register: 'Registro',
  login: 'Conecte-se',
  grand: 'Grande',
  major: 'Principal',
  minor: 'Menor',
  mini: 'Mini',
  crashGameTitle: 'Colidir',
  betAmount: 'Valor da aposta',
  profit: 'Lucro',
  payout: 'Pagamento alvo',
  chance: 'Chance de ganhar',
  betBtn: 'Aposta',
  autoCashout: 'Saque automático',
  btnHalf: '½',
  btnDouble: '2×',
  btnMax: 'Máx.',
  wagered: 'Apostado',
  lost: 'Perdido',
  won: 'Ganho',
  streak: 'Onda',
  highestWin: 'Maior vitória',
  highestLost: 'Maior perda',
  highestBet: 'Aposta mais alta',
  maxStreak: 'Sequência máxima',
  maxProfit: 'Lucro máximo: ',
  bal: 'Bal: ',
  messagesNoBetHistory: 'Seu histórico de apostas está vazio',
  messagesCopyNotSupported: 'Seu navegador não suporta cópia automática para a área de transferência.\n',
  messagesCopied: 'Endereço copiado.',
  deposit: 'Depósito',
  statReset: 'Redefinir estatísticas',
  rollOver: 'Rolar',
  inputFieldBtnHalf: '½',
  inputFieldBtnDouble: '2×',
  inputFieldBtnMax: 'máx.',
  buttonTextPlaceBet: 'Fazer aposta',
  buttonTextCancelBet: 'Cancelar aposta',
  buttonTextEscapeBet: 'Sacar',
  buttonTextStartAutoBet: 'Iniciar aposta automática',
  buttonTextCancelAutoBet: 'Cancelar aposta automática',
  buttonTextLoading: 'Carregando',
  tabsMyBets: 'Minhas apostas',
  tabsDeposit: 'Depósito',
  tabsCashout: 'Saques',
  tabsAllbets: 'Todas as apostas',
  tabsTopbets: 'Melhores apostas',
  tabsBonus: 'Bônus',
  tabsjackpot: 'Jackpot',
  tabsBetHistory: 'Histórico de apostas',
  timerTextCurrentPayout: 'Pagamento atual',
  timerTextStarting: 'Começando em',
  timerTextSec: 'é',
  timerTextFlewAway: 'Falha',
  timerTextWait: 'Por favor, aguarde..',
  timerTextPreparingRound: 'Preparando rodada',
  timerTextLoadingRound: 'Carregando Rodada',
  comingSoon: 'Em breve !',
  labelsProfitOnWin: 'Lucro na vitória',
  inputsNoOfBets: 'Número de apostas',
  inputsAuto: 'Auto',
  inputsHalf: '1/2',
  inputsDouble: '2×',
  inputsStart: 'Começar',
  inputsCancel: 'Cancelar',
  buttonsMin: 'Mínimo',
  buttonsMax: 'Máx.',
  prediction: 'Predição',
  luckyNumber: 'Número da sorte',
  multiplier: 'Multiplicador',
  chanceToWin: 'Chance de ganhar',
  bets: 'Apostas',
  depositBtn: 'Depósito',
  rollUnder: 'Role para baixo',
  symbolsX: 'x',
  symbolsPercent: '%',
  navBarTitle: 'História',
  navBarMyBets: 'Minhas apostas',
  navBarAllBets: 'Todas as apostas',
  navBarHighRollers: 'Grandes apostadores',
  navBarRoundHistory: 'História da Rodada',
  navBarTotal: 'Apostas totais',
  topHeaderBetId: 'ID da aposta',
  topHeaderUser: 'Do utilizador',
  topHeaderPayout: 'Pagamento',
  topHeaderAmount: 'Quantia',
  topHeaderTime: 'Tempo',
  topHeaderProfit: 'Lucro',
  topHeaderRoundCrashed: 'Falha',
  topHeaderRoundEscape: '@',
  topHeaderRoundRoundHash: 'Cerquilha',
  topHeaderRoundBet: 'Aposta',
  topHeaderRoundProfit: 'Lucro',
  topHeaderRoundSignature: 'Assinatura',
  roundHistoryTitle: 'INFORMAÇÕES DO JOGO',
  roundHistoryDescGame: 'Jogo',
  roundHistoryDescCrashAt: 'Travou em:',
  roundHistoryDescDate: 'Data :',
  roundHistoryDescBetHeader: 'Aposta',
  roundHistoryTableTitlePlayer: 'JOGADOR',
  roundHistoryTableTitleBet: 'APOSTA',
  roundHistoryTableTitleCashedOut: 'SAQUEADO',
  roundHistoryTableTitleProfit: 'LUCRO',
  roundHistoryTableTitleBetId: 'ID DA APOSTA',
  roundHistoryMessagesNoBetHistory: 'Seu histórico de apostas está vazio',
  placedBetsTabPlaced: 'Aposta colocada',
  placedBetsTabPrev: 'Aposta Anterior',
  placedBetsTitleUser: 'Do utilizador',
  placedBetsTitleEscape: 'Multi.x',
  placedBetsTitleBet: 'Aposta',
  placedBetsTitleProfit: 'Lucro',
  placedBetsTitleCashout: 'Sacar',
  placedBetsNoPlacedBet: 'Nenhuma aposta feita.',
  provablyFairContentCheckBtn: 'Verifique a feira comprovável',
  provablyFairContentTitle: 'Provavelmente Justo',
  provablyFairContentDesc: 'O resultado da liderança de quatro lados pode ser comprovado como justo. ',
  provablyFairContentInputFieldsCrashRateTitle: 'Taxa de falhas',
  provablyFairContentInputFieldsCrashRatePlaceHolder: 'Taxa de falhas sem x',
  provablyFairContentInputFieldsRoundHashTitle: 'Hash redondo',
  provablyFairContentInputFieldsRoundHashPlaceHolder: 'por exemplo. ',
  provablyFairContentInputFieldsSubmitBtn: 'Gerar assinatura',
  provablyFairContentSignature: 'Assinatura Redonda',
  provablyFairContentErrorsCrashRate: 'insira o número no campo taxa de falhas',
  resetPasswordTitle: 'Redefinir senha',
  resetPasswordNewPasswordTitle: 'Nova Senha',
  resetPasswordNewPasswordErrorsRequired: '*Senha requerida.',
  resetPasswordNewPasswordErrorsMinLength: 'Mínimo 8 caracteres permitidos.',
  resetPasswordNewPasswordErrorsMaxLength: 'Máximo de 16 caracteres permitidos.',
  resetPasswordNewPasswordErrorsPattern: '*A senha deve ter de 8 a 16 caracteres alfanuméricos e especiais. ',
  resetPasswordConfirmPasswordTitle: 'Confirme sua senha',
  resetPasswordConfirmPasswordErrorsNotMatched: 'As senhas não coincidem.',
  resetPasswordResetBtn: 'Reiniciar',
  passwordResetSuccess: 'Senha mudada com sucesso.',
  resetPasswordWentWrong: 'Algo deu errado!',
  levelGrand: 'GRANDE',
  levelMajor: 'PRINCIPAL',
  levelMinor: 'MENOR',
  levelMini: 'MINI',
  bannerContentWinDaily: 'Ganhe diariamente $ 1000 ',
  bannerContentContest: 'Concursos agora ',
  bannerContentjoin: 'Participe do nosso concurso diário de apostas e torne-se o Herói!  ',
  bannerContentPlayNow: 'Jogue agora',
  bannerContentTitle: 'Ganhe Jackpot',
  bannerContentContent: 'Jogue mais',
  bannerContentGameName: 'JOGO DE CRASH',
  bannerContentGameDesc: 'Jogue mais',
  partners: 'Parceiros',
  gamesGoldenDragon: 'Dragão dourado',
  gamesCrashGame: 'Jogo de colisão',
  gamesDice: 'Dados',
  gamesHilo: 'Olá',
  allBetsTitle: 'Todas as apostas',
  allBetsBetId: 'ID da aposta',
  allBetsUser: 'Do utilizador',
  allBetsPayout: 'Pagamento',
  allBetsAmount: 'Quantia',
  allBetsTime: 'Tempo',
  allBetsProfit: 'Lucro',
  LoginwithOtp: 'Faça login com Otp',
  EnterLoginOtp: 'Digite Login Otp',
  submit: 'Enviar',
  footerAboutUs: 'Sobre nós',
  footerFaq: 'Perguntas frequentes',
  footerSitePolicy: 'Política do Site',
  footerResponsibleGambling: 'Jogo Responsável',
  footerTerms: 'Termos e Condições',
  footerCopywrite: 'LINHA CRIPTO 2022. ',
  signinTitle: 'Conecte-se',
  signinUsername: 'Nome de usuário ou email',
  signinPassword: 'Senha',
  signinLoginBtn: 'Conecte-se',
  signinSignup: 'Inscrever-se',
  signinForgetPassword: 'Esqueceu sua senha?',
  signinDontHaveAccount: 'Não tem uma conta? ',
  signinCreateAccount: 'Criar uma conta',
  signinLoginDescription: 'Ocasionalmente, a Clutch Gaming pode enviar ofertas gratuitas e promoções especiais por e-mail ou SMS. ',
  signinErrorsUserNameRequired: '*Por favor, insira o nome de usuário/e-mail.',
  signinErrorsUserNameMinLength: 'Mínimo 4 caracteres permitidos.',
  signinErrorsUserNameMaxLength: 'Máximo de 20 caracteres permitidos.',
  signinErrorsUserNamePattern: 'Por favor, insira um nome de usuário válido, apenas letras e números são permitidos.',
  signinErrorsPasswordRequired: '*Senha requerida.',
  signinErrorsPasswordMinLength: 'Mínimo 8 caracteres permitidos.',
  signinErrorsPasswordMaxLength: 'Máximo de 16 caracteres permitidos.',
  signinErrorsPasswordPattern: '*A senha deve ter de 8 a 16 caracteres alfanuméricos e especiais. ',
  signinSuccessfullyLoggedIn: 'Login realizado com sucesso.',
  signinResetMailSent: 'E-mail de redefinição de senha enviado.',
  signinTryAgain: 'Alguma coisa deu errado. Por favor tente outra vez.',
  signinResetPasswordUsernameErr: 'Por favor, digite o nome de usuário/e-mail para redefinir a senha.',
  signinResetMailSentSuccessContent: 'Enviamos a você um link de redefinição de senha em seu e-mail. ',
  signupTitle: 'Registro',
  signupUsername: 'Nome de usuário',
  signupEmail: 'E-mail',
  signupTermsConditions: 'Termos',
  signupWalletConnect: 'Faça login com carteira ',
  signupPassword: 'Senha',
  signupEighteenPlus: 'Tenho mais de 18 anos e concordo com os Termos de Serviço',
  signupRegisterBtn: 'Inscrever-se',
  signupCreateNewAccount: 'Criar nova conta',
  signupFullName: 'Nome completo',
  signupReferralCode: 'Código de Referencia',
  signupConfirmPassword: 'Confirme sua senha',
  signupUseSocialAccount: 'Ou use uma conta social',
  signupFacebook: ' Facebook',
  signupGoogle: ' Google',
  signupHaveAccount: 'Ter uma conta? ',
  signupLoginBtn: 'Conecte-se',
  signupErrorsUserNameRequired: '*Por favor, insira o nome de usuário.',
  signupErrorsUserNameMinLength: 'Mínimo 4 caracteres permitidos.',
  signupErrorsUserNameMaxLength: 'Máximo de 20 caracteres permitidos.',
  signupErrorsUserNamePattern: 'Por favor, insira um nome de usuário válido, apenas letras e números são permitidos.',
  signupErrorsFullNameRequired: '*Insira o nome completo.',
  signupErrorsFullNamePattern: 'Por favor insira um nome completo válido, apenas letras e números são permitidos.',
  signupErrorsEmailRequired: '*Por favor, insira o e-mail.',
  signupErrorsEmailMinLength: 'Mínimo 4 caracteres permitidos.',
  signupErrorsEmailMaxLength: 'Máximo de 20 caracteres permitidos.',
  signupErrorsEmailPattern: 'Por favor insira um e-mail válido.',
  signupErrorsPasswordRequired: '*Senha requerida.',
  signupErrorsPasswordMinLength: 'Mínimo 8 caracteres permitidos.',
  signupErrorsPasswordMaxLength: 'Depósito máximo de 16 caracteres permitidos.',
  signupErrorsPasswordPattern: '*A senha deverá ter de 8 a 16 dígitos alfanuméricos (maiúsculas e minúsculas) e caracteres especiais. ',
  signupErrorsConfirmPasswordMatch: 'A senha deve corresponder.',
  signupPlaceholderUserName: 'Min. ',
  signupPlaceholderEmail: 'por exemplo. ',
  signupPlaceholderPassword: 'Min. ',
  signupSomethingWentWrong: 'Algo deu errado!',
  signupSignupGoogle: 'Inscreva-se com sucesso no Google.',
  signupRegistered: 'Registrado com sucesso.',
  signupConfirmAge: 'Por favor, confirme que você tem 18 anos.',
  signupSignupFacebook: 'Cadastre-se com sucesso no Facebook.',
  signupCookiesConsent: 'Permita que cookies de terceiros nas configurações do seu navegador usem o login social.',
  signupRegisterEmailPopupContent: 'Enviamos a você um e-mail de verificação. ',
  accountMenuDeposit: 'Depósito',
  accountMenuCashout: 'Sacar',
  accountMenujackpotWinners: 'Vencedores do jackpot',
  accountMenuTransactions: 'Transações',
  accountMenuPromotions: 'Promoções',
  accountMenuProfile: 'Perfil',
  accountMenuLogout: 'Sair',
  accountMenuSound: 'Som',
  accountMenuMusic: 'Música',
  accountMenuWithdraw: 'Retirar',
  pageNotFound: 'Não encontramos a página que você procura!',
  error: 'Erro',
  somethingWentWrong: 'Algo deu errado!',
  verifyEmailThankYou: 'Obrigado!',
  verifyEmailEmailIsVerified: 'Seu e-mail foi verificado. ',
  verifyEmailGoToLogin: 'Vá para a página de login',
  verifyEmailWentWrong: 'Algo deu errado!',
  verifyEmailTokenExpired: 'E-mail já verificado ou link expirado, reenvie o link de verificação por e-mail, fornecendo seu e-mail registrado abaixo. ',
  verifyEmailGoToHome: 'Vá para a página inicial',
  verifyEmailSuccessMsg: 'E-mail verificado com sucesso.',
  gameCardTitle: 'Clutch Gaming Site de jogos de criptografia provavelmente justo',
  bonusSectionTitle: 'JOGOS DE EMBREAGEM',
  bonusSectionSpanText: 'apresenta os mais generosos e variados',
  bonusSectionBonuses: 'Bônus',
  bonusSectionSubtitleText: 'Jogue com prazer e ganhe recompensas adicionais durante o jogo. ',
  bonusSectionHeadingText: 'Bônus do Clutch Gaming',
  bonusSectionDesc: 'Como o melhor cassino Bitcoin, o Clutch Gaming possui um elaborado sistema de bônus generosos. ',
  JoiningBonusTitle: 'Bônus de adesão',
  JoiningBonusButton: 'Reivindique agora',
  JoiningBonusDesc: 'Esses bônus incentivam os jogadores de cassino online a jogar usando Bitcoin ou outras criptomoedas. ',
  depositBonusTitle: 'Bônus de Depósito',
  depositBonusButton: 'Depósito',
  depositBonusDesc: 'Esses bônus incentivam os jogadores de cassino online a jogar usando Bitcoin ou outras criptomoedas. ',
  refferBonusTitle: 'Bônus de indicação',
  refferBonusButton: 'Depósito',
  refferBonusDesc: 'Esses bônus incentivam os jogadores de cassino online a jogar usando Bitcoin ou outras criptomoedas. ',
  aboutSectionTitle: 'O que é jogo Clutch?',
  aboutSectionSpanText1: ' Clutch Gaming é um site inovador de cassino Bitcoin, criado com novos conceitos de jogos de azar online em mente. ',
  aboutSectionSpanText2: 'O cassino online permite que você jogue seus jogos favoritos gratuitamente ou com criptomoeda. ',
  aboutSectionSpanText3: ' Neste mundo virtual de jogos de azar com Bitcoin, você pode aproveitar muitas oportunidades – jogar seus jogos de cassino favoritos, receber bônus, participar de concursos e promoções.',
  verifiedSectionTitle: 'Software verificado e licenciado',
  verifiedSectionSpanText1: 'Não copiamos nem adotamos software de outros sites. ',
  faqSectionTitle: 'Perguntas frequentes dos jogadores',
  faqSectionForgetPasswordTitle: 'Esqueci a minha senha.',
  faqSectionForgetPasswordDesc: 'Você não forneceu um endereço de e-mail de recuperação para sua conta. ',
  faqSectionMyDepositCreditedTitle: 'Meu depósito não foi creditado.',
  faqSectionMyDepositCreditedDesc: ' Conteúdo de espaço reservado para este acordeão, que se destina a demonstrar a aula. ',
  SupportedCurrencyTitle: 'Moeda Suportada',
  SupportedCurrencySpanText1: 'Não copiamos nem adotamos software de outros sites. ',
  CarouselSectionTitle: 'QUEDA DE HODL',
  CarouselSectionSubTitle: ' Jogue mais jogos e tenha a chance de ganhar. ',
  WalletSettingTitle: 'Configuração de carteira',
  WalletSettingHideZero: 'Ocultar saldo zero',
  WalletSettingHideZeroInfo: 'Seu saldo zero não aparecerá em sua carteira',
  WalletSettingDisplayFiat: 'Exibir criptografia em moeda fiduciária',
  WalletSettingDisplayFiatInfo: 'Todas as apostas',
  WalletSettingNoteForApprox: 'Observe que estas são aproximações de moeda.',
  WalletSettingSave: 'Salvar',
  WalletSettingNoWalletFound: 'Nenhuma carteira encontrada',
  jackpotWinnersNoWinnerAvailable: 'Nenhum vencedor disponível',
  jackpotWinnersNew: 'Novo',
  jackpotWinnersHeader: 'Vencedor do jackpot',
  jackpotDetailsHeader: 'Detalhe do jackpot',
  jackpotDetailsCampaignPeriod: 'Período da campanha',
  jackpotDetailsFrom: 'De',
  jackpotDetailsTo: 'Para',
  jackpotDetailsBettingRule: 'Regra de apostas',
  jackpotDetailsMinBetAmount: 'Valor mínimo da aposta',
  jackpotDetailsMaxBetAmount: 'Valor máximo da aposta',
  jackpotDetailsBetPercentage: 'Porcentagem de aposta',
  jackpotDetailsWinningRuleHeader: 'Regra vencedora',
  jackpotDetailsWinningRule: 'Fazer apostas com valores maiores aumentará as chances de ganhar.',
  jackpotDetailsProfitRule: 'Se o valor da aposta estiver entre minBetAmount e maxBetAmount, então betPercentage% do valor da aposta será adicionado ao valor do jackpot.',
  profileTitle: 'Detalhes pessoais',
  profileFName: 'Primeiro nome',
  profileLname: 'Sobrenome',
  profileUserName: 'Nome de usuário',
  profileDob: 'Data de nascimento',
  profileEmail: 'Endereço de email',
  profilePhone: 'Número de telefone',
  profileChange: 'Mudar',
  profileSave: 'Salvar as mudanças',
  profilePassword: 'Senha',
  profileSubmit: 'Enviar',
  profileUploadProfile: 'Carregar perfil',
  profileCurrentPassword: 'Senha atual',
  profileNewPassword: 'Nova Senha',
  profileConfirmPassword: 'Confirme sua senha',
  profileScanQRCode: 'Digitalize o código QR',
  profileEnterSecretCode: 'Digite o código secreto',
  profileErrorsFirstNameRequired: 'O primeiro nome é necessário.',
  profileErrorsFirstNamePattern: 'Caracteres inválidos no seu Nome. ',
  profileErrorsFirstNameMinLength: 'Mínimo 3 caracteres permitidos.',
  profileErrorsFirstNameMaxLength: 'Máximo de 20 caracteres permitidos.',
  profileErrorsLastNameRequired: 'O sobrenome é obrigatório.',
  profileErrorsLastNamePattern: 'Caracteres inválidos no seu Sobrenome. ',
  profileErrorsLastNameMinLength: 'Mínimo 3 caracteres permitidos.',
  profileErrorsLastNameMaxLength: 'Máximo de 20 caracteres permitidos.',
  profileErrorsChangePasswordOldRequired: 'É necessária uma senha antiga.',
  profileErrorsChangePasswordOldPattern: 'Por favor insira uma senha válida.',
  profileErrorsChangePasswordOldMinLength: 'Mínimo 8 caracteres permitidos.',
  profileErrorsChangePasswordOldMaxLength: 'Máximo de 16 caracteres permitidos.',
  profileErrorsChangePasswordNewRequired: 'É necessária uma nova senha.',
  profileErrorsChangePasswordNewPattern: 'Por favor insira uma senha válida.',
  profileErrorsChangePasswordNewMinLength: 'Mínimo 8 caracteres permitidos.',
  profileErrorsChangePasswordNewMaxLength: 'Máximo de 16 caracteres permitidos.',
  profileErrorsChangePasswordNewPasswordNotMatched: 'As senhas não coincidem.',
  profileErrorsChangePasswordNewPasswordMatched: 'A nova senha não pode ser igual à senha atual.',
  profileErrorsChangePasswordNewRequiredConfirmPass: 'A confirmação da senha é necessária.',
  profileErrorsUserNameRequired: 'Nome de usuário é requerido.',
  profileErrorsUserNamePattern: 'Insira um nome válido.',
  profileErrorsUserNameMinLength: 'Mínimo 3 caracteres permitidos.',
  profileErrorsUserNameMaxLength: 'Máximo de 20 caracteres permitidos.',
  profileErrorsDobRequired: 'A data de nascimento é obrigatória.',
  profileErrorsEmailRequired: 'O e-mail é obrigatório.',
  profileErrorsPostcodeRequired: 'O código postal é obrigatório.',
  profileErrorsCityRequired: 'A cidade é necessária.',
  profileErrorsCurrencyRequired: 'A moeda é obrigatória.',
  profileErrorsAddressRequired: 'Endereço é necessário.',
  profileErrorsCountryRequired: 'O país é obrigatório.',
  profileErrorsEmailPattern: 'Por favor insira um e-mail válido.',
  profileErrorsEmailMinLength: 'Mínimo 3 caracteres permitidos.',
  profileErrorsEmailMaxLength: 'Máximo de 20 caracteres permitidos.',
  profileMessagesProfileUpdate: 'Perfil atualizado com sucesso.',
  profileMessagesChangePassword: 'Senha atualizada com sucesso.',
  profileProfilePicProfilePicUpload: 'Carregar imagem do perfil',
  profileNoNewChanges: 'Não foi possível atualizar o perfil! ',
  profileLoggedInWithSocial: 'Você fez login com login social.',
  profileSideNavigatorSound: 'Som',
  profileSideNavigatorMusic: 'Música',
  profileSideNavigatorProfile: 'Meu perfil',
  profileSideNavigatorBetHistory: 'Histórico de apostas',
  profileSideNavigatorTransactions: 'Transações',
  profileSideNavigatorDeposit: 'Depósito',
  profileSideNavigatorWithdraw: 'Retirar',
  profileSideNavigatorGameLimits: 'Limites do jogo',
  profileSideNavigatorReferral: 'Referência',
  profileSideNavigatorChangePassword: 'Alterar a senha',
  profileSideNavigatorTermsConditions: 'Termos',
  profileSideNavigatorLogOut: 'Sair',
  profileSideNavigatorProvablyFair: 'Provavelmente Justo',
  WalletInfoSectionNotAvailable: 'Não disponível',
  WalletInfoSectionRealBalance: 'Saldo Real',
  GameLimitsMinimumBet: 'Aposta mínima',
  GameLimitsMaximumBet: 'Aposta máxima',
  GameLimitsMaxWinFor1Bet: 'Vitória máxima para uma aposta',
  ProvablyFairSubTitle: 'Este jogo usa a tecnologia Provably Fair para determinar o resultado do jogo. ',
  ProvablyFairMaximumBet: 'Aposta máxima:',
  ProvablyFairMaxWinFor1Bet: 'Vitória máxima para uma aposta',
  promotionsTitle: 'Promoções',
  promotionsNoLosingData: 'Desculpe, promoções de bônus sem perda disponíveis para ',
  promotionsNoDepositData: 'Desculpe, promoções de bônus sem depósito disponíveis para ',
  promotionsCurrency: ' moeda.',
  promotionsViewBtn: 'Visualizar',
  promotionsClaimSuccess: 'Você reivindicou com sucesso',
  promotionsAvailability: 'Disponibilidade: ',
  promotionsAvailabilityTabLosing: 'Perdendo bônus',
  promotionsAvailabilityTabDeposit: 'Bônus de depósito',
  promotionsDepositBonusTableCode: 'Código',
  promotionsDepositBonusTableMinDeposit: 'Min. ',
  promotionsDepositBonusTablePercentage: 'Percentagem',
  promotionsDepositBonusTableMaxBonus: 'Bônus máximo',
  promotionsDepositBonusTableRolloverMultipler: 'Multiplicador de rollover',
  promotionsDepositBonusTableMaxRolloverPerBet: 'Rollover máximo por aposta',
  promotionsDepositBonusTableTimeToAchieveRolloverTarget: 'Hora de atingir a meta de rollover',
  promotionsBonusCode: 'CÓDIGO BÔNUS: ',
  promotionsLossesClaim: 'As perdas podem ser reivindicadas (dentro do período da campanha): ',
  promotionsLossesClaimLosingBonusTablePercentage: 'Percentagem',
  promotionsLossesClaimLosingBonusTableMinLosingAmount: 'Min. ',
  promotionsBonusStatus: 'Status do bônus: ',
  promotionsBonusAmount: 'Valor do bônus: ',
  promotionsRolloverTarget: 'Meta de rolagem: ',
  promotionsRolloverAchieved: 'Rollover alcançado: ',
  promotionsActivatebtn: 'ATIVAR',
  promotionsClaimBtn: 'ALEGAR',
  promotionsCancelBtn: 'CANCELAR',
  promotionsBackBtn: 'VOLTAR',
  promotionsBonus: 'Bônus',
  promotionsTermAndConditions: ' Termos',
  promotionsClaimDepositBonusTitle: 'Passos para reivindicar bônus de depósito',
  promotionsClaimDepositBonusFirst: 'Ativar código bônus',
  promotionsClaimDepositBonusSecond: 'Valor do depósito na carteira',
  promotionsClaimDepositBonusThird: 'Alcançar a meta de rollover para reivindicar bônus',
  promotionsClaimLosingBonusTitle: 'Passos para reivindicar bônus perdidos',
  promotionsClaimLosingBonusFirst: 'Ativar código bônus',
  promotionsClaimLosingBonusSecond: 'Jogue seus jogos favoritos',
  promotionsClaimLosingBonusThird: "Clique em 'Reivindicar perdas' para reivindicar perdas dentro do período da campanha",
  promotionsWentWrong: 'Algo deu errado!',
  transactionTitle: 'Transações',
  transactionBetId: 'ID da aposta',
  transactionUser: 'Do utilizador',
  transactionPayout: 'Pagamento',
  transactionAmount: 'Quantia',
  transactionTime: 'Data',
  transactionProfit: 'Lucro',
  transactionCashout: 'Sacar',
  transactionBet: 'Aposta',
  transactionMultix: 'Multi.x',
  transactionWin: 'Ganhar',
  transactionFairness: 'Justiça',
  transactionReferFriend: 'Indique um Amigo',
  transactionTotal: 'Apostas totais',
  transactionWins: 'Vitórias',
  transactionRefresh: 'Atualizar',
  transactionFilterTopMultipliers: 'Principais multiplicadores',
  transactionFilterHugeWins: 'Grandes vitórias',
  transactionFilterBiggestWins: 'Maiores vitórias',
  transactionFilterMultipliers: 'Multiplicadores',
  transactionRealBalance: 'Saldo Real',
  depositWithdrawTXDate: 'Data',
  depositWithdrawTXAmount: 'Quantia',
  depositWithdrawTXTxId: 'TxID',
  depositWithdrawTXTxType: 'Tipo de transação',
  depositWithdrawTXStatus: 'Status',
  depositWithdrawTXNoData: 'Não há dados disponíveis.',
  depositTitle: 'Depósito',
  depositHistory: 'História',
  depositSubTitle: 'O usuário não pode depositar ou sacar moeda fiduciária. ',
  depositCopyNotSupported: 'Seu navegador não suporta cópia automática para a área de transferência.\n',
  depositCopied: 'Endereço copiado.',
  depositAddressCreated: 'Endereço criado com sucesso.',
  depositGenerateAddress: 'Gerar endereço',
  depositWarningText: 'Por favor, NÃO deposite via transferência entre cadeias',
  withdrawTitle: 'Retirar',
  withdrawSubTitle: 'O usuário não pode depositar ou sacar moeda fiduciária. ',
  withdrawNoteDesc: 'Certifique-se de NÃO inserir o endereço BEP2, BEP20 (BSC) como endereço de retirada de carteiras LTC',
  withdrawNoteSublist: ' Por favor, NÃO retire como transferência entre cadeias',
  withdrawHistory: 'História',
  withdrawWithdrawBtn: 'Solicitação de retirada',
  withdrawFees: 'Taxa de retirada {{fees}} {{token}}',
  withdrawWithdrawPending: 'Seu pedido de saque está pendente. ',
  withdrawEnterFields: 'Insira o valor da retirada e o endereço da sua carteira.',
  withdrawSelectWallet: 'Selecione a carteira para sacar.',
  withdrawAmountFieldTitle: 'Quantidade (mín. 0,03 {{wallet.currency.code}})',
  withdrawAmountFieldPlaceholder: '0',
  withdrawAddressFieldTitle: 'Seu {{wallet.currency.code}} endereço',
  withdrawAddressFieldPlaceholder: 'Digite seu endereço',
  withdrawNoBalance: 'Não há equilíbrio suficiente.',
  withdrawMinAmount: 'Insira um valor maior ou igual a {{min}} .',
  fiatCurrencyInfo: 'O usuário não pode depositar ou sacar moeda fiduciária. ',
  paginationNextBtn: 'Próximo',
  paginationPrevBtn: 'Anterior',
  wentWrong: 'Algo deu errado.',
  referralTitle: 'Referência',
  referralSubTitle: 'Indique seus amigos',
  referralEarn: ' Ganhe ₹ 150 cada',
  referralReferAndEarn: 'Indique e ganhe bônus por cada indicação',
  referralReferPara: ' Se você encaminhar este código para qualquer usuário',
  referralReferLink: 'Consulte Link',
  referralCopyReferralCode: 'Copiar código de referência',
  referralTotalRewards: 'Recompensa total',
  TwoWayAuthentication: 'Autenticação bidirecional',
  LoginRequired: 'Login necessário!',
  BetPlacedSuccessfully: 'Aposta realizada com sucesso',
  BetAddedInQueueSuccessfully: 'Aposta adicionada na fila com sucesso',
  BetRemovedFromQueueSuccessfully: 'Aposta removida da fila com sucesso',
  BetCancelledSuccessfully: 'Aposta cancelada com sucesso',
  PlayerEscapedSuccessfully: 'Jogador escapou com sucesso',
  CopiedSuccessfull: 'Copiado com sucesso',
  NotEnoughBalance: 'Equilíbrio insuficiente',
  AutoBetStarted: 'Aposta automática iniciada ',
  AutoBetFinished: 'Aposta automática concluída ',
  IdCopied: 'Eu copiei',
  usersConsent: 'Ao acessar este site, confirmo que tenho 18 anos e revisei,\n ',
  termsOfService: 'Termos de serviço',
  email: 'E-mail',
  playNow: 'Jogue agora',
  orContinueWith: 'Ou continue com',
  metamaskExtensionNotAvailable: 'Instale a extensão metaMask e atualize a página.',
  cashier: 'CAIXA',
  account: 'CONTA',
  hello: 'Olá',
  balance: 'Equilíbrio',
  referrals: 'Referências',
  settings: 'Configurações',
  withdrawals: 'Retiradas',
  demoWallet: 'Carteira de demonstração',
  myAccount: 'Minha conta',
  wallet: 'Carteira',
  user: 'Do utilizador',
  USD: 'USD',
  amountIsRequired: 'O valor é obrigatório',
  minDepositAmount: 'O valor do depósito deve ser maior que {{min}}.',
  minEqualDepositAmount: 'O valor do depósito deve ser maior ou igual a {{min}}.',
  minMaxDepositAmount: 'O valor do depósito deve ser mínimo {{min}} e máximo {{max}}.',
  maxDepositAmount: 'O valor do depósito deve ser inferior a {{max}}.',
  minWithdrawAmount: 'O valor da retirada deve ser maior que {{min}}',
  minEqualWithdrawAmount: 'O valor da retirada deve ser maior ou igual a {{min}}',
  withdrawMustLessThanWallet: 'O valor do saque deve ser menor que o valor da sua carteira',
  accountTypeIsRequired: 'O tipo de conta é obrigatório.',
  mobileNumberLength: 'O número do celular deve ser de {{length}} dígitos',
  phoneIsRequired: 'O telefone é obrigatório',
  cpfError: 'O CPF deve ser de {{length}} personagens',
  cpfRequiredError: 'CPF é obrigatório',
  cnpjError: 'O CNPJ deve ser de {{length}} personagens',
  mainMenu: 'MENU PRINCIPAL',
  casino: 'Cassino',
  sports: 'Esportes',
  crypto: 'Criptografia',
  NFT: 'NFT',
  poker: 'Pôquer',
  earnRewards: 'GANHE RECOMPENSAS',
  rankUp: 'Promover',
  inviteFriends: 'Convide amigos',
  partnerProgram: 'Programa de parceria',
  home: 'Lar',
  sportsbook: 'Apostas esportivas',
  menu: 'Cardápio',
  liveBets: 'APOSTAS AO VIVO',
  allBets: 'Todas as apostas',
  highRollers: 'Grandes apostadores',
  luckyBets: 'Apostas de sorte',
  trade: 'Troca',
  game: 'JOGO',
  player: 'JOGADOR',
  time: 'TEMPO',
  wager: 'APOSTA',
  multiplierBan: 'MULTIPLICADOR',
  payoutText: 'PAGAMENTO',
  bannerHeadOne: 'Experimente a verdadeira inovação com o mais alto',
  bannerHeadTwo: 'programa de recompensas dentro do setor.',
  registerNow: 'Registrar agora',
  liveWins: 'VITÓRIAS AO VIVO',
  monthly: 'M',
  weekly: 'C',
  daily: 'D',
  clutchEcosystem: 'ecossistema de embreagem',
  allGames: 'Todos os jogos',
  allSports: 'Todos os esportes',
  cryptoFutures: 'futuros de criptografia',
  tradeNow: 'Negocie agora',
  $clutch: '$embreagem',
  allTables: 'Todas as tabelas',
  nft: 'NFT',
  viewAll: 'Ver tudo',
  rewards: 'RECOMPENSAS',
  profile: 'Perfil',
  clutchProfile: 'PERFIL DA EMBREAGEM',
  totalBets: 'Apostas totais',
  totalWagered: 'Total apostado',
  joinDate: 'Data de afiliação',
  displaySocialLinks: 'Exibir contas sociais vinculadas',
  privateProfile: 'Perfil Privado',
  rank: 'Classificação',
  nextRank: 'Próxima classificação',
  changeUsername: 'Mudar nome de usuário',
  newPassword: 'Nova Senha',
  changeName: 'Mude o nome',
  linkSocialAccount: 'Vincular conta social',
  privateProfileTooltip: 'Depois de tornar seu perfil privado, outros usuários não verão seu nome de usuário junto com apostas, corridas, etc.',
  socialLinksTooltip: 'Conteúdo não disponível',
  rankTooltip: 'Conteúdo não disponível',
  balances: 'saldos',
  createReferralBalance: 'Criar saldo de referência',
  total: 'Total',
  switchBalance: 'Alternar equilíbrio',
  mainAccount: 'Conta principal',
  tips: 'Pontas',
  receivedAmount: 'Valor recebido',
  dateOrTime: 'Data hora',
  view: 'Visualizar',
  allChains: 'Todas as cadeias',
  creditAmount: 'Quantidade de crédito',
  setCustomReferralCode: 'Definir código de referência personalizado',
  changeEmail: 'Mude o e-mail',
  newEmail: 'Novo Email',
  createPassword: 'Criar senha',
  newUsername: 'Novo usuário',
  username: 'Nome de usuário',
  changePassword: 'Alterar a senha',
  twoFactorAuthentication: 'autenticação de dois fatores',
  twoFactorAuthenticationTitle: 'O uso da autenticação de dois fatores é altamente recomendado porque protege sua conta com sua senha e seu telefone.',
  twoFactorAuthenticationSubTitle: 'Um e-mail e uma senha devem ser definidos para ativar o 2FA. ',
  enable2fa: 'ATIVAR 2FA',
  verifyIdentityByKyc: 'Verifique sua identidade (kyc)',
  oldPassword: 'Senha Antiga',
  live: 'Ao vivo',
  soccer: 'Futebol',
  tennis: 'tênis',
  fifa: 'Fifa',
  basketball: 'Basquetebol',
  iceHockey: 'Gelo Hokey',
  volleyball: 'Voleibol',
  tableTennis: 'Tênis de mesa',
  baseball: 'Beisebol',
  rubgy: 'Rúgbi',
  golf: 'Golfe',
  boxing: 'Boxe',
  myBets: 'Minhas apostas',
  favourites: 'Favoritos',
  americanFootball: 'futebol americano',
  buyCrypto: 'Compre criptografia',
  oldEmail: 'E-mail velho',
  loggedInSuccess: 'Conectado com sucesso',
  loggedOutSuccess: 'Desconectado com sucesso',
  wagerLimit: 'Limite de aposta definido com sucesso',
  lossLimit: 'Limite de perda definido com sucesso',
  accountDisable: 'Conta desativada com sucesso',
  sessionTimeout: 'Tempo limite da sessão definido com sucesso',
  signup: 'Inscrição com sucesso',
  depositLimit: 'Limite de depósito definido com sucesso',
  tokenVerify: 'O token foi enviado para seu e-mail, verifique',
  emailVerify: 'O e-mail foi verificado',
  emailChangedSuccess: 'O e-mail foi alterado com sucesso',
  profileUpdatedSuccess: 'perfil atualizado',
  otpVerified: 'OTP verificado',
  twoFADeactivated: 'Autenticação 2FA desativada',
  unAuthorized: 'Não autorizado',
  dataNotFound: 'Dados não encontrados',
  setReferralCode: 'Definir código de referência...',
  ultimate: 'FINAL',
  notFound: 'Não encontrado',
  casinoTransactions: 'Transações de Cassino',
  clutchOriginals: 'Originais da embreagem',
  sportsbookTransactions: 'Transações de apostas esportivas',
  status: 'Status',
  success: 'Sucesso',
  failed: 'Fracassado',
  pending: 'Pendente',
  date: 'Data',
  to: 'Para',
  gameName: 'Nome do jogo',
  transactionId: 'ID da transação',
  roundId: 'ID da rodada',
  bet: 'Aposta',
  win: 'Ganhar',
  transactionType: 'Tipo de transação',
  at: 'no',
  downloadAll: 'Baixar tudo',
  gameText: 'Jogo',
  betId: 'ID da aposta',
  gameId: 'ID do jogo',
  actionType: 'Tipo de acão',
  searchGames: 'Pesquisar jogos',
  provider: 'Fornecedor',
  search: 'Procurar',
  sortBy: 'Ordenar por',
  popular: 'Popular',
  challengePool: 'Conjunto de Desafios',
  loadMore: 'Carregue mais',
  bitcoin: 'Bitcoin',
  chat: 'Bater papo',
  trades: 'Negociações',
  battles: 'Batalhas',
  loginToChat: 'Faça login para conversar',
  rules: 'Regras',
  chatRule1: 'Não assedie ou insulte outros usuários',
  chatRule2: 'Não implore, peça empréstimos, curiosidades ou gorjetas',
  chatRule3: 'Não insinue que Clutch tem más intenções ("site fraudulento" etc.)',
  chatRule4: 'Não envie spam ou poste links que não sejam do Clutch',
  chatRule5: 'Não anuncie qualquer forma de negociação, compra ou venda de serviços',
  chatRule6: 'Não compartilhe ou anuncie seu código de referência',
  chatRule7: 'Não peça para se tornar um membro da equipe',
  chatRule8: 'apenas inglês',
  chatRule9: 'Respeite mods, administradores e outros usuários',
  send: 'Enviar',
  expand: 'Expandir',
  signIn: 'Entrar',
  cross: 'Cruzar',
  collapse: 'Colapso',
  emailPlaceholder: 'Insira o seu endereço de email',
  removeFromFavourite: 'Remova dos favoritos',
  addToFavourite: 'Adicionar aos favoritos',
  footerAboutSite: 'Copyright © 2022-2023 Gammastack.com pertence e é operado pela HNA Gaming N.V., uma empresa registrada e estabelecida sob as leis de Curaçao. ',
  footerRightsReserved: '2023 Gamastack. ',
  signupFirstName: 'Primeiro nome',
  signupAddress: 'Endereço',
  signupCity: 'Cidade',
  signupPostcode: 'Código postal ',
  signupCounty: 'País',
  signupCurrency: 'Moeda',
  signupGender: 'Gênero',
  signupMan: 'Homem',
  signupWomen: 'Mulheres',
  signupOther: 'Outro',
  signupLoginDetails: 'Login em detalhes',
  signupPersonalDetails: 'Detalhes pessoais',
  signupConfirm: 'confirme',
  signupPrivacyPolicy: 'Li e aceito a Política de Privacidade',
  signupTermAndConditions: 'Concordo com o tratamento dos meus dados pessoais. ',
  signupNewsLetter: 'Desejo receber bônus, promoções, novidades ou outras informações relevantes periodicamente por News Letter.',
  signupSms: 'Desejo receber bônus, promoções, novidades ou outras informações relevantes periodicamente por SMS.',
  initialHeaderContent: 'Obtenha incríveis 100%',
  secoundaryHeaderContent: 'Bônus de boas-vindas de até $ 100',
  playNowButton: 'Jogue agora',
  registerButtonContent: 'Registro',
  results: 'Resultados',
  refresh: 'Atualizar',
  cancel: 'Cancelar',
  select: 'Selecione',
  current: 'Atual',
  set: 'Definir',
  remove: 'Remover',
  save: 'Salvar',
  not: 'não',
  before: 'Antes',
  after: 'Depois',
  action: 'Ação',
  withdrawal: 'Cancelamento',
  requested: 'Requeridos',
  withdraw: 'Retirar',
  areYouSure: 'Tem certeza',
  yes: 'Sim',
  no: 'Não',
  cancelled: 'Cancelado',
  approved: 'Aprovado',
  refunded: 'Devolveu',
  chargeback: 'Estorno',
  processedOn: 'Processado em',
  transaction: 'Transação',
  dateTime: 'Data hora',
  more: 'Mais',
  fromDate: 'Da data',
  toDate: 'A data',
  percentage: 'Percentagem',
  info: 'Informações',
  min: 'Mínimo',
  max: 'Máx.',
  change: 'Mudar',
  continueToSite: 'Continuar para o site',
  choose: 'Escolher',
  enterWithdrawalAmount: 'Escolha ou insira seu valor de retirada',
  capDeposit: 'DEPÓSITO',
  capWithdrawal: 'CANCELAMENTO',
  capAmount: 'QUANTIA',
  minimum: 'Mínimo',
  required: 'Obrigatório',
  of: 'de',
  isRequired: 'é necessário',
  isAllowed: 'é permitido',
  method: 'Método',
  capWithdraw: 'RETIRAR',
  showLess: 'Mostre menos',
  clearFilter: 'Filtro limpo',
  quantity: 'Quantidade',
  reset: 'Reiniciar',
  incorrect: 'Incorreta',
  load: 'Carregar',
  applied: 'Aplicado',
  ok: 'OK',
  list: 'Lista',
  all: 'Todos',
  favorite: 'Favorito',
  endPointNotFound: 'Algo está errado com a conexão de rede',
  category: 'Categoria',
  seeAll: 'Ver tudo',
  emailUserNameRequired: 'E-mail/nome de usuário obrigatório',
  emailUserNamePlaceHolder: 'Digite seu e-mail ou nome de usuário',
  passwordRequired: 'Senha requerida',
  newPasswordRequired: 'Nova senha é obrigatória',
  accountFrozen: 'Sua conta está congelada até',
  resendEmail: 'Reenviar email',
  resendLink: 'REENVIAR LINK',
  userLoggedOut: 'Desconectado com sucesso',
  emailUserName: 'Digite seu e-mail/nome de usuário',
  enterYourEmail: 'Insira o seu endereço de email',
  emailAlreadyRegistered: 'Este e-mail já está registado',
  userNameAlreadyTaken: 'Este nome de usuário já está em uso',
  fillAllFields: 'Preencha todos os campos antes de passar para a próxima etapa',
  pleaseAccept: 'Por favor aceite',
  acceptPrivacyPolicy: 'política de Privacidade',
  acceptTerms: 'Termos e Condições',
  modeOfComm: ' e modo de comunicação',
  beforeNextStep: 'antes de passar para a próxima etapa',
  userNamePlaceholder: 'Insira nome de usuário',
  min8Characters: 'Mínimo 8 caracteres',
  min1Number: 'Número mínimo 1',
  min1LowerCase: 'Mínimo de 1 caractere minúsculo',
  min1UpperCase: 'Mínimo de 1 caractere maiúsculo',
  min1SpecialChar: 'Mínimo de 1 caractere especial',
  passwordTip: 'A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial',
  newPasswordTip: 'A nova senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial',
  commonWordsNotAllowed: "A senha não deve ser uma palavra comum como ('senha@123', '123456', 'nome de usuário') ou uma sequência facilmente adivinhada.",
  passwordPlaceholder: 'Digite a senha',
  confirmPasswordPlaceholder: 'Digite Confirmar Senha',
  acceptAll: 'Aceitar tudo',
  firstNamePlaceholder: 'Introduza o primeiro nome',
  lastNamePlaceholder: 'Insira o último nome',
  addressPlaceholder: 'Insira o endereço',
  cityPlaceholder: 'Digite a cidade',
  postCodePlaceholder: 'Insira o código postal',
  phonePlaceholder: 'Digite o número de telefone',
  invalidEmail: 'E-mail inválido. Insira um endereço de e-mail válido, como exemplo@exemplo.com',
  emailRequired: 'Email (obrigatório',
  confirmPasswordRequired: 'Confirmar senha obrigatória',
  firstNameRequired: 'Por favor entre com seu primeiro nome.',
  lastNameRequired: 'Por favor insira seu Sobrenome.',
  phoneRequired: 'Telefone obrigatório',
  dateOfBirthRequired: 'Data de nascimento obrigatória',
  genderRequired: 'Sexo obrigatório',
  userNameRequired: 'Nome de usuário (necessário',
  addressRequired: 'Endereço obrigatório',
  cityRequired: 'Cidade obrigatória',
  stateRequired: 'Estado obrigatório',
  countryRequired: 'País obrigatório',
  postCodeRequired: 'Código postal obrigatório',
  currencyCodeRequired: 'Código de moeda obrigatório',
  matchPassword: 'As senhas não coincidem. ',
  fName3Chars: 'Seu Nome deve ter entre 3 e 50 caracteres.',
  onlyAlphabet: 'Somente alfabetos permitidos',
  lName3Chars: 'O sobrenome deve ter pelo menos 3 caracteres',
  mustBe18: 'Você deve ter 18 anos para se registrar',
  validDOB: 'Por favor, insira uma data de nascimento válida',
  max20Characters: 'Máximo de 20 caracteres permitidos',
  min3Characters: 'Mínimo de 3 caracteres necessários',
  max100Characters: 'Máximo de 100 caracteres permitidos',
  max50Characters: 'Máximo de 50 caracteres permitidos',
  invalidNumber: 'Número inválido',
  preferredLanguageRequired: 'Idioma preferido obrigatório',
  invalidPass: 'senha inválida',
  prevBtn: 'Anterior',
  newPasswordPlaceholder: 'Insira a nova senha',
  currentPasswordPlaceholder: 'Digite a senha atual',
  uploadImage: 'Enviar Imagem',
  removeImage: 'Remover imagem',
  preferredLanguage: 'língua preferida',
  wantToChangePass: 'Deseja alterar a senha?',
  selectCountry: 'Selecione o pais',
  selectPreferredLanguage: 'Selecione o idioma preferido',
  fName50Chars: 'O nome deve ter no máximo 50 caracteres',
  lName50Chars: 'O sobrenome deve ter no máximo 50 caracteres',
  invalidDate: 'Data inválida',
  DOBEarlyThanToday: 'A data de nascimento deve ser anterior a hoje',
  enterValidPhone: 'Por favor insira um número de telefone válido',
  accountStatus: 'Status da Conta',
  kycVerified: 'Seu KYC foi verificado com sucesso.',
  denied: 'Negado',
  chooseFile: 'Escolher arquivo',
  upload: 'Carregar',
  countryCantChange: 'Você está prestes a iniciar a verificação KYC. ',
  proceed: 'Continuar',
  update: ' ',
  history: 'História',
  type: 'Tipo',
  amount: 'Quantia',
  forfeited: 'Perdido',
  expired: 'Expirado',
  completed: 'Concluído',
  zeroedOut: 'Zerado',
  active: 'Ativo',
  lapsed: 'Caducado',
  yourActiveBonus: 'Seus bônus ativos',
  currentActiveBonus: 'Bônus ativos atuais',
  bonusStatus: 'Status',
  bonusWager: 'Aposta',
  bonusActivate: 'Ativar',
  bonusForfeit: 'Perda de bônus',
  notUsableBonus: 'Se sim, você não poderá usar este bônus novamente.',
  bonusLapsed: 'Você não teve perdas suficientes para receber o reembolso. ',
  inProcess: 'Em processo',
  claiming: 'Reivindicando',
  loyaltyPopover: 'Esses pontos de fidelidade podem ser usados ​​para obter reembolso. ',
  loyaltyPage: 'Página de fidelidade',
  loyaltyPerCurrency: 'Lealdade por moeda',
  maxLevelReached: 'Nível máximo alcançado',
  pointsToReach: 'pontos a alcançar ',
  loyaltyBannerHeading: 'Você merece nossa nova lealdade',
  loyaltyBannerDesc: 'Bônus de boas-vindas de 100% até $ 200',
  loyaltyHeadingOne: 'Você é sempre recompensado no Gammastack',
  loyaltyHeadingOneDesc: 'Na Gammastack, levamos a lealdade a um nível totalmente novo de emoção, enchendo você de recompensas a cada reviravolta! ',
  loyaltySubHeadOne: 'Ganhar pontos de fidelidade',
  loyaltySubHeadOneDesc: 'Por cada 10€ em apostas a dinheiro feitas no nosso casino, você ganhará 1 ponto de fidelidade. ',
  loyaltySubHeadTwo: 'Subindo a escada da lealdade',
  loyaltySubHeadTwoDesc: 'Quanto mais pontos você acumular, mais alto você subirá nos níveis de fidelidade, desbloqueando recompensas ainda mais fantásticas ao longo do caminho. ',
  loyaltySubHeadThree: 'Suba de nível e seja recompensado ',
  loyaltySubHeadThreeDesc: 'Eleve sua experiência de jogo progredindo em nossos atraentes níveis de recompensa, onde cada nível acima desbloqueia uma variedade emocionante de rodadas grátis para maximizar suas chances de ganhar. ',
  loyaltyTestimonialHeadOne: 'NO Gammastack CASINO GARANTIAMOS QUE',
  loyaltyTestimonialHeadTwo: 'Cada giro conta',
  loyaltyTestimonialDesc: 'Cada rodada que você dá nos caça-níqueis não é apenas para diversão, mas também deixa você mais perto de algumas recompensas realmente incríveis! ',
  loyaltyPoints: 'Pontos',
  headerLevel: 'nível',
  loyaltyCashback: 'Dinheiro de volta',
  loyaltyBannerBtn: 'Verifique o progresso',
  loyaltyHeadingTwo: 'Desbloqueie a melhor experiência de jogo: Programa Gammastack VIP',
  loyaltyHeadingTwoDesc: 'Ao atingir o nível 6 em nosso programa de fidelidade, você desbloqueará um mundo de privilégios exclusivos como membro do prestigiado Programa VIP Gammastack. ',
  loyaltySubHeadFour: 'Limites mais altos de depósito e retirada',
  loyaltySubHeadFourDesc: 'Aproveite a flexibilidade dos limites aumentados de depósito e saque como membro VIP.',
  loyaltySubHeadFive: 'Bônus e promoções exclusivas',
  loyaltySubHeadFiveDesc: 'Obtenha acesso a bônus e promoções especiais feitas sob medida para nossos jogadores VIP',
  loyaltySubHeadSix: 'Gerente de conta pessoal',
  loyaltySubHeadSixDesc: 'Receba suporte dedicado e assistência personalizada do seu gerente de conta.',
  loyaltySubHeadSeven: 'Tempos de retirada mais rápidos',
  loyaltySubHeadSevenDesc: 'Como VIP, você desfrutará de tempos de saque rápidos, garantindo acesso aos seus ganhos ainda mais rápido.',
  loyaltySubHeadEight: 'Recompensas mensais de reembolso',
  loyaltySubHeadEightDesc: 'Aumente seu saldo com generosas recompensas mensais de cashback exclusivas para nossos membros VIP.',
  loyaltySubHeadNine: 'Convites para eventos e torneios exclusivos',
  loyaltySubHeadNineDesc: 'Obtenha convites cobiçados para eventos e torneios exclusivos, onde você pode se misturar com outros VIPs e competir por prêmios incríveis.',
  loyaltySubHeadTen: 'Bônus de aniversário',
  loyaltySubHeadTenDesc: 'Comemore seu dia especial em grande estilo com um delicioso bônus de aniversário só para você!',
  loyaltySubHeadEleven: 'Presentes de luxo',
  loyaltySubHeadElevenDesc: 'Delicie-se com uma seleção requintada de presentes luxuosos escolhidos a dedo para recompensar nossos jogadores mais valiosos.',
  loyaltyTableHeading: 'Visão geral dos níveis e recompensas',
  loyaltyTableHeaderOne: 'Nível de fidelidade',
  loyaltyTableHeaderTwo: 'Pontos de fidelidade',
  loyaltyTableHeaderThree: 'Reembolso Diário',
  promReadMore: 'consulte Mais informação',
  currentPortalBlock: 'se você deseja bloquear sua conta apenas para este portal.',
  allPortalBlock: 'para bloquear sua conta de todos os portais.',
  limit24Reset: 'Depois de definir os limites de apostas, perdas e depósitos, eles poderão ser editados e removidos após 24 horas, mas os limites podem ser reduzidos imediatamente.',
  enterAmount: 'Insira o valor',
  limit: 'Limite',
  loss: 'Perda',
  takeABreak: 'Dar um tempo',
  session: 'Sessão',
  selfExclusion: 'Autoexclusão',
  used: 'Usado',
  edit: 'Editar',
  updatedAt: 'Atualizado em',
  cannotBeRemoved: 'Não pode ser removido antes',
  timePeriod: 'Período de tempo',
  custom: 'Personalizado',
  hours: 'Horas',
  days: 'Dias',
  timePeriodPlaceholder: 'Número de dias',
  months: 'Meses',
  permanent: 'Permanente',
  onlyNumbers: 'Somente números permitidos',
  notNumbers: 'Números não permitidos',
  noSpaceAllow: 'Sapce não é permitido',
  userNameAllowed: 'Deve começar com o alfabeto. ',
  timePeriodRequired: 'Período de tempo necessário',
  cannotBeIncreased: 'Não pode ser aumentado antes',
  amountGTZero: 'O valor deve ser maior que 0',
  amountEqualOrLess: 'O valor deve ser igual ou menor que semanal e mensal',
  amountInBetween: 'O valor deve estar entre diário e mensal (inclusive)',
  amountEqualOIrGreater: 'O valor deve ser igual ou superior ao diário e mensal',
  limitRemoved: 'Seu seguinte limite será removido.',
  limitSet24Hrs: 'Você está prestes a definir o seguinte limite para sua conta. ',
  takeABreakInfo: 'Você está prestes a bloquear o acesso à sua conta por um período predefinido. ',
  sessionLimitInfo: 'Você está prestes a definir o seguinte limite para sua conta. ',
  limitCantSetBefore: 'Seu limite de sessão não pode ser definido antes',
  selfExclusionInfo: 'Você está prestes a bloquear o acesso à sua conta por um período predefinido. ',
  quickSearch: 'Pesquisa rápida',
  cash: 'Dinheiro',
  nonCash: 'Não-dinheiro',
  userDetail: 'Detalhes do usuário',
  gameIdentifier: 'Identificador de jogo',
  rollback: 'Reverter',
  rollbackBeforeBetWin: 'Reverter antes de ganhar a aposta',
  freeSpins: 'Rodadas Grátis',
  betInternal: 'Aposta Interna',
  winInternal: 'Ganhe interno',
  addMoney: 'Adicionar dinheiro',
  removeMoney: 'Remover dinheiro',
  addMoneyInternal: 'Adicionar dinheiro interno',
  removeMoneyInternal: 'Remover dinheiro interno',
  depositInternal: 'Depósito Interno',
  withdrawInternal: 'Retirar Interno',
  promotionTitle: 'Título da promoção',
  cancelWithdrawRequest: 'Você deseja cancelar esta solicitação',
  bankname: 'Nome do banco',
  selectBank: 'Selecione seu banco',
  accountNumber: 'Número de conta',
  enterAccountNumber: 'Insira o número da conta',
  rowsPerPage: 'Linhas por página',
  availedBonus: 'Você já tem um bônus ativo. ',
  capForfeit: 'PERDIDO',
  itFirst: 'primeiro.',
  selectYourBonus: 'Selecione seu bônus',
  skip: 'PULAR',
  with: 'com',
  without: 'sem',
  selectPaymentMethod: 'Por favor escolha o seu método de pagamento',
  paymentMethod: 'Forma de pagamento',
  selectPaymentmethod: 'Selecione o método de pagamento',
  mobileNo: 'Número de telemóvel',
  enterMobileNo: 'Digite o número do celular',
  volatility: 'Volatilidade',
  paylines: 'Linhas de pagamento',
  theme: 'Tema',
  tryForFree: 'Experimentar gratuitamente',
  resultsFound: 'Resultados encontrados',
  games: 'Jogos',
  in: 'em',
  low: 'Baixo',
  medium: 'Médio',
  high: 'Alto',
  'medium-high': 'Médio Alto',
  'very-high': 'Muito alto',
  'low-medium': 'Médio baixo',
  fantasy: 'Fantasia',
  ancient_civilizations: 'Civilizações antigas',
  fruits: 'Frutas',
  africa: 'África',
  military: 'Militares',
  joker: 'Palhaço',
  asia: 'Ásia',
  luxurylife: 'Vida de luxúria',
  underwater_world: 'Mundo Subaquático',
  book_off: 'Reserva',
  western: 'Ocidental',
  retro: 'Retrô',
  egypt: 'Egito',
  party: 'Festa',
  st_patrick_day: 'Dia de São Patrício',
  space: 'Espaço',
  easter: 'Páscoa',
  girls: 'Garotas',
  branded: 'De marca',
  x_mas_and_new_year: 'Natal e Ano Novo',
  horrors: 'Horrores',
  other: 'Outro',
  loginFirst: 'Faça login primeiro para adicionar o jogo aos favoritos',
  loginFirstPlay: 'Faça login primeiro para jogar este jogo',
  sport: 'Esporte',
  pirates: 'Piratas',
  sweets: 'Doces',
  luxury_life: 'Vida de luxúria',
  st_valentines_day: 'Dia de São Valentim',
  halloween: 'dia das Bruxas',
  food: 'Comida',
  lobby: 'salão',
  favourite: 'Favorito',
  playNGO: 'Jogue e vá',
  loyaltySystem: 'Sistema de Fidelidade',
  progressionSystem: 'Sistema de progressão',
  startPoint: 'Ponto de partida',
  endPoint: 'Ponto final',
  loyaltyBonus: 'Bônus de lealdade',
  pageRemoved: 'Esta página não existe ou foi removida',
  suggestToBackHome: 'Sugerimos que você volte para casa',
  backToHome: 'De volta para casa',
  goHome: 'Ir para casa',
  oopps: 'Ops',
  somethingNotWorking: 'Lamentamos, algo não está funcionando aqui!',
  tournament: 'Torneio',
  none: 'Nenhum',
  validOnDays: 'Válido em dias',
  validTill: 'Válida até',
  yourRequest: 'Seu pedido',
  withdrawalRequestPending: 'Você tem uma solicitação de saque em estado pendente. ',
  realBalance: 'Saldo Real',
  bonusInCasino: 'Bônus no cassino',
  bonusSelected: 'Bônus selecionado',
  payWith: 'Pagar com',
  reached: 'Alcançado',
  receiptOfYour: 'Recebimento do seu',
  capEmail: 'E-MAIL',
  capCreditCard: 'NÚMERO DO CARTÃO DE CRÉDITO',
  capExpiry: 'TERMO',
  capCvv: 'CVV',
  expiryIncorrectFormat: 'EXPIRY não está no formato correto',
  min13Numbers: 'Mínimo 13 números necessários',
  max19Numbers: 'Máximo de 19 números permitidos',
  min3Numbers: 'Mínimo 3 números necessários',
  max4Numbers: 'Máximo de 4 números permitidos',
  Other: 'Outro',
  forfeit: 'Perder',
  withdrawRequestCreated: 'Solicitação de retirada criada com sucesso',
  noBetHistory: 'Nenhum histórico de apostas encontrado',
  noPromotions: 'Nenhuma promoção encontrada',
  noBonuses: 'Nenhum bônus encontrado',
  noHistory: 'Nenhum histórico encontrado',
  noWithdrawalHistory: 'Nenhum histórico de retirada encontrado',
  noCMS: 'Nenhum CMS encontrado',
  noProvidersFound: 'Nenhum provedor encontrado',
  noDataFound: 'Nenhum dado encontrado',
  countryBlocked: 'PAÍS BLOQUEADO',
  forbidden: 'PROIBIDO',
  notAcceptingVisitors: 'Lamentamos profundamente por isso, mas brilhantewilds.com não está disponível em seu país.',
  contactUsAt: 'Se você acha que isso foi mostrado a você por engano, entre em contato com nosso suporte por e-mail em',
  siteUnderMaintenance: 'Site em manutenção',
  weWillBeRightBack: 'Nós voltaremos logo!',
  serviceUnavailable: 'Nosso serviço está temporariamente indisponível. ',
  tryAgainLater: 'Por favor, tente novamente mais tarde.',
  checkYourEmail: 'Verifique seu e-mail',
  sentAVerification: 'Enviamos um link de verificação',
  toYourEmail: 'para o seu e-mail.',
  backToLogin: 'Volte ao login',
  goToLogin: 'Vá para fazer login',
  accountVerified: 'Conta verificada',
  yourAccountVerified: 'Sua conta foi verificada',
  successfully: 'Com sucesso',
  verificationFailed: 'Falha na verificação',
  emailFailedContact: 'Falha na verificação. Se isso estiver acontecendo de forma consistente, entre em contato ',
  setNewPass: 'Defina uma nova senha',
  passResetLink: 'Enviamos um link de redefinição de senha para',
  toYourAccount: 'Para sua conta',
  sendPasswordResetLink: 'Solicitação para redefinir senha',
  gamePlayWagerComplete: 'Parabéns, você concluiu as apostas com sucesso e o dinheiro do bônus ganho é maior do que o valor máximo de ganho permitido. ',
  real: 'Real',
  demo: 'Demonstração',
  money: 'Dinheiro',
  formatRquired: 'Formato .png, .jpg, .jpeg necessário',
  chooseImage: 'Escolha uma imagem',
  imageSize3MB: 'Insira a imagem com tamanho inferior a 3 MB',
  noProfileImage: 'Nenhuma imagem de perfil disponível para remoção',
  limitSetSuccess: 'limite definido com sucesso',
  accountDisabled: 'Conta desativada com sucesso',
  loginSuccess: 'Login bem-sucedido',
  signupSuccess: 'Cadastro bem sucedido',
  linkExpired: 'Link expirado',
  linkSent: 'Link enviado',
  accAlreadyVerified: 'Conta já verificada. ',
  profileImage: 'Imagem de perfil',
  profileImageRemoved: 'Imagem de perfil removida com sucesso',
  docs: 'Documentos',
  updatedSuccess: 'Atualizado com sucesso',
  MATCH: 'CORRESPONDER',
  BALANCE: 'EQUILÍBRIO',
  FREESPINS: 'RODADAS GRATUITAS',
  MATCH_1: 'CORRESPONDER',
  DEPOSIT: 'DEPÓSITO',
  WAGERING: 'APOSTAS',
  PROMOTION: 'PROMOÇÃO',
  CREDITCARD: 'CARTÃO DE CRÉDITO',
  WALLET: 'CARTEIRA',
  CRYPTO: 'CRIPTO',
  OTHER: 'OUTRO',
  INSTANTBANKING: 'BANCO INSTANTÂNEO',
  cookieTitle: 'Biscoitos!',
  declineButton: 'Declínio',
  acceptButton: 'Aceitar',
  cookieDeclinedMessage: 'Para a melhor experiência possível, os cookies devem ser aceitos para continuar a utilizar este site. ',
  cookieDescription: 'Bem-vindo ao Shinywilds.com! ',
  forfeitMessageFirst: 'Atualmente você tem o seguinte bônus ativo:',
  forfeitMessageSecond: 'Você precisa desistir deste bônus antes de reivindicar um novo.',
  forfeitButton: 'Perder',
  pleaseVerifyFirstText: 'Para poder solicitar um saque, primeiro precisamos verificar seu endereço de e-mail. ',
  clickHere: 'Clique aqui',
  pleaseVerifyLastText: 'para que possamos reenviar o e-mail de verificação e prosseguir.',
  pleseWait: 'Por favor espere por',
  toResend: 'para reenviar o link',
  notVerified: 'Você deve verificar seu endereço de e-mail para sacar dinheiro',
  verification: 'Verificação',
  UPLOAD_IMG_MSG: 'Arraste e solte arquivos aqui ou clique para selecionar os arquivos',
  addressIsRequired: 'Endereço é necessário',
  withdrawRequestSent: 'Solicitação de retirada enviada ao administrador. Aguarde a aprovação!',
  withdrawAddressCannotBeSame: 'O endereço de retirada não pode ser igual ao seu endereço',
  pasteYourCurrencyAddressHere: 'Cole o seu {{currency}} endereço da carteira aqui...',
  noGamesAvailable: 'Nenhum jogo disponível',
  or: 'ou',
  copyPasteNot: 'copiar/colar não é permitido',
  selectDob: 'Selecione a data de nascimento',
  iAgreeWith: 'Concordo com o acordo do usuário e confirmo que tenho pelo menos 18 anos de idade!',
  iAgreeto: 'eu concordo ',
  alreadyHave: 'já tem uma conta?',
  kyc: 'Kyc',
  changePicture: 'Alterar imagem',
  selectGender: 'Selecione o gênero',
  male: 'Macho',
  female: 'Fêmea',
  zipCode: 'CEP',
  selectCurrency: 'Selecione sua moeda',
  bannerTitle: 'Cassino incrível',
  bannerSubTitle: 'A APENAS UM CLIQUE DE DISTÂNCIA',
  address: 'Endereço',
  streetAddress: 'Endereço da Rua',
  signupAddressState: 'Estado',
  onlyNumberAllowed: '{{fieldName}} deve conter apenas números',
  profileImageSizeFormat: 'Permitir tamanho de imagem menor que {{imageSize}} e formato {{imageFormat}}',
  fileSizeFormat: 'Permitir tamanho de arquivo menor que {{imageSize}} e formato {{imageFormat}}', ///
  comingNewUpdate: 'Estamos trabalhando em algo emocionante. ',
  ooops: 'Ops...',
  redirectingMessage: 'Redirecionando para {{redirectTime}} segundos...',
  logIn: 'Conecte-se',
  signUp: 'Inscrever-se',
  Username: 'Nome de usuário',
  day: 'dia',
  month: 'mês',
  year: 'ano',
  forgetPassword: 'Esqueceu a senha',
  verifyEmail: 'Verificar e-mail',
  loggedInUser: 'Usuário logado',
  favorites: 'Favoritos',
  liveGames: 'Jogos ao vivo',
  videoSlots: 'Vídeos caça-níqueis',
  beforeAmount: 'Antes do valor',
  startDateCanNotGreterThanEnd: 'a data de início não pode ser posterior à data de término',
  Wallet: 'Carteira',
  Casino: 'Cassino',
  affiliate: 'Afiliado',
  affiliateTitle: 'Programa de Afiliados',
  affiliateDescription: ' Indique nossa marca e ganhe dinheiro participando do Programa de Afiliados Deuces! ',
  affiliateButtonText: 'Torne-se Afiliado',
  refferelLink: 'Link de referência',
  affiliateLinkCopy: 'Link copiado com sucesso',
  startDate: 'Data de início',
  endDate: 'Data final',
  referral: 'Referência',
  referralCode: 'Código de Referencia',
  requiredReferralCode: 'O código de referência é necessário para se inscrever',
  referralProgram: 'Programa de referência',
  overview: 'Visão geral',
  referralDetails: 'Detalhes de referência',
  referralIncentive: 'Indique nossa marca e ganhe dinheiro participando do Programa de Indicação Deuces! ',
  copyTitleMessage: 'Por favor copie {{title}} aplicar',
  referralCopiedMessage: 'Código de referência copiado com sucesso',
  isReferralCode: 'Código de referência (opcional)',
  greaterThan18years: 'e confirmo que tenho pelo menos 18 anos!',
  userId: 'ID do usuário',
  totalCommissionsRewards: 'Recompensas totais de comissões',
  registrationDate: 'data de registro',
  isReferralCheckbox: 'Deu ao Código de Afiliado, não é possível aplicar o Código de Referência ',
  notAvailable: 'N / D',
  serialNumber: 'SN',
  searchBy: 'Procurar por {{searchableKeyword}}',
  name: 'Nome',
  noDataAvailable: 'Nenhum dado disponível',
  reloadPage: 'recarregar página',
  commission: 'Comissão',
  promotions: 'Promoções',
  sponsorships: 'Patrocínios',
  refreshYourPage: 'Atualize sua página',
  latestPromotions: 'Últimas Promoções',
  promotionsNotAvailable: 'No momento, os detalhes da promoção não estão disponíveis ',
  luckyWinners: 'Vencedores sortudos',
  favoriteGames: 'Jogos favoritos',
  gameProviders: 'Provedores de jogos',
  affiliateEligibleDescription: 'Você não é elegível para se tornar afiliado, preencha o KYC até o nível 2 para se tornar elegível para Afiliado',
  moveOnKycPage: 'para passar para a página KYC.',
  docDownload: 'Clique aqui para baixar o documento enviado',
  documentUploadedSuccess: 'Documento enviado com sucesso',
  uploadFile: 'Subir arquivo',
  completeKycLevel: 'Por favor complete o nível  {{kycLevel}} Verificação KYC primeiro',
  leadingCasinoTitle: 'Principal cassino criptográfico online',
  leadingCasinoDescription: 'Descubra a emoção sem fim com a vasta coleção de jogos de cassino do Deuces. ',
  casinoButtonTitle: 'Vá para o cassino',
  leadingSportsTitle: 'Melhores apostas esportivas criptografadas online',
  leadingSportsDescription: 'Experimente a emoção das apostas esportivas com o Deuces. ',
  sportsButtonTitle: 'Vá para apostas esportivas',
  welcomeBack: 'Bem vindo de volta, {{userName}}',
  playSmarter: 'Jogue de maneira mais inteligente',
  loginNow: 'Conecte-se agora',
  yourKYCProgress: 'Seu {{value}} Progresso',
  copiedMessage: '{{title}} Copiado com sucesso',
  depositAddress: 'Endereço de depósito',
  emailVerified: 'email verificado',
  verify: 'Verificado',
  notVerify: 'Não verificar',
  winAmount: 'Valor do ganho',
  referralAmountMessage: 'O valor da indicação só será transferido caso o e-mail do usuário cadastrado seja verificado.',
  vipClub: 'Clube VIP',
  bronze: 'Bronze',
  silver: 'Prata',
  gold: 'Ouro',
  platinum: 'Platina',
  vipRankingMsg: 'Sistema de classificação VIP Duques',
  level1: 'nível 1',
  level2: 'nível 2',
  level3: 'nível 3',
  level4: 'nível 4',
  level5: 'nível 5',
  casinoLevelMessage: 'Cassino - Valor da aposta {{amount}}',
  sportsLevelMessage: 'Esportes - Valor da aposta {{amount}}',
  level5message: 'O jogador pode atingir este nível apenas fornecido pelo superadministrador',
  totalReferredAmount: 'Valor total referido {{amount}}',
  invalidReferralCode: 'Código de referência inválido',
  wantToLogin: 'Tem certeza de que deseja fazer login?',
  totalWager: 'Aposta Total',
  totalCommissionRewards: 'Recompensas totais da comissão',
  noGamesFound: 'Nenhum jogo encontrado',
  bonusReferral: 'Referência de bônus',
  affiliateCode: 'Código de afiliado',
  bonus: 'Bônus',
  latestBonus: 'Último bônus',
  bonusNotAvailable: 'No momento, os detalhes do bônus não estão disponíveis',
  lose: 'Perder',
  genderInvalid: 'O tipo de gênero deve ser apenas masculino ou feminino',
  walletSettings: 'Configurações da carteira',
  yearShouldBeBetween: 'O ano selecionado deve estar entre {{from}} - {{to}}',
  accessingSiteWarning: 'Ao acessar este site atesto que tenho pelo menos 18 anos de idade e que li e concordo com os Termos de Serviço.',
  rake_Back: '{{percent}} RAKE DE VOLTA',
  logoutSuccess: 'logout bem sucedido',
  registerSuccess: 'Registro bem-sucedido',
  forgetPasswordEmailVerifyMsg: 'E-mail de verificação enviado com sucesso para seu e-mail registrado, o e-mail de redefinição de senha é válido para {{time}} apenas',
  mailSentSuccess: 'Correio enviado com sucesso',
  passwordChanged: 'Senha alterada',
  passwordResetLogin: 'Sua senha foi alterada com sucesso, deseja fazer login',
  passwordChangedSuccess: 'Sua senha foi alterada com sucesso',
  profileUpdate: 'Perfil atualizado com sucesso',
  invalidCredentials: 'Credenciais inválidas',
  emailAlreadyExists: 'e-mail já existe',
  usernameAlreadyExists: 'O nome de usuário já existe',
  emailNotExists: 'E-mail não existe',
  resetPassInvalidToken: 'O link de redefinição de senha expirou. Verifique o e-mail novamente',
  IncorrectCurrentPassword: 'A senha atual está incorreta',
  formatIsRequired: '{{formatTypes}} o formato é obrigatório',
  anErrorOccurs: 'Ocorreu um erro, tente novamente',
  imageUploaded: 'Imagem enviada com sucesso',
  insufficientBalance: 'Saldo insuficiente',
  withdrawApprovalReqMsg: 'Solicitação de retirada enviada com sucesso, o dinheiro será transferido em algum momento',
  hideZeroBalancesTitle: 'Ocultar saldo zero',
  hideZeroBalancesSubTitle: 'Seus saldos zero não aparecerão na sua carteira',
  displayCryptoInFiatTitle: 'Exibir criptografia em moeda fiduciária',
  displayCryptoInFiatSubTitle: 'Todas as apostas',
  documentsAlreadyUploaded: 'Documento já carregado',
  documentsUploadedSuccessfully: 'Documento enviado com sucesso',
  gameAddedtoFavourite: 'Jogo adicionado aos favoritos com sucesso',
  gameRemovedFromFavourite: 'Jogo removido dos favoritos com sucesso',
  favoriteGameExists: 'O jogo favorito já existe',
  favoriteGameNotFound: 'Jogo favorito não encontrado',
  internalServerError: 'Erro interno do servidor, entre em contato com o administrador',
  realPlay: 'Jogo Real',
  funPlay: 'Jogo divertido',
  gameNotActive: 'O jogo não está ativo',
  captcha: 'Não é uma atividade divertida',
  signUpAndGet: 'cadastre-se e receba',
  rakeDisc: '50% de RAKE BACK',
  bannerFooter: 'ao acessar este site, atesto que tenho pelo menos 18 anos de idade e que li e concordo com os Termos de Serviço.',
  DeucesOriginal : 'Deuces Originais',
  Promostion: 'Promoção',
  orLoginWith: 'ou faça login com',
  RegisterAnAccount: 'Registre uma conta',
  MakeADepositAndPlay: 'Faça um depósito e jogue',
  ReceiveRakeBack: 'RECEBA 50% DE RAKEBACK',
  PreferNotToSay: 'Prefiro não dizer',

  // Privacy
  privacy: 'Privacidade',
  ghostMode: 'Modo fantasma',
  ghostModeOn: 'Modo fantasma ativado',
  ghostModeOff: 'Modo fantasma desativado',
  enableGhostMode: 'Ativar modo fantasma',
  yourBetsWontAppear: 'Suas apostas não aparecerão no feed de apostas público e na visualização de apostas'
}
