import React from 'react'
import { Box } from '../../../../node_modules/@mui/material/index'
import GoogleLogin from './GoogleLogin'
import { SocialMediaLoginStyle } from './style'
// import FacebookLogin from './FacebookLogin'

const SocialMediaLogin = () => {
  return (
    <SocialMediaLoginStyle>
      <Box
        className='social-media-box'
        display='flex'
        spacing='4'
      >
        <GoogleLogin />
        {/* <FacebookLogin /> */}
      </Box>
    </SocialMediaLoginStyle>

  )
}

export default SocialMediaLogin

//  <Box className='social-media-icon' flexGrow='1'>
// <LineIcon />
// </Box>
// <Box className='social-media-icon' flexGrow='1'>
// <HangoutIcon />
// </Box>
