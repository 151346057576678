import React, { memo } from 'react'
import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'

const AmountComponent = memo((props) => {
  const { option, isNonCash } = props
  const userData = useSelector((state) => state?.user?.user?.getUser)

  const showValue = (option) => {
    switch (option?.code) {
      case 'IDR' : return Number(option?.amount) || 0
      case 'USD' : return Number(option?.amount) || 0
      default : return (Number(option?.amount) / Number(userData?.convertedRate?.[option?.code])).toFixed(2) || 0
    }
  }
  return (
    <>
      {!isNonCash
        ? (
          <Typography className='coin-value'>
            {userData?.other?.displayCryptoInFlat
              ? showValue(option)
              : Number(option?.amount)}
          </Typography>
          )
        : (
          <Typography className='coin-value'>
            {Number(option?.nonCashAmount)}
          </Typography>
          )}
    </>
  )
})

export default AmountComponent

{ /* <Typography className='coin-value'>
{userData?.other?.displayCryptoInFlat
  ? option?.code !== 'IDR'
      ? (
          Number(option?.amount) /
      Number(userData?.convertedRate?.[option?.code])
        ).toFixed(2)
      : Number(option?.amount)
  : Number(option?.amount)}
</Typography> */ }
