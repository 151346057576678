import React from 'react'
import { MatchesListContainer } from './style'

const MatchesList = () => {
	return (
		<MatchesListContainer>
			MatchesListContainerBox
		</MatchesListContainer>
	)
}

export default MatchesList