import { createAsyncThunk } from '@reduxjs/toolkit'
import { activateWageringService, forfeitBonusService, getAllBonusService, getUserBonusService, getBonusDetailsService, getLoyaltyLevelService, getPromotionsService, getBonusService } from 'network/services/bonus.service'
import { getLoyaltyLevelStart, getBonusDetails, getGameService } from 'redux-thunk/redux/slices/bonus.slice'
export const getAllBonus = createAsyncThunk(
  'fetch/all-bonus',
  async (data, thunkApi) => {
    try {
      const res = await getAllBonusService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const activateWagering = createAsyncThunk(
  'activate-bonus/wagering',
  async ({ bonusId, onSuccess }, thunkApi) => {
    try {
      const res = await activateWageringService({ bonusId })
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const forfeitBonus = createAsyncThunk(
  'forfiet/bonus',
  async ({ bonusId, onSuccess }, thunkApi) => {
    try {
      const res = await forfeitBonusService({ bonusId })
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getUserBonus = createAsyncThunk(
  'fetch/user-bonus',
  async (data, thunkApi) => {
    try {
      const res = await getUserBonusService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
export const getLoyaltyLevel = createAsyncThunk(
  'fetch/loyalty-level',
  async (_, thunkApi) => {
    try {
      const res = await getLoyaltyLevelService()
      thunkApi.dispatch(getLoyaltyLevelStart(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
export const getBonusDetailsStart = createAsyncThunk(
  'fetch/user-bonus-details',
  async (data, thunkApi) => {
    try {
      const res = await getBonusDetailsService(data)
      thunkApi.dispatch(getBonusDetails(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
export const getPromotions = createAsyncThunk(
  'fetch/user-bonus-details',
  async (data, thunkApi) => {
    try {
      const res = await getPromotionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getBonus = createAsyncThunk(
  'fetch/all-bonus-details',
  async (data, thunkApi) => {
    try {
      const res = await getBonusService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
