import { CircularProgress } from '@mui/material'
import { StyledMainButton, StyledPrimaryButton, StyledTextButton } from './style'

export const CustomMainButton = ({
  type,
  variant,
  color,
  size,
  children,
  disabled,
  fullWidth,
  onClick,
  startIcon,
  endIcon,
  disableElevation,
  isLoading,
  ...otherProps
}) => {
  return (
    <StyledMainButton
      type={type}
      disabled={disabled}
      fullWidth={fullWidth}
      variant={variant}
      onClick={onClick}
      size={size}
      color={color}
      startIcon={startIcon}
      endIcon={endIcon}
      disableElevation={disableElevation}
      {...otherProps}
    >
      {isLoading ? <CircularProgress size={24} style={{ color: 'white' }} /> : children}
    </StyledMainButton>
  )
};

export const CustomPrimaryButton = ({
  type,
  variant,
  color,
  size,
  children,
  disabled,
  fullWidth,
  onClick,
  startIcon,
  endIcon,
  disableElevation,
  isLoading,
  ...otherProps
}) => {
  return (
    <StyledPrimaryButton
      type={type}
      disabled={disabled}
      fullWidth={fullWidth}
      variant={variant}
      onClick={onClick}
      size={size}
      color={color}
      startIcon={startIcon}
      endIcon={endIcon}
      disableElevation={disableElevation}
      {...otherProps}
    >
      {isLoading ? <CircularProgress size={24} style={{ color: 'white' }} /> : children}
    </StyledPrimaryButton>
  )
};

export const CustomTextButton = ({
  type,
  variant,
  color,
  size,
  children,
  disabled,
  fullWidth,
  onClick,
  startIcon,
  endIcon,
  disableElevation,
  isLoading,
  ...otherProps
}) => {
  return (
    <StyledTextButton
      type={type}
      disabled={disabled}
      fullWidth={fullWidth}
      variant={variant}
      onClick={onClick}
      size={size}
      color={color}
      startIcon={startIcon}
      endIcon={endIcon}
      disableElevation={disableElevation}
      {...otherProps}
    >
      {isLoading ? <CircularProgress size={24} style={{ color: 'white' }} /> : children}
    </StyledTextButton>
  )
};
