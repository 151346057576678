import React, { useEffect, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import routes from './routesList'
import RouteValidator from './RouteValidator'
import NotFoundComponent from 'components/layout/404/index'
import SideNavBar from 'components/layout/SideNavBar/index'
import Header from 'components/layout/Header/index'
import { useSelector } from 'react-redux'
import { SportsRoutes } from 'SportsBook/BetBalancer/SportsRoutes/index'
import { Box } from '@mui/material/index'
import Chat from 'components/layout/Header/components/Chat/index'
import { isMobile } from 'react-device-detect'

const CustomRoutes = () => {
  const { siteData } = useSelector((state) => state.general)
  const { hamburgerIconClass } = useSelector((state) => state?.user)
  const { showSideNav, showChat } = useSelector(state => state.settings)
  const history = useHistory()
  const isGamePlay = history.location.pathname.includes("/play-game/")

  return (
    <>
      {(siteData?.tenantDetail?.maintenance)
        ? <></>
        : !(isMobile && isGamePlay) &&  (
          <>

            <Header />
          </>
          )}
      <Box className={`screen-layout ${hamburgerIconClass}`}>
        {showSideNav && !(isMobile && isGamePlay) && <SideNavBar />}
        <Switch>

          {SportsRoutes()}
          {

            routes.map((route) => (
              <Route
                path={route.path}
                key={route.path}
                exact
                render={() => <RouteValidator route={route} />}
              />
            ))
          }
          <Route component={() => <NotFoundComponent />} />
        </Switch>
        {showChat && <Chat />}
      </Box>

    </>
  )
}

export default CustomRoutes
