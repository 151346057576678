import { createSlice } from '@reduxjs/toolkit'
import { fetchUserInformation, updateUserInformation } from 'redux-thunk/thunk/user.thunk'
import { getLocalLanguage, setLocalLanguage } from 'helpers/localstorage.helpers'
import { LANGUAGES } from 'constants/index'
import { VerifyPasswordToken, updatePassword, userLogin, userLogout, userSignUp } from 'redux-thunk/thunk/auth.thunk'

const defaultAuthState = {
  loginData: null,
  operatorInformationData: null,
  language: getLocalLanguage() || LANGUAGES[0].languageCode,
  authTabPanelValue: '1',
  forgotPassword: false,
  showResetPasswordPopup: false,
  isPasswordChange: false,
  logoutData: null,
  signUpData: null,
  changePasswordResponse: null,
  updateUserInformationResponse: null,
  isPasswordChangeLoader: false,
  isProfileUploaderLoader: false
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState: defaultAuthState,
  reducers: {
    setLoginData: (state, action) => ({
      ...state,
      loginData: action?.payload
    }),
    setAuthTabPanelValue: (state, action) => ({
      ...state,
      authTabPanelValue: action?.payload
    }),
    handleLanguageChange: (state, action) => {
      setLocalLanguage(action.payload)
      return {
        ...state,
        language: action.payload
      }
    },
    setForgotPassword: (state, action) => ({
      ...state,
      forgotPassword: action?.payload
    }),
    setShowResetPasswordPopup: (state, action) => ({
      ...state,
      showResetPasswordPopup: action?.payload
    }),
    setSignUpData: (state, action) => ({
      ...state,
      signUpData: action?.payload
    }),
    setChangePasswordResponse: (state, action) => ({
      ...state,
      changePasswordResponse: action?.payload
    }),
    setIsPasswordChange: (state, action) => ({
      ...state,
      isPasswordChange: action?.payload
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.fulfilled, (state, action) => {
        return {
          ...state,
          loginData: action.payload,
          language: action.payload?.additionalInfo?.languageCode || LANGUAGES[0].languageCode
        }
      })
      .addCase(userLogin.pending, (state, action) => {
        const { password, userNameOrEmail } = action.meta.arg
        return {
          ...state,
          loginData: { password, userNameOrEmail }
        }
      })
      .addCase(userSignUp.fulfilled, (state, action) => {
        return {
          ...state,
          signUpData: action.payload
        }
      })
      // .addCase(userSignUp.pending, (state, action) => {
      //   const { password, userNameOrEmail } = action.meta.arg
      //   return {
      //     ...state,
      //     signUpData: action
      //   }
      // })
      .addCase(userLogout.fulfilled, (state, action) => {
        return {
          ...state,
          logoutData: action.payload
        }
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        return {
          ...state,
          changePasswordResponse: action.payload,
          isPasswordChange: action.payload.success,
          isPasswordChangeLoader: false
        }
      })
      .addCase(updatePassword.pending, (state, action) => {
        return {
          ...state,
          isPasswordChangeLoader: true
        }
      })
      .addCase(updatePassword.rejected, (state, action) => {
        return {
          ...state,
          changePasswordResponse: action.payload,
          isPasswordChange: false,
          isPasswordChangeLoader: false
        }
      })
      .addCase(fetchUserInformation.fulfilled, (state, action) => {
        return {
          ...state,
          operatorInformationData: action.payload.userDetails
        }
      })
      .addCase(VerifyPasswordToken.fulfilled, (state, action) => {
        return {
          ...state,
          changePasswordResponse: action.payload
        }
      })
      .addCase(updateUserInformation.fulfilled, (state, action) => {
        return {
          ...state,
          updateUserInformationResponse: action.payload,
          isProfileUploaderLoader: false
        }
      })
      .addCase(updateUserInformation.rejected, (state, action) => {
        return {
          ...state,
          isProfileUploaderLoader: false
        }
      })
      .addCase(updateUserInformation.pending, (state, action) => {
        return {
          ...state,
          isProfileUploaderLoader: true
        }
      })
  }
})

export const {
  handleLanguageChange,
  setLoginData,
  setAuthTabPanelValue,
  setForgotPassword,
  setShowResetPasswordPopup,
  setChangePasswordResponse,
  setSignUpData,
  setIsPasswordChange
} = authSlice.actions

export default authSlice.reducer
