import { TextField, styled } from '@mui/material'

// export const CustomFormWrapper = styled(Box)(({ theme }) => {
//     return {
//     };
// });

export const CustomTextField = styled(TextField)(({ theme }) => {
  return {
    width: '100%',
    borderRadius: theme.spacing(1),
    background: '#0d1b32',
    marginBottom: '5px',
    '& .MuiInputBase-root': {
      maxHeight: theme.spacing(7),
      backgroundColor: theme.palette.border.transparent,
      borderRadius: theme.spacing(1.25),
      '& .MuiInputBase-input': {
        borderRadius: theme.spacing(1.25),
        color: theme.palette.primary.contrastText,
        fontSize: '16px',
        fontWeight: theme.typography.fontWeightRegular,
        backgroundColor: theme.palette.border.transparent,
        webkitBoxShadow: '0 0 0 50px #0d1b32 inset !important',
        boxShadow: '0 0 0 50px #0d1b32 inset !important',
        border: '1px solid #0d1b32'
      },
      '& .MuiSvgIcon-root': {
        fill: theme.palette.primary.contrastText
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.border.transparent,
        backgroundColor: theme.palette.border.transparent,
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.border.transparent,
          backgroundColor: theme.palette.border.transparent,
        }
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.border.transparent,
          backgroundColor: theme.palette.border.transparent,
        }
      },
      '&.Mui-focused, &.Mui-focused:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.border.transparent,
          backgroundColor: theme.palette.border.transparent,
        }
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.border.transparent,
        cursor: 'no-drop'
      }
    },
    ".MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: 'transparent',
      color: theme.palette.lightShade.light_09,
      backgroundColor: theme.palette.border.transparent,
    }

  }
})
