/* ==========================================================================
  LANGUAGES
  ========================================================================== */
export const LANGUAGES = [
  { label: 'English', languageCode: 'en', icon: require('../assets/flagsIcon/en.webp') },
  { label: 'Spanish', languageCode: 'es', icon: require('../assets/flagsIcon/es.webp') },
  { label: 'Portuguese', languageCode: 'pt', icon: require('../assets/flagsIcon/pt.webp') },
  { label: 'Indonesian', languageCode: 'id', icon: require('../assets/flagsIcon/pl.webp') }
  // { label: 'German', languageCode: 'de' },
  // { label: 'French', languageCode: 'fr' },
  // { label: 'Russian', languageCode: 'ru' }
]

/* ==========================================================================
  HTTP Method Types
========================================================================== */
export const METHOD_TYPES = Object.freeze({
  get: 'get',
  post: 'post',
  put: 'put',
  delete: 'delete',
  patch: 'patch'
})

/* ==========================================================================
LocalStorage / Cookie data
========================================================================== */
export const TOKEN = 'authtoken'
export const LANGUAGE = 'operator_language'
export const WALLETSETTING = 'operator_walletSetting'
export const CHAT = 'chat'
export const LIVE_DATA = 'liveData'
export const SHOW_SIDE_NAV = 'showSideNav'
export const PROVIDER_ID = 'providerId'

/* ==========================================================================
  TOASTER / NOTIFICATION Types
========================================================================== */
export const TOASTER_TYPE = Object.freeze({
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info'
})

/* ==========================================================================
  Loader Types
========================================================================== */
export const LOADER_HANDLER_TYPES = Object.freeze({
  page: 'pageLoader',
  submit: 'submitButtonLoader',
  table: 'tableLoader',
  content: 'contentLoader'
})

/* ==========================================================================
  All the navigation route Paths
========================================================================== */
export const ROUTE_PATHS = Object.freeze({
  HOME: '/',
  TEMPHOME: '/home',
  LOGIN: '/login',
  SIGNUP: '/signup',
  // SIGNUPAFFILIATE: '/:affc',
  CRASH_GAME: '/crash-game',
  DICE_GAME: '/dice-game',
  DICE_GAME_DEMO: '/dice-game/demo',
  MINE_GAME: '/mine-game',
  VERIFY_EMAIL: '/verify-email',
  RESET_PASSWORD: '/reset-password/:newPasswordKey/:uuid',
  SEND_RESET_LINK: '/reset-password',
  ABOUT: '/about',
  SITE_INFO: '/site-info/:pageName',
  GAME_CATAGORY: '/casino/:gameCategory',
  TERMS_AND_CONDITION: '/terms-condition',
  SPORTS_BOOK: '/sports-book',
  CASINO_PLAYGAME: '/casino/play-game/:gameId',
  CASINO: '/casino',
  CRASH: '/Crash',
  CRASH_GAME_DEMO: '/crash-game/demo',
  PROMOTION: '/promotions',
  PROMOTIONLIST: '/promotionslist',
  PROFILE: '/account/profile',
  BALANCE: '/account/balances',
  DEPOSIT: '/accountdeposits',
  WITHDRAWALS: '/account/withdrawals',
  REFERRALS: '/account/referrals',
  SETTINGS: '/account/settings',
  // TRANSACTIONS: '/account/transactions',
  CRYPTO: '/crypto',
  PROVIDERS: '/providers',
  PROVIDERS_CATEGORY: '/providers/:providerName',
  REWARDS: '/rewards',
  LOBBY: '/lobby',
  LOYALTY_BONUS: '/loyalty-bonus',
  RESULTS: '/results',
  CMS: '/cms/:cmsId',
  CASINO_BET: '/account/casino-bet-history',
  VERIFICATION: '/user/verification',
  SPORTS_BET: '/account/sports-bet-history',
  LOYALTY: '/account/loyalty',
  LOYALTY_BONUS_PAGE: '/loyalty-bonus',
  PROMOTIONS: '/promotions',
  LIMITS: '/account/limits',
  PROMOTIONS_PAGE_ID: '/promotion/:slug',
  MAINTENANCE: '/maintenance',
  COMINGSOON: '/coming-soon',
  COMING_SOON: '/coming-soon',
  RESPONSIBLE_GAMBLING: '/responsible-gambling',
  TRANSACTION: '/transaction',
  SPORTS: '/sports',
  FAVORITES: '/casino/favoritesGames',
  FAVORITE: '/favorite',
  DICE: '/dice',
  RESULT: '/result',
  MYBETS: '/my-bets',
  AFFILIATE: '/affiliate',
  REFERRAL: '/referral',
  SPONSORSHIP: '/sponsorships/:slug',
  VIPCLUB: '/vipclub',
  MY_ACCOUNT: '/my-account',
  MY_ACCOUNT_CHANGEPASSWORD: '/my-account/change-password',
  MY_ACCOUNT_USERKYC: '/my-account/user-kyc',
  MY_ACCOUNT_PRIVACY: '/my-account/privacy',
  BONUS: '/bonus',
  BONUS_PAGE_ID: '/bonus-details'

})

/* ==========================================================================
  Table Cell Type
========================================================================== */
export const TABLE_CELL_TYPE = Object.freeze({
  status: 'status',
  switch: 'switch',
  button: 'button',
  date: 'date',
  text: 'text',
  number: 'number',
  default: 'default',
  updateIconButton: 'updateIconButton',
  deleteIconButton: 'deleteIconButton',
  actionCell: 'actionCell',
  object: 'object'
})

/* ==========================================================================
  All Games default ids
========================================================================== */
export const DEFAULT_GAME_ID = Object.freeze({
  CRASH: '1'
})

/* ==========================================================================
  Default id of sports book
========================================================================== */
export const DEFAULT_SPORTS_BOOK_GAME_ID = 3

/* ==========================================================================
  Bet Result constants
========================================================================== */
export const BET_RESULT = Object.freeze({
  WON: 'won',
  LOST: 'lost',
  CANCELLED: 'cancelled'
})

/* ==========================================================================
  Table tabs constants
========================================================================== */
export const TABLE_TABS = Object.freeze({
  VIVA_ORIGINALS: 'viva-originals',
  MY_BETS: 'my-bets',
  CASINO: 'casino',
  SPORTS_BOOK: 'sports-book'
})

/* ==========================================================================
  Table transactions tabs constants
========================================================================== */
export const TRANSACTION_TABS = Object.freeze({
  ALL_BETS: 'all-bets',
  HIGH_ROLLER: 'high-roller'
})

/* ==========================================================================
  Custom games icons constants
========================================================================== */
export const CUSTOM_GAMES_ICONS = Object.freeze({
  dice: '/assets/images/dice/dice-icon.svg',
  crash: '/assets/images/casino/crash-table.svg'
})

/* ==========================================================================
  Betting modes constants
========================================================================== */
export const BET_MODES = Object.freeze({
  MANUAL: 'manual',
  AUTO: 'auto',
  STRATEGY: 'strategy'
})

/* ==========================================================================
  Minimum payout for all games
========================================================================== */
export const MIN_PAYOUT = Object.freeze({
  CRASH: 1.01
})

/* ==========================================================================
  Max increased by percentage for all games
========================================================================== */
export const MAX_INCREASED_BY_PERCENTAGE = Object.freeze({
  DICE_AUTO_BET: 100,
  CRASH_AUTO_BET: 100
})

/* ==========================================================================
  Bonus status constants
========================================================================== */
export const BONUS_STATUS = Object.freeze({
  ACTIVE: 'active',
  CLAIMED: 'claimed',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired',
  FORFEIT: 'forfeit',
  READY_TO_CLAIM: 'readyToClaim'
})

/* ==========================================================================
  Provable Fair Text
========================================================================== */
export const PROVABLE_FAIR_TEXT = '"Provably fair" is a term that refers to a system or algorithm used in online gambling or gaming that can be mathematically verified to ensure that the results are fair and not manipulated in any way. It is a way for players to know that the game or gambling platform they are using is legitimate and not rigged against them.'

/* ==========================================================================
  Transaction status and type constants
========================================================================== */
export const TRANSACTIONS_STATUS = Object.freeze({
  SUCCESS: 'success',
  PENDING: 'pending',
  FAILED: 'failed'
})

export const TRANSACTIONS_TYPE = Object.freeze({
  ALL: 'All',
  BONUS: 'Bonus',
  DEPOSIT: 'DEPOSIT',
  WITHDRAW: 'WITHDRAW'
  // CASINO: 'CASINO',
  // ORIGINALS: 'ORIGINALS',
  // SPORTS_BOOK: 'SPORTS_BOOK'
})

/* ==========================================================================
  Casino Page Tabs
========================================================================== */
export const CASINO_TABS = Object.freeze({
  LOBBY: 'lobby',
  FAVOURITE: 'favourite',
  ORIGINALS: 'originals',
  GAME_SEARCH: 'game-search',
  ALL_PROVIDERS: 'all-providers'
})

export const GAME_CATEGORY = Object.freeze({
  FAVOURITE: 'favourite'
})

export const LIVE_WINS_TABS = Object.freeze({
  LIVE_WINS: 'live-wins',
  MONTH: 'month',
  WEEK: 'week',
  DAY: 'day'
})

export const SPORTS_BOOK_PATHS_SIDEBAR = Object.freeze({
  MY_BETS: 'bets',
  LIVE: 'live',
  FAVOURITES: 'favorites',
  SOCCER: 'soccer-1',
  TENNIS: 'tennis-5',
  BASKETBALL: 'basketball-2',
  VOLLEYBALL: 'volleyball-23',
  TABLE_TENNIS: 'table-tennis-20',
  AMERICAN_FOOTBALL: 'american-football-16',
  BASEBALL: 'baseball-3',
  GOLF: 'golf-9'
})

export const SPORTSBOOK_PATHS = {
  ...SPORTS_BOOK_PATHS_SIDEBAR,
  FIFA: '300',
  ICE_HOKEY: 'ice-hockey-4',
  RUGBY: 'rugby-12',
  BOXING: 'boxing-10'
}

export const LIVE_WINS_TYPES = {
  CASINO_WIN: 'casino-win',
  CASINO_BET: 'casino-bet'
}

/* ==========================================================================
  Other constants
========================================================================== */
export const DEFAULT_PRECISION = 5
export const PAGINATION_LIMIT = 1
export const PAGINATION_LIMIT_FOR_ALLBETS = 10
export const MIN_WITHDRAW_AMOUNT = 0.003
export const WITHDRAW_FEES = 0.000006
export const TAB_SELECTION = Object.freeze({
  placed: Symbol('placed'),
  previous: Symbol('previous')
})
export const ROLLOVER = 'rollOver'
export const STRATEGY = 'strategiesSaved'
export const USER_DISCLAIMER = 'userDisclaimer'
export const LIVE_WINS_RECORDS_LIMIT = 10

export const CASINO_PAGINATION_LIMIT = 10
export const SPORTS_BOOK_PAGINATION_LIMIT = 10
export const TRANSACTIONS_LIMIT = 10
export const DEFAULT_PAGINATION_LIMIT = 10
