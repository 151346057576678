import React from 'react'
import { Box } from '@mui/material'
import { GoogleIcon } from 'assets/icons/index'
import { useDispatch } from 'react-redux'
import { ROUTE_PATHS } from 'constants/index'
import { setShowLoginPopup } from 'redux-thunk/redux/slices/gameSetting.slice'
import { userSocialLogin } from 'redux-thunk/thunk/auth.thunk'
import { useGoogleLogin } from '@react-oauth/google'
import config from 'config/app.config'
import axios from 'axios'
import { useLocation, useHistory } from 'react-router-dom'
import { AllSocialLoginStyle } from './style'

const GoogleLogin = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const onLoginSuccess = () => {
    if (location.pathname.match('/casino/play-game')) {
      history.replace(ROUTE_PATHS.CASINO)
    } else if (location.pathname?.match('/verify')) {
      history.replace(ROUTE_PATHS.HOME)
    } else {
      history.replace(location.pathname)
    }
    dispatch(setShowLoginPopup(false))
  }

  const socialLoginHandler = (socialLoginData) => {
    dispatch(userSocialLogin({ data: socialLoginData, onLoginSuccess }))
  }

  const googlLoginHandler = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const userInfo = await axios.get(config?.socialLogin?.google?.googleAPI, {
        headers: { Authorization: `Bearer ${codeResponse?.access_token}` }
      })
      const userName = userInfo?.data?.name?.trim()?.replace(' ', '_')
      const socialLoginData = {
        email: userInfo?.data?.email,
        signInType: 'Google',
        firstName: userInfo?.data?.given_name,
        lastName: userInfo?.data?.family_name,
        password: codeResponse?.access_token,
        username: userName || userInfo?.data?.given_name + '_' + userInfo?.data?.family_name
      }
      socialLoginHandler(socialLoginData)
    }
  })
  return (
    <AllSocialLoginStyle>
      <Box
        onClick={googlLoginHandler}
        className='social-media-icon'
        flexGrow='1'
      >
        <GoogleIcon />
      </Box>
    </AllSocialLoginStyle>
  )
}

export default GoogleLogin
