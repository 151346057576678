import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCasinoBannersListService, getCasinoProviderListService, getGeneralService } from 'network/services/casino.service'
import { getGeneralDataSuccess, setCasinoBanners, setCasinoProviderListData, setloginBanners } from 'redux-thunk/redux/slices/casino.slice'

export const getCasinoProviderList = createAsyncThunk(
  '/getCasinoProviderList',
  async (data, thunkApi) => {
    try {
      const res = await getCasinoProviderListService(data)
      thunkApi.dispatch(setCasinoProviderListData(res?.providers))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getCasinoBannerList = createAsyncThunk(
  '/casino-banners',
  async (data, thunkApi) => {
    try {
      const res = await getCasinoBannersListService(data)
      thunkApi.dispatch(setCasinoBanners(res?.banners))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getLoginBannerList = createAsyncThunk(
  '/casino-banners',
  async (data, thunkApi) => {
    try {
      const res = await getCasinoBannersListService(data)
      thunkApi.dispatch(setloginBanners(res?.banners))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getGeneralData = createAsyncThunk(
  '/getGeneralData',
  async (data, thunkApi) => {
    try {
      const res = await getGeneralService()
      thunkApi.dispatch(getGeneralDataSuccess(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
