export const getDeviceType = () => {
  const height = window.screen.height
  const width = window.screen.width
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    if (height <= 950 && width <= 480) {
      return 'MOBILE'
    } else {
      return 'TABLET'
    }
  }else{
    return 'DESKTOP'
  }
}
