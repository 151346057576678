export default {
  404: '404',
  register: 'Daftar',
  login: 'Gabung',
  grand: 'Agung',
  major: 'Besar',
  minor: 'Minor',
  mini: 'Mini',
  crashGameTitle: 'Menabrak',
  betAmount: 'Jumlah Taruhan',
  profit: 'Laba',
  payout: 'Target Pembayaran',
  chance: 'Menangkan Peluang',
  betBtn: 'Bertaruh',
  autoCashout: 'Pembayaran Otomatis',
  btnHalf: '½',
  btnDouble: '2×',
  btnMax: 'Maks',
  wagered: 'Bertaruh',
  lost: 'Hilang',
  won: 'Won',
  streak: 'Garis',
  highestWin: 'Kemenangan Tertinggi',
  highestLost: 'Hilang Tertinggi',
  highestBet: 'Taruhan Tertinggi',
  maxStreak: 'Garis Maks',
  maxProfit: 'Keuntungan Maks: ',
  bal: 'Bal: ',
  messagesNoBetHistory: 'Riwayat taruhan Anda kosong',
  messagesCopyNotSupported: 'Browser Anda tidak mendukung Salin ke Clipboard otomatis.\n',
  messagesCopied: 'Alamat disalin.',
  deposit: 'Menyetorkan',
  statReset: 'Setel ulang statistik',
  rollOver: 'Berputar',
  inputFieldBtnHalf: '½',
  inputFieldBtnDouble: '2×',
  inputFieldBtnMax: 'maks',
  buttonTextPlaceBet: 'Pasang Taruhan',
  buttonTextCancelBet: 'Batalkan Taruhan',
  buttonTextEscapeBet: 'Kas keluar',
  buttonTextStartAutoBet: 'Mulai Taruhan Otomatis',
  buttonTextCancelAutoBet: 'Batalkan Taruhan Otomatis',
  buttonTextLoading: 'Memuat',
  tabsMyBets: 'Taruhan saya',
  tabsDeposit: 'Menyetorkan',
  tabsCashout: 'Pembayaran',
  tabsAllbets: 'Semua Taruhan',
  tabsTopbets: 'Taruhan Teratas',
  tabsBonus: 'Bonusnya',
  tabsjackpot: 'Jackpot',
  tabsBetHistory: 'Sejarah Taruhan',
  timerTextCurrentPayout: 'Pembayaran saat ini',
  timerTextStarting: 'Mulai di',
  timerTextSec: 'S',
  timerTextFlewAway: 'Hancur',
  timerTextWait: 'Harap tunggu..',
  timerTextPreparingRound: 'Mempersiapkan putaran',
  timerTextLoadingRound: 'Memuat Putaran',
  comingSoon: 'Segera hadir !',
  labelsProfitOnWin: 'Untung saat menang',
  inputsNoOfBets: 'Jumlah taruhan',
  inputsAuto: 'Mobil',
  inputsHalf: '1/2',
  inputsDouble: '2×',
  inputsStart: 'Awal',
  inputsCancel: 'Membatalkan',
  buttonsMin: 'Minimal',
  buttonsMax: 'Maks',
  prediction: 'Ramalan',
  luckyNumber: 'Nomor keberuntungan',
  multiplier: 'Pengali',
  chanceToWin: 'Kesempatan untuk menang',
  bets: 'Taruhan',
  depositBtn: 'Menyetorkan',
  rollUnder: 'Gulung ke Bawah',
  symbolsX: 'X',
  symbolsPercent: '%',
  navBarTitle: 'Sejarah',
  navBarMyBets: 'Taruhan saya',
  navBarAllBets: 'Semua Taruhan',
  navBarHighRollers: 'Rol Tinggi',
  navBarRoundHistory: 'Sejarah Putaran',
  navBarTotal: 'Jumlah Taruhan',
  topHeaderBetId: 'ID Taruhan',
  topHeaderUser: 'Pengguna',
  topHeaderPayout: 'Pembayaran',
  topHeaderAmount: 'Jumlah',
  topHeaderTime: 'Waktu',
  topHeaderProfit: 'Laba',
  topHeaderRoundCrashed: 'Hancur',
  topHeaderRoundEscape: '@',
  topHeaderRoundRoundHash: 'hash',
  topHeaderRoundBet: 'Bertaruh',
  topHeaderRoundProfit: 'Laba',
  topHeaderRoundSignature: 'Tanda tangan',
  roundHistoryTitle: 'INFORMASI PERMAINAN',
  roundHistoryDescGame: 'Permainan',
  roundHistoryDescCrashAt: 'Jatuh Pada:',
  roundHistoryDescDate: 'Tanggal :',
  roundHistoryDescBetHeader: 'Bertaruh',
  roundHistoryTableTitlePlayer: 'PEMAIN',
  roundHistoryTableTitleBet: 'BERTARUH',
  roundHistoryTableTitleCashedOut: 'DIUANG',
  roundHistoryTableTitleProfit: 'LABA',
  roundHistoryTableTitleBetId: 'ID TARUHAN',
  roundHistoryMessagesNoBetHistory: 'Riwayat taruhan Anda kosong',
  placedBetsTabPlaced: 'Taruhan yang Dipasang',
  placedBetsTabPrev: 'Taruhan Sebelumnya',
  placedBetsTitleUser: 'Pengguna',
  placedBetsTitleEscape: 'Multi.x',
  placedBetsTitleBet: 'Bertaruh',
  placedBetsTitleProfit: 'Laba',
  placedBetsTitleCashout: 'Kas keluar',
  placedBetsNoPlacedBet: 'Tidak ada Taruhan yang dipasang.',
  provablyFairContentCheckBtn: 'Periksa Adil yang Dapat Dibuktikan',
  provablyFairContentTitle: 'Terbukti Adil',
  provablyFairContentDesc: 'Hasil dari empat pihak dapat dibuktikan adil. ',
  provablyFairContentInputFieldsCrashRateTitle: 'Tingkat Kecelakaan',
  provablyFairContentInputFieldsCrashRatePlaceHolder: 'Tingkat kerusakan tanpa x',
  provablyFairContentInputFieldsRoundHashTitle: 'Hash Bulat',
  provablyFairContentInputFieldsRoundHashPlaceHolder: 'misalnya ',
  provablyFairContentInputFieldsSubmitBtn: 'Hasilkan Tanda Tangan',
  provablyFairContentSignature: 'Tanda Tangan Bulat',
  provablyFairContentErrorsCrashRate: 'silakan masukkan nomor di kolom tingkat kerusakan',
  resetPasswordTitle: 'Atur Ulang Kata Sandi',
  resetPasswordNewPasswordTitle: 'kata sandi baru',
  resetPasswordNewPasswordErrorsRequired: '*katakunci dibutuhkan.',
  resetPasswordNewPasswordErrorsMinLength: 'Minimal 8 karakter diperbolehkan.',
  resetPasswordNewPasswordErrorsMaxLength: 'Maksimum 16 karakter diperbolehkan.',
  resetPasswordNewPasswordErrorsPattern: '*Kata sandi harus terdiri dari 8 hingga 16 karakter alfanumerik dan karakter khusus. ',
  resetPasswordConfirmPasswordTitle: 'konfirmasi sandi',
  resetPasswordConfirmPasswordErrorsNotMatched: 'Sandi tidak cocok.',
  resetPasswordResetBtn: 'Mengatur ulang',
  passwordResetSuccess: 'Kata sandi berhasil diubah.',
  resetPasswordWentWrong: 'Ada yang salah!',
  levelGrand: 'AGUNG',
  levelMajor: 'BESAR',
  levelMinor: 'MINOR',
  levelMini: 'MINI',
  bannerContentWinDaily: 'Menangkan $1000 Setiap Hari ',
  bannerContentContest: 'Kontes sekarang ',
  bannerContentjoin: 'Bergabunglah dalam kontes taruhan harian kami dan jadilah Pahlawan!  ',
  bannerContentPlayNow: 'Main sekarang',
  bannerContentTitle: 'Menangkan Jackpot',
  bannerContentContent: 'Main lagi',
  bannerContentGameName: 'PERMAINAN KEJADIAN',
  bannerContentGameDesc: 'Main lagi',
  partners: 'Mitra',
  gamesGoldenDragon: 'Naga Emas',
  gamesCrashGame: 'Permainan Kecelakaan',
  gamesDice: 'Dadu',
  gamesHilo: 'Halo',
  allBetsTitle: 'Semua Taruhan',
  allBetsBetId: 'ID Taruhan',
  allBetsUser: 'Pengguna',
  allBetsPayout: 'Pembayaran',
  allBetsAmount: 'Jumlah',
  allBetsTime: 'Waktu',
  allBetsProfit: 'Laba',
  LoginwithOtp: 'Masuk dengan Otp',
  EnterLoginOtp: 'Masukkan Login Otp',
  submit: 'Kirim',
  footerAboutUs: 'Tentang kami',
  footerFaq: 'FAQ',
  footerSitePolicy: 'Kebijakan Situs',
  footerResponsibleGambling: 'Perjudian yang Bertanggung Jawab',
  footerTerms: 'syarat dan Ketentuan',
  footerCopywrite: 'GARIS KRIPTO 2022. ',
  signinTitle: 'Gabung',
  signinUsername: 'Username atau email',
  signinPassword: 'Kata sandi',
  signinLoginBtn: 'Gabung',
  signinSignup: 'Mendaftar',
  signinForgetPassword: 'Tidak ingat kata sandi?',
  signinDontHaveAccount: 'Belum punya akun? ',
  signinCreateAccount: 'Buat Akun',
  signinLoginDescription: 'Clutch Gaming terkadang mengirimi Anda penawaran gratis dan promosi khusus melalui email atau SMS. ',
  signinErrorsUserNameRequired: '*Silakan masukkan Nama Pengguna/Email.',
  signinErrorsUserNameMinLength: 'Minimal 4 karakter diperbolehkan.',
  signinErrorsUserNameMaxLength: 'Maksimum 20 karakter diperbolehkan.',
  signinErrorsUserNamePattern: 'Silakan masukkan nama pengguna yang valid, hanya huruf dan angka yang diperbolehkan.',
  signinErrorsPasswordRequired: '*Katakunci dibutuhkan.',
  signinErrorsPasswordMinLength: 'Minimal 8 karakter diperbolehkan.',
  signinErrorsPasswordMaxLength: 'Maksimum 16 karakter diperbolehkan.',
  signinErrorsPasswordPattern: '*Kata sandi harus terdiri dari 8 hingga 16 karakter alfanumerik dan karakter khusus. ',
  signinSuccessfullyLoggedIn: 'Berhasil masuk.',
  signinResetMailSent: 'Email setel ulang kata sandi terkirim.',
  signinTryAgain: 'Ada yang tidak beres! Silakan coba lagi.',
  signinResetPasswordUsernameErr: 'Silakan masukkan nama pengguna/email untuk mengatur ulang kata sandi.',
  signinResetMailSentSuccessContent: 'Kami mengirimi Anda tautan setel ulang kata sandi di email Anda. ',
  signupTitle: 'Daftar',
  signupUsername: 'Nama belakang',
  signupEmail: 'Surel',
  signupTermsConditions: 'Ketentuan',
  signupWalletConnect: 'Masuk dengan Dompet ',
  signupPassword: 'Kata sandi',
  signupEighteenPlus: 'Saya berusia di atas 18 tahun dan saya menyetujui Persyaratan Layanan',
  signupRegisterBtn: 'Mendaftar',
  signupCreateNewAccount: 'Buat akun baru',
  signupFullName: 'Nama lengkap',
  signupReferralCode: 'Kode Referensi',
  signupConfirmPassword: 'konfirmasi sandi',
  signupUseSocialAccount: 'Atau Gunakan Akun Sosial',
  signupFacebook: ' Facebook',
  signupGoogle: ' Google',
  signupHaveAccount: 'Punya akun? ',
  signupLoginBtn: 'Gabung',
  signupErrorsUserNameRequired: '*Silakan masukkan nama pengguna.',
  signupErrorsUserNameMinLength: 'Minimal 4 karakter diperbolehkan.',
  signupErrorsUserNameMaxLength: 'Maksimum 20 karakter diperbolehkan.',
  signupErrorsUserNamePattern: 'Silakan masukkan nama pengguna yang valid, hanya huruf dan angka yang diperbolehkan.',
  signupErrorsFullNameRequired: '*Silakan masukkan nama lengkap.',
  signupErrorsFullNamePattern: 'Silakan masukkan nama lengkap yang valid, hanya huruf dan angka yang diperbolehkan.',
  signupErrorsEmailRequired: '*Silakan masukkan email.',
  signupErrorsEmailMinLength: 'Minimal 4 karakter diperbolehkan.',
  signupErrorsEmailMaxLength: 'Maksimum 20 karakter diperbolehkan.',
  signupErrorsEmailPattern: 'Silakan masukkan email yang valid.',
  signupErrorsPasswordRequired: '*Katakunci dibutuhkan.',
  signupErrorsPasswordMinLength: 'Minimal 8 karakter diperbolehkan.',
  signupErrorsPasswordMaxLength: 'Deposit Maksimum 16 karakter diperbolehkan.',
  signupErrorsPasswordPattern: '*Kata sandi harus terdiri dari 8 hingga 16 digit alfanumerik (huruf besar dan kecil) dan karakter khusus. ',
  signupErrorsConfirmPasswordMatch: 'Password harus sama dengan.',
  signupPlaceholderUserName: 'Minimal. ',
  signupPlaceholderEmail: 'misalnya ',
  signupPlaceholderPassword: 'Minimal. ',
  signupSomethingWentWrong: 'Ada yang salah!',
  signupSignupGoogle: 'Berhasil mendaftar dengan Google.',
  signupRegistered: 'Pendaftaran berhasil.',
  signupConfirmAge: 'Harap konfirmasikan bahwa Anda berusia 18 tahun.',
  signupSignupFacebook: 'Berhasil mendaftar dengan Facebook.',
  signupCookiesConsent: 'Harap izinkan cookie pihak ketiga dari pengaturan browser Anda untuk menggunakan login Sosial.',
  signupRegisterEmailPopupContent: 'Kami mengirimi Anda email verifikasi. ',
  accountMenuDeposit: 'Menyetorkan',
  accountMenuCashout: 'Kas keluar',
  accountMenujackpotWinners: 'Pemenang Jackpot',
  accountMenuTransactions: 'Transaksi',
  accountMenuPromotions: 'Promosi',
  accountMenuProfile: 'Profil',
  accountMenuLogout: 'Keluar',
  accountMenuSound: 'Suara',
  accountMenuMusic: 'Musik',
  accountMenuWithdraw: 'Menarik',
  pageNotFound: 'Kami tidak dapat menemukan halaman yang Anda cari!',
  error: 'Kesalahan',
  somethingWentWrong: 'Ada yang salah!',
  verifyEmailThankYou: 'Terima kasih!',
  verifyEmailEmailIsVerified: 'Email Anda telah diverifikasi. ',
  verifyEmailGoToLogin: 'Buka halaman Masuk',
  verifyEmailWentWrong: 'Ada yang salah!',
  verifyEmailTokenExpired: 'Email sudah terverifikasi atau link kadaluwarsa, silakan kirim ulang link verifikasi email dengan memberikan email terdaftar Anda di bawah. ',
  verifyEmailGoToHome: 'Buka halaman Beranda',
  verifyEmailSuccessMsg: 'Email berhasil diverifikasi.',
  gameCardTitle: 'Clutch Gaming Situs Perjudian Kripto yang Terbukti Adil',
  bonusSectionTitle: 'GAME KOPLING',
  bonusSectionSpanText: 'menyajikan yang paling dermawan dan beragam',
  bonusSectionBonuses: 'Bonus',
  bonusSectionSubtitleText: 'Mainkan dengan senang hati dan dapatkan hadiah tambahan selama permainan. ',
  bonusSectionHeadingText: 'Bonus dari Clutch Gaming',
  bonusSectionDesc: 'Sebagai kasino Bitcoin terbaik, Clutch Gaming memiliki sistem bonus besar yang rumit. ',
  JoiningBonusTitle: 'Bonus Bergabung',
  JoiningBonusButton: 'Klaim Sekarang',
  JoiningBonusDesc: 'Bonus semacam itu mendorong pemain kasino online untuk berjudi menggunakan Bitcoin atau kripto lainnya. ',
  depositBonusTitle: 'Bonus Setoran',
  depositBonusButton: 'Menyetorkan',
  depositBonusDesc: 'Bonus semacam itu mendorong pemain kasino online untuk berjudi menggunakan Bitcoin atau kripto lainnya. ',
  refferBonusTitle: 'Bonus Referensi',
  refferBonusButton: 'Menyetorkan',
  refferBonusDesc: 'Bonus semacam itu mendorong pemain kasino online untuk berjudi menggunakan Bitcoin atau kripto lainnya. ',
  aboutSectionTitle: 'Apa itu Kopling Gaming?',
  aboutSectionSpanText1: ' Clutch Gaming adalah situs kasino Bitcoin inovatif, dibuat dengan mempertimbangkan konsep perjudian online baru. ',
  aboutSectionSpanText2: 'Kasino online memungkinkan Anda memainkan permainan favorit Anda secara gratis atau dengan mata uang kripto. ',
  aboutSectionSpanText3: ' Di dunia virtual perjudian Bitcoin ini, Anda dapat menikmati banyak peluang — memainkan permainan kasino favorit Anda, mendapatkan bonus, berpartisipasi dalam kontes dan promosi.',
  verifiedSectionTitle: 'Perangkat Lunak Terverifikasi dan Berlisensi',
  verifiedSectionSpanText1: 'Kami tidak menyalin atau mengadopsi perangkat lunak dari situs web lain. ',
  faqSectionTitle: 'FAQ dari Pemain',
  faqSectionForgetPasswordTitle: 'Saya lupa kata sandi saya.',
  faqSectionForgetPasswordDesc: 'Anda tidak memberikan alamat email pemulihan untuk akun Anda. ',
  faqSectionMyDepositCreditedTitle: 'Deposit saya belum dikreditkan.',
  faqSectionMyDepositCreditedDesc: ' Konten placeholder untuk akordeon ini, yang dimaksudkan untuk mendemonstrasikan kelas. ',
  SupportedCurrencyTitle: 'Mata Uang yang Didukung',
  SupportedCurrencySpanText1: 'Kami tidak menyalin atau mengadopsi perangkat lunak dari situs web lain. ',
  CarouselSectionTitle: 'KEJADIAN HODL',
  CarouselSectionSubTitle: ' Mainkan lebih banyak game dan dapatkan peluang untuk menang. ',
  WalletSettingTitle: 'Pengaturan Dompet',
  WalletSettingHideZero: 'Sembunyikan saldo nol',
  WalletSettingHideZeroInfo: 'Saldo nol Anda tidak akan muncul di dompet Anda',
  WalletSettingDisplayFiat: 'Tampilkan kripto dalam fiat',
  WalletSettingDisplayFiatInfo: 'Semua taruhan',
  WalletSettingNoteForApprox: 'Harap dicatat bahwa ini adalah perkiraan mata uang.',
  WalletSettingSave: 'Menyimpan',
  WalletSettingNoWalletFound: 'Tidak ada dompet yang ditemukan',
  jackpotWinnersNoWinnerAvailable: 'Tidak ada pemenang yang tersedia',
  jackpotWinnersNew: 'Baru',
  jackpotWinnersHeader: 'Pemenang Jackpot',
  jackpotDetailsHeader: 'Detail jackpot',
  jackpotDetailsCampaignPeriod: 'Periode Kampanye',
  jackpotDetailsFrom: 'Dari',
  jackpotDetailsTo: 'Ke',
  jackpotDetailsBettingRule: 'Aturan Taruhan',
  jackpotDetailsMinBetAmount: 'Jumlah taruhan minimum',
  jackpotDetailsMaxBetAmount: 'Jumlah taruhan maksimal',
  jackpotDetailsBetPercentage: 'Persentase taruhan',
  jackpotDetailsWinningRuleHeader: 'Aturan Kemenangan',
  jackpotDetailsWinningRule: 'Memasang taruhan dengan jumlah taruhan lebih tinggi akan meningkatkan peluang menang.',
  jackpotDetailsProfitRule: 'Jika jumlah taruhan antara minBetAmount dan maxBetAmount maka persentase taruhan% dari jumlah taruhan akan ditambahkan ke jumlah jackpot.',
  profileTitle: 'Data pribadi',
  profileFName: 'Nama depan',
  profileLname: 'Nama keluarga',
  profileUserName: 'Nama belakang',
  profileDob: 'Tanggal lahir',
  profileEmail: 'Alamat email',
  profilePhone: 'Nomor telepon',
  profileChange: 'Mengubah',
  profileSave: 'Simpan perubahan',
  profilePassword: 'Kata sandi',
  profileSubmit: 'Kirim',
  profileUploadProfile: 'Unggah Profil',
  profileCurrentPassword: 'kata sandi saat ini',
  profileNewPassword: 'kata sandi baru',
  profileConfirmPassword: 'konfirmasi sandi',
  profileScanQRCode: 'Pindai kode QR',
  profileEnterSecretCode: 'Masukkan Kode Rahasia',
  profileErrorsFirstNameRequired: 'Nama depan wajib diisi.',
  profileErrorsFirstNamePattern: 'Karakter di Nama Depan Anda tidak valid. ',
  profileErrorsFirstNameMinLength: 'Minimal 3 karakter diperbolehkan.',
  profileErrorsFirstNameMaxLength: 'Maksimum 20 karakter diperbolehkan.',
  profileErrorsLastNameRequired: 'Nama belakang wajib diisi.',
  profileErrorsLastNamePattern: 'Karakter di Nama Belakang Anda tidak valid. ',
  profileErrorsLastNameMinLength: 'Minimal 3 karakter diperbolehkan.',
  profileErrorsLastNameMaxLength: 'Maksimum 20 karakter diperbolehkan.',
  profileErrorsChangePasswordOldRequired: 'Kata sandi lama diperlukan.',
  profileErrorsChangePasswordOldPattern: 'Silakan masukkan kata sandi yang valid.',
  profileErrorsChangePasswordOldMinLength: 'Minimal 8 karakter diperbolehkan.',
  profileErrorsChangePasswordOldMaxLength: 'Maksimum 16 karakter diperbolehkan.',
  profileErrorsChangePasswordNewRequired: 'Kata sandi baru diperlukan.',
  profileErrorsChangePasswordNewPattern: 'Silakan masukkan kata sandi yang valid.',
  profileErrorsChangePasswordNewMinLength: 'Minimal 8 karakter diperbolehkan.',
  profileErrorsChangePasswordNewMaxLength: 'Maksimum 16 karakter diperbolehkan.',
  profileErrorsChangePasswordNewPasswordNotMatched: 'Sandi tidak cocok.',
  profileErrorsChangePasswordNewPasswordMatched: 'Kata sandi baru tidak boleh sama dengan kata sandi saat ini.',
  profileErrorsChangePasswordNewRequiredConfirmPass: 'Konfirmasi kata sandi diperlukan.',
  profileErrorsUserNameRequired: 'Nama pengguna diperlukan.',
  profileErrorsUserNamePattern: 'Silakan masukkan nama yang valid.',
  profileErrorsUserNameMinLength: 'Minimal 3 karakter diperbolehkan.',
  profileErrorsUserNameMaxLength: 'Maksimum 20 karakter diperbolehkan.',
  profileErrorsDobRequired: 'Tanggal lahir wajib diisi.',
  profileErrorsEmailRequired: 'Email diperlukan.',
  profileErrorsPostcodeRequired: 'Kode pos diperlukan.',
  profileErrorsCityRequired: 'Kota diperlukan.',
  profileErrorsCurrencyRequired: 'Mata uang diperlukan.',
  profileErrorsAddressRequired: 'Alamat diperlukan.',
  profileErrorsCountryRequired: 'Negara diperlukan.',
  profileErrorsEmailPattern: 'Silakan masukkan email yang valid.',
  profileErrorsEmailMinLength: 'Minimal 3 karakter diperbolehkan.',
  profileErrorsEmailMaxLength: 'Maksimum 20 karakter diperbolehkan.',
  profileMessagesProfileUpdate: 'Profil berhasil diperbarui.',
  profileMessagesChangePassword: 'Kata sandi berhasil diperbarui.',
  profileProfilePicProfilePicUpload: 'Unggah gambar profil',
  profileNoNewChanges: 'Tidak dapat memperbarui profil! ',
  profileLoggedInWithSocial: 'Anda telah masuk dengan login sosial.',
  profileSideNavigatorSound: 'Suara',
  profileSideNavigatorMusic: 'Musik',
  profileSideNavigatorProfile: 'Profil saya',
  profileSideNavigatorBetHistory: 'Sejarah Taruhan',
  profileSideNavigatorTransactions: 'Transaksi',
  profileSideNavigatorDeposit: 'Menyetorkan',
  profileSideNavigatorWithdraw: 'Menarik',
  profileSideNavigatorGameLimits: 'Batasan Permainan',
  profileSideNavigatorReferral: 'Rujukan',
  profileSideNavigatorChangePassword: 'Ganti kata sandi',
  profileSideNavigatorTermsConditions: 'Ketentuan',
  profileSideNavigatorLogOut: 'Keluar',
  profileSideNavigatorProvablyFair: 'Terbukti Adil',
  WalletInfoSectionNotAvailable: 'Tidak tersedia',
  WalletInfoSectionRealBalance: 'Keseimbangan Nyata',
  GameLimitsMinimumBet: 'Taruhan minimal',
  GameLimitsMaximumBet: 'Taruhan maksimal',
  GameLimitsMaxWinFor1Bet: 'Kemenangan maksimal untuk satu taruhan',
  ProvablyFairSubTitle: 'Game ini menggunakan teknologi Terbukti Adil untuk menentukan hasil permainan. ',
  ProvablyFairMaximumBet: 'Taruhan maksimum:',
  ProvablyFairMaxWinFor1Bet: 'Kemenangan maksimal untuk satu taruhan',
  promotionsTitle: 'Promosi',
  promotionsNoLosingData: 'Maaf, promosi bonus Tanpa Kalah tersedia untuk ',
  promotionsNoDepositData: 'Maaf, promosi bonus Tanpa Deposit tersedia untuk ',
  promotionsCurrency: ' mata uang.',
  promotionsViewBtn: 'Melihat',
  promotionsClaimSuccess: 'Anda telah berhasil mengklaim',
  promotionsAvailability: 'Ketersediaan: ',
  promotionsAvailabilityTabLosing: 'Kehilangan bonus',
  promotionsAvailabilityTabDeposit: 'Bonus setoran',
  promotionsDepositBonusTableCode: 'Kode',
  promotionsDepositBonusTableMinDeposit: 'Minimal. ',
  promotionsDepositBonusTablePercentage: 'Persentase',
  promotionsDepositBonusTableMaxBonus: 'Bonus Maks',
  promotionsDepositBonusTableRolloverMultipler: 'Pengganda putaran',
  promotionsDepositBonusTableMaxRolloverPerBet: 'Rollover maksimum per taruhan',
  promotionsDepositBonusTableTimeToAchieveRolloverTarget: 'Saatnya untuk mencapai target rollover',
  promotionsBonusCode: 'KODE BONUS: ',
  promotionsLossesClaim: 'Kerugian dapat diklaim (dalam periode kampanye): ',
  promotionsLossesClaimLosingBonusTablePercentage: 'Persentase',
  promotionsLossesClaimLosingBonusTableMinLosingAmount: 'Minimal. ',
  promotionsBonusStatus: 'Status Bonusnya: ',
  promotionsBonusAmount: 'Jumlah Bonus: ',
  promotionsRolloverTarget: 'Target putaran: ',
  promotionsRolloverAchieved: 'Perputaran tercapai: ',
  promotionsActivatebtn: 'MENGAKTIFKAN',
  promotionsClaimBtn: 'MENGEKLAIM',
  promotionsCancelBtn: 'MEMBATALKAN',
  promotionsBackBtn: 'KEMBALI',
  promotionsBonus: 'Bonusnya',
  promotionsTermAndConditions: ' Ketentuan',
  promotionsClaimDepositBonusTitle: 'Langkah-langkah untuk mengklaim bonus deposit',
  promotionsClaimDepositBonusFirst: 'Aktifkan kode Bonus',
  promotionsClaimDepositBonusSecond: 'Jumlah setoran ke dompet',
  promotionsClaimDepositBonusThird: 'Mencapai target rollover untuk mengklaim bonus',
  promotionsClaimLosingBonusTitle: 'Langkah-langkah untuk mengklaim bonus yang kalah',
  promotionsClaimLosingBonusFirst: 'Aktifkan kode Bonus',
  promotionsClaimLosingBonusSecond: 'Mainkan game favorit Anda',
  promotionsClaimLosingBonusThird: "Klik 'Klaim kerugian' untuk mengklaim kerugian selama periode kampanye",
  promotionsWentWrong: 'Ada yang salah!',
  transactionTitle: 'Transaksi',
  transactionBetId: 'ID Taruhan',
  transactionUser: 'Pengguna',
  transactionPayout: 'Pembayaran',
  transactionAmount: 'Jumlah',
  transactionTime: 'Tanggal',
  transactionProfit: 'Laba',
  transactionCashout: 'Kas keluar',
  transactionBet: 'Bertaruh',
  transactionMultix: 'Multi.x',
  transactionWin: 'Menang',
  transactionFairness: 'Keadilan',
  transactionReferFriend: 'Menunjuk teman',
  transactionTotal: 'Jumlah Taruhan',
  transactionWins: 'Menang',
  transactionRefresh: 'Menyegarkan',
  transactionFilterTopMultipliers: 'Pengganda Teratas',
  transactionFilterHugeWins: 'Kemenangan Besar',
  transactionFilterBiggestWins: 'Kemenangan Terbesar',
  transactionFilterMultipliers: 'Pengganda',
  transactionRealBalance: 'Keseimbangan Nyata',
  depositWithdrawTXDate: 'Tanggal',
  depositWithdrawTXAmount: 'Jumlah',
  depositWithdrawTXTxId: 'TxID',
  depositWithdrawTXTxType: 'Tipe transaksi',
  depositWithdrawTXStatus: 'Status',
  depositWithdrawTXNoData: 'Tidak ada data yang tersedia.',
  depositTitle: 'Menyetorkan',
  depositHistory: 'Sejarah',
  depositSubTitle: 'Pengguna tidak dapat menyetor atau menarik mata uang fiat. ',
  depositCopyNotSupported: 'Browser Anda tidak mendukung Salin ke Clipboard otomatis.\n',
  depositCopied: 'Alamat disalin.',
  depositAddressCreated: 'Alamat berhasil dibuat.',
  depositGenerateAddress: 'Hasilkan alamat',
  depositWarningText: 'Harap JANGAN menyetor melalui transfer lintas rantai',
  withdrawTitle: 'Menarik',
  withdrawSubTitle: 'Pengguna tidak dapat menyetor atau menarik mata uang fiat. ',
  withdrawNoteDesc: 'Harap pastikan bahwa Anda TIDAK memasukkan alamat BEP2, BEP20 (BSC) sebagai dompet alamat penarikan LTC',
  withdrawNoteSublist: ' Harap JANGAN menarik sebagai transfer lintas rantai',
  withdrawHistory: 'Sejarah',
  withdrawWithdrawBtn: 'Permintaan Penarikan',
  withdrawFees: 'Biaya penarikan {{fees}} {{token}}',
  withdrawWithdrawPending: 'Permintaan penarikan Anda tertunda. ',
  withdrawEnterFields: 'Masukkan jumlah penarikan dan alamat dompet Anda.',
  withdrawSelectWallet: 'Silakan pilih dompet untuk ditarik.',
  withdrawAmountFieldTitle: 'Jumlah (Min. 0,03 {{wallet.currency.code}})',
  withdrawAmountFieldPlaceholder: '0',
  withdrawAddressFieldTitle: 'Milikmu {{wallet.currency.code}} alamat',
  withdrawAddressFieldPlaceholder: 'Ketikkan alamat Anda',
  withdrawNoBalance: 'Saldo tidak cukup.',
  withdrawMinAmount: 'Silakan masukkan jumlah yang lebih besar atau sama dengan {{min}} .',
  fiatCurrencyInfo: 'Pengguna tidak dapat menyetor atau menarik mata uang fiat. ',
  paginationNextBtn: 'Berikutnya',
  paginationPrevBtn: 'Sebelumnya',
  wentWrong: 'Ada yang salah.',
  referralTitle: 'Rujukan',
  referralSubTitle: 'Referensikan Teman Anda',
  referralEarn: ' Hasilkan masing-masing ₹150',
  referralReferAndEarn: 'Referensikan dan dapatkan bonus untuk setiap referensi',
  referralReferPara: ' Jika Anda merujuk kode ini ke pengguna mana pun',
  referralReferLink: 'Tautan Referensi',
  referralCopyReferralCode: 'Salin Kode Referensi',
  referralTotalRewards: 'Jumlah Hadiah',
  TwoWayAuthentication: 'Otentikasi Dua Arah',
  LoginRequired: 'Login dibutuhkan!',
  BetPlacedSuccessfully: 'Taruhan berhasil dipasang',
  BetAddedInQueueSuccessfully: 'Taruhan berhasil ditambahkan dalam antrian',
  BetRemovedFromQueueSuccessfully: 'Taruhan berhasil dihapus dari antrian',
  BetCancelledSuccessfully: 'Taruhan berhasil dibatalkan',
  PlayerEscapedSuccessfully: 'Pemain berhasil lolos',
  CopiedSuccessfull: 'Berhasil disalin',
  NotEnoughBalance: 'Saldo tidak cukup',
  AutoBetStarted: 'Taruhan otomatis dimulai ',
  AutoBetFinished: 'Taruhan otomatis selesai ',
  IdCopied: 'Aku menyalinnya',
  usersConsent: 'Dengan mengakses situs ini, saya mengonfirmasi bahwa saya berusia 18 tahun, dan telah mengulas,\n ',
  termsOfService: 'Ketentuan Layanan',
  email: 'Surel',
  playNow: 'Main sekarang',
  orContinueWith: 'Atau Lanjutkan Dengan',
  metamaskExtensionNotAvailable: 'Silakan instal ekstensi metaMask dan segarkan halaman.',
  cashier: 'KASIR',
  account: 'AKUN',
  hello: 'Halo',
  balance: 'Keseimbangan',
  referrals: 'Referensi',
  settings: 'Pengaturan',
  withdrawals: 'Penarikan',
  demoWallet: 'Dompet Demo',
  myAccount: 'Akun saya',
  wallet: 'Dompet',
  user: 'Pengguna',
  USD: 'Rp',
  amountIsRequired: 'Jumlah diperlukan',
  minDepositAmount: 'Jumlah setoran harus lebih besar dari {{min}}.',
  minEqualDepositAmount: 'Jumlah setoran harus lebih besar atau sama dengan {{min}}.',
  minMaxDepositAmount: 'Jumlah deposit harus minimum {{min}} dan maksimal {{max}}.',
  maxDepositAmount: 'Jumlah setoran harus kurang dari {{max}}.',
  minWithdrawAmount: 'Jumlah penarikan harus lebih besar dari {{min}}',
  minEqualWithdrawAmount: 'Jumlah penarikan harus lebih besar atau sama dengan {{min}}',
  withdrawMustLessThanWallet: 'Jumlah penarikan harus kurang dari jumlah dompet Anda',
  accountTypeIsRequired: 'Jenis Akun wajib diisi.',
  mobileNumberLength: 'Nomor ponsel harus dari {{length}} angka',
  phoneIsRequired: 'Telepon diperlukan',
  cpfError: 'CPF harus dari {{length}} karakter',
  cpfRequiredError: 'CPF diperlukan',
  cnpjError: 'CNPJ harus dari {{length}} karakter',
  mainMenu: 'MENU UTAMA',
  casino: 'Kasino',
  sports: 'Olahraga',
  crypto: 'kripto',
  NFT: 'NFT',
  poker: 'Poker',
  earnRewards: 'DAPATKAN HADIAH',
  rankUp: 'Naik Peringkat',
  inviteFriends: 'Mengundang teman-teman',
  partnerProgram: 'Program Mitra',
  home: 'Rumah',
  sportsbook: 'Buku Olahraga',
  menu: 'Menu',
  liveBets: 'TARUHAN LANGSUNG',
  allBets: 'Semua taruhan',
  highRollers: 'Rol Tinggi',
  luckyBets: 'Taruhan Keberuntungan',
  trade: 'Berdagang',
  game: 'PERMAINAN',
  player: 'PEMAIN',
  time: 'WAKTU',
  wager: 'TARUHAN',
  multiplierBan: 'PENGALI',
  payoutText: 'PEMBAYARAN',
  bannerHeadOne: 'Rasakan inovasi sejati dengan yang tertinggi',
  bannerHeadTwo: 'program penghargaan dalam industri.',
  registerNow: 'Daftar sekarang',
  liveWins: 'KEMENANGAN LANGSUNG',
  monthly: 'M',
  weekly: 'W',
  daily: 'D',
  clutchEcosystem: 'ekosistem kopling',
  allGames: 'Semua permainan',
  allSports: 'Semua olahraga',
  cryptoFutures: 'kripto berjangka',
  tradeNow: 'Berdagang Sekarang',
  $clutch: '$kopling',
  allTables: 'Semua Tabel',
  nft: 'NFT',
  viewAll: 'Lihat semua',
  rewards: 'HADIAH',
  profile: 'Profil',
  clutchProfile: 'PROFIL KOPLING',
  totalBets: 'Jumlah Taruhan',
  totalWagered: 'Jumlah Taruhan',
  joinDate: 'Tanggal Bergabung',
  displaySocialLinks: 'Tampilkan Akun Sosial Tertaut',
  privateProfile: 'Profil Pribadi',
  rank: 'Pangkat',
  nextRank: 'Peringkat Berikutnya',
  changeUsername: 'Ubah nama pengguna',
  newPassword: 'Kata sandi baru',
  changeName: 'Ganti Nama',
  linkSocialAccount: 'Tautkan akun sosial',
  privateProfileTooltip: 'Setelah menjadikan profil Anda pribadi, pengguna lain tidak akan melihat nama pengguna Anda di samping taruhan, balapan, dll.',
  socialLinksTooltip: 'Konten tidak tersedia',
  rankTooltip: 'Konten tidak tersedia',
  balances: 'saldo',
  createReferralBalance: 'Buat saldo rujukan',
  total: 'Total',
  switchBalance: 'Ganti Saldo',
  mainAccount: 'Akun utama',
  tips: 'Kiat',
  receivedAmount: 'Jumlah yang diterima',
  dateOrTime: 'Tanggal Waktu',
  view: 'Melihat',
  allChains: 'Semua Rantai',
  creditAmount: 'Jumlah kredit',
  setCustomReferralCode: 'Tetapkan kode referensi khusus',
  changeEmail: 'Ganti e-mail',
  newEmail: 'Email baru',
  createPassword: 'Buat kata sandi',
  newUsername: 'Nama baru',
  username: 'Nama belakang',
  changePassword: 'Ganti kata sandi',
  twoFactorAuthentication: 'otentikasi dua faktor',
  twoFactorAuthenticationTitle: 'Sangat disarankan untuk menggunakan autentikasi dua faktor karena ini melindungi akun Anda dengan kata sandi dan ponsel Anda.',
  twoFactorAuthenticationSubTitle: 'Email dan kata sandi harus diatur untuk mengaktifkan 2FA. ',
  enable2fa: 'AKTIFKAN 2FA',
  verifyIdentityByKyc: 'Verifikasi identitas Anda (kyc)',
  oldPassword: 'Password lama',
  live: 'Hidup',
  soccer: 'Sepak bola',
  tennis: 'Tenis',
  fifa: 'FIFA',
  basketball: 'Bola basket',
  iceHockey: 'Hoki Es',
  volleyball: 'Bola voli',
  tableTennis: 'Tenis meja',
  baseball: 'Baseball',
  rubgy: 'Ragbi',
  golf: 'Golf',
  boxing: 'Tinju',
  myBets: 'Taruhan saya',
  favourites: 'Favorit',
  americanFootball: 'Sepak Bola Amerika',
  buyCrypto: 'Beli Kripto',
  oldEmail: 'Email Lama',
  loggedInSuccess: 'Berhasil masuk',
  loggedOutSuccess: 'Berhasil logout',
  wagerLimit: 'Batas taruhan berhasil ditetapkan',
  lossLimit: 'Batas kerugian berhasil ditetapkan',
  accountDisable: 'Akun berhasil dinonaktifkan',
  sessionTimeout: 'Batas waktu sesi berhasil ditetapkan',
  signup: 'Pendaftaran berhasil',
  depositLimit: 'Batas setoran berhasil ditetapkan',
  tokenVerify: 'Token telah dikirimkan ke Email Anda, silakan verifikasi',
  emailVerify: 'Email telah diverifikasi',
  emailChangedSuccess: 'Email telah berhasil diubah',
  profileUpdatedSuccess: 'Profil diperbarui',
  otpVerified: 'OTP terverifikasi',
  twoFADeactivated: 'Otentikasi 2FA dinonaktifkan',
  unAuthorized: 'Tidak sah',
  dataNotFound: 'Data Tidak Ditemukan',
  setReferralCode: 'Tetapkan kode rujukan...',
  ultimate: 'TERAKHIR',
  notFound: 'Tidak ditemukan',
  casinoTransactions: 'Transaksi Kasino',
  clutchOriginals: 'Kopling Asli',
  sportsbookTransactions: 'Transaksi Sportsbook',
  status: 'Status',
  success: 'Kesuksesan',
  failed: 'Gagal',
  pending: 'Tertunda',
  date: 'Tanggal',
  to: 'Ke',
  gameName: 'Nama permainan',
  transactionId: 'ID transaksi',
  roundId: 'ID Bulat',
  bet: 'Bertaruh',
  win: 'Menang',
  transactionType: 'tipe transaksi',
  at: 'pada',
  downloadAll: 'Unduh Semua',
  gameText: 'Permainan',
  betId: 'ID Taruhan',
  gameId: 'ID permainan',
  actionType: 'tipe aksi',
  searchGames: 'Permainan Pencarian',
  provider: 'Pemberi',
  search: 'Mencari',
  sortBy: 'Sortir dengan',
  popular: 'Populer',
  challengePool: 'Kolam Tantangan',
  loadMore: 'Muat lebih banyak',
  bitcoin: 'Bitcoin',
  chat: 'Mengobrol',
  trades: 'Perdagangan',
  battles: 'Pertempuran',
  loginToChat: 'Masuk ke obrolan',
  rules: 'Aturan',
  chatRule1: 'Jangan melecehkan atau menghina pengguna lain',
  chatRule2: 'Jangan mengemis, meminta pinjaman, hal-hal sepele atau tip',
  chatRule3: 'Jangan menyindir Kopling mempunyai niat buruk ("situs scam" dll)',
  chatRule4: 'Jangan mengirim spam atau memposting tautan non-Clutch',
  chatRule5: 'Jangan mengiklankan segala bentuk layanan perdagangan, pembelian atau penjualan',
  chatRule6: 'Jangan membagikan atau mengiklankan kode referral Anda',
  chatRule7: 'Jangan meminta untuk menjadi anggota staf',
  chatRule8: 'hanya bahasa Inggris',
  chatRule9: 'Hormati mod, admin, dan pengguna lain',
  send: 'Mengirim',
  expand: 'Memperluas',
  signIn: 'Masuk',
  cross: 'Menyeberang',
  collapse: 'Runtuh',
  emailPlaceholder: 'Masukkan alamat email Anda',
  removeFromFavourite: 'Hapus dari favorit',
  addToFavourite: 'Tambahkan ke favorit',
  footerAboutSite: 'Hak Cipta © 2022-2023 Gammastack.com dimiliki dan dioperasikan oleh HNA Gaming N.V., sebuah perusahaan yang terdaftar dan didirikan berdasarkan hukum Curacao. ',
  footerRightsReserved: 'Tumpukan Gamma 2023. ',
  signupFirstName: 'Nama depan',
  signupAddress: 'Alamat',
  signupCity: 'Kota',
  signupPostcode: 'Kode Pos ',
  signupCounty: 'Negara',
  signupCurrency: 'Mata uang',
  signupGender: 'Jenis kelamin',
  signupMan: 'Pria',
  signupWomen: 'Wanita',
  signupOther: 'Lainnya',
  signupLoginDetails: 'Masuk di Detail',
  signupPersonalDetails: 'Data pribadi',
  signupConfirm: 'Mengonfirmasi',
  signupPrivacyPolicy: 'Saya telah membaca dan menerima Kebijakan Privasi',
  signupTermAndConditions: 'Saya setuju dengan pemrosesan data pribadi saya. ',
  signupNewsLetter: 'Saya ingin menerima bonus, promosi, berita atau informasi relevan lainnya secara berkala melalui Surat Berita.',
  signupSms: 'Saya ingin menerima bonus, promosi, berita atau informasi relevan lainnya secara berkala melalui SMS.',
  initialHeaderContent: 'Dapatkan 100% yang luar biasa',
  secoundaryHeaderContent: 'Bonus selamat datang hingga $100',
  playNowButton: 'Main sekarang',
  registerButtonContent: 'Daftar',
  results: 'Hasil',
  refresh: 'Menyegarkan',
  cancel: 'Membatalkan',
  select: 'Pilih',
  current: 'Saat ini',
  set: 'Mengatur',
  remove: 'Menghapus',
  save: 'Menyimpan',
  not: 'bukan',
  before: 'Sebelum',
  after: 'Setelah',
  action: 'Tindakan',
  withdrawal: 'Penarikan',
  requested: 'Diminta',
  withdraw: 'Menarik',
  areYouSure: 'Apa kamu yakin',
  yes: 'Ya',
  no: 'TIDAK',
  cancelled: 'Dibatalkan',
  approved: 'Disetujui',
  refunded: 'Dikembalikan',
  chargeback: 'Tagihan Balik',
  processedOn: 'Diproses Aktif',
  transaction: 'Transaksi',
  dateTime: 'Tanggal Waktu',
  more: 'Lagi',
  fromDate: 'Dari tanggal',
  toDate: 'Hingga saat ini',
  percentage: 'Persentase',
  info: 'Informasi',
  min: 'Minimal',
  max: 'Maks',
  change: 'Mengubah',
  continueToSite: 'Lanjutkan Ke Situs',
  choose: 'Memilih',
  enterWithdrawalAmount: 'Pilih atau masukkan Jumlah Penarikan Anda',
  capDeposit: 'MENYETORKAN',
  capWithdrawal: 'PENARIKAN',
  capAmount: 'JUMLAH',
  minimum: 'Minimum',
  required: 'Diperlukan',
  of: 'dari',
  isRequired: 'Dibutuhkan',
  isAllowed: 'Diperbolehkan',
  method: 'metode',
  capWithdraw: 'MENARIK',
  showLess: 'Tampilkan Lebih Sedikit',
  clearFilter: 'Hapus Filter',
  quantity: 'Kuantitas',
  reset: 'Mengatur ulang',
  incorrect: 'Salah',
  load: 'Memuat',
  applied: 'Terapan',
  ok: 'OKE',
  list: 'Daftar',
  all: 'Semua',
  favorite: 'Favorit',
  endPointNotFound: 'Ada yang salah dengan koneksi jaringan',
  category: 'Kategori',
  seeAll: 'Lihat semua',
  emailUserNameRequired: 'Email/Nama Pengguna Diperlukan',
  emailUserNamePlaceHolder: 'Masukkan Email atau Nama Pengguna Anda',
  passwordRequired: 'Katakunci dibutuhkan',
  newPasswordRequired: 'Kata Sandi Baru Diperlukan',
  accountFrozen: 'Akun Anda dibekukan sampai',
  resendEmail: 'Kirim Ulang Email',
  resendLink: 'KIRIM ULANG LINK',
  userLoggedOut: 'Berhasil Keluar',
  emailUserName: 'Masukkan Email/Nama Pengguna Anda',
  enterYourEmail: 'Masukkan alamat email Anda',
  emailAlreadyRegistered: 'email ini sudah terdaftar',
  userNameAlreadyTaken: 'Nama pengguna ini sudah dipakai',
  fillAllFields: 'Isi semua bidang sebelum melanjutkan ke langkah berikutnya',
  pleaseAccept: 'Tolong terima',
  acceptPrivacyPolicy: 'Kebijakan pribadi',
  acceptTerms: 'Syarat dan Ketentuan',
  modeOfComm: ' dan cara komunikasi',
  beforeNextStep: 'sebelum melanjutkan ke langkah berikutnya',
  userNamePlaceholder: 'Masukkan nama pengguna',
  min8Characters: 'Minimal 8 karakter',
  min1Number: 'Minimal 1 Nomor',
  min1LowerCase: 'Minimal 1 karakter huruf kecil',
  min1UpperCase: 'Min 1 karakter huruf besar',
  min1SpecialChar: 'Minimal 1 karakter khusus',
  passwordTip: 'Kata sandi minimal harus memiliki satu huruf besar, satu huruf kecil, satu angka, dan satu karakter khusus',
  newPasswordTip: 'Kata sandi baru harus memiliki minimal satu huruf besar, satu huruf kecil, satu angka, dan satu karakter khusus',
  commonWordsNotAllowed: "Kata sandi tidak boleh berupa kata umum seperti ('kata sandi@123', '123456', 'nama pengguna') atau urutan yang mudah ditebak.",
  passwordPlaceholder: 'Masukkan kata kunci',
  confirmPasswordPlaceholder: 'Masukkan Konfirmasi Kata Sandi',
  acceptAll: 'Terima semua',
  firstNamePlaceholder: 'Masukkan Nama Depan',
  lastNamePlaceholder: 'Masukkan Nama Belakang',
  addressPlaceholder: 'Masukkan alamat',
  cityPlaceholder: 'Masuk Kota',
  postCodePlaceholder: 'Masukkan Kode Pos',
  phonePlaceholder: 'Masukkan nomor telepon',
  invalidEmail: 'Email Tidak Valid, Silakan masukkan alamat email yang valid, seperti example@example.com',
  emailRequired: 'Email Diperlukan',
  confirmPasswordRequired: 'Konfirmasi Kata Sandi Diperlukan',
  firstNameRequired: 'Silakan masukkan Nama Depan Anda.',
  lastNameRequired: 'Silakan masukkan Nama Belakang Anda.',
  phoneRequired: 'Telepon Diperlukan',
  dateOfBirthRequired: 'Tanggal Lahir Diperlukan',
  genderRequired: 'Jenis Kelamin Wajib',
  userNameRequired: 'Nama Pengguna Diperlukan',
  addressRequired: 'Alamat Diperlukan',
  cityRequired: 'Dibutuhkan Kota',
  stateRequired: 'Negara Diperlukan',
  countryRequired: 'Diperlukan Negara',
  postCodeRequired: 'Kode Pos Diperlukan',
  currencyCodeRequired: 'Kode Mata Uang Diperlukan',
  matchPassword: 'Sandi tidak cocok. ',
  fName3Chars: 'Nama Depan Anda harus antara 3 hingga 50 karakter.',
  onlyAlphabet: 'Hanya Abjad yang diperbolehkan',
  lName3Chars: 'Nama Belakang minimal harus 3 karakter',
  mustBe18: 'Anda Harus berusia 18 Tahun untuk mendaftar',
  validDOB: 'Silakan masukkan Tanggal Lahir yang valid',
  max20Characters: 'Maksimal 20 Karakter Diizinkan',
  min3Characters: 'Diperlukan Minimal 3 Karakter',
  max100Characters: 'Maksimal 100 Karakter Diizinkan',
  max50Characters: 'Maksimum 50 Karakter Diizinkan',
  invalidNumber: 'Nomor Tidak Valid',
  preferredLanguageRequired: 'Diperlukan Bahasa Pilihan',
  invalidPass: 'kata sandi salah',
  prevBtn: 'Sebelumnya',
  newPasswordPlaceholder: 'Masukan kata sandi baru',
  currentPasswordPlaceholder: 'Masukkan Kata Sandi Saat Ini',
  uploadImage: 'Unggah Gambar',
  removeImage: 'Hapus Gambar',
  preferredLanguage: 'Bahasa Pilihan',
  wantToChangePass: 'Apakah Anda ingin Mengubah Kata Sandi?',
  selectCountry: 'Pilih negara',
  selectPreferredLanguage: 'Pilih Bahasa Pilihan',
  fName50Chars: 'Nama Depan minimal harus 50 karakter',
  lName50Chars: 'Nama Belakang harus terdiri dari minimal 50 karakter',
  invalidDate: 'Tanggal tidak berlaku',
  DOBEarlyThanToday: 'Tanggal Lahir Harus Lebih Awal dari Hari Ini',
  enterValidPhone: 'Silakan masukkan nomor telepon yang valid',
  accountStatus: 'status akun',
  kycVerified: 'KYC Anda telah berhasil diverifikasi.',
  denied: 'Ditolak',
  chooseFile: 'Pilih File',
  upload: 'Mengunggah',
  countryCantChange: 'Anda akan memulai Verifikasi KYC. ',
  proceed: 'Melanjutkan',
  update: ' ',
  history: 'Sejarah',
  type: 'Jenis',
  amount: 'Jumlah',
  forfeited: 'Hilang',
  expired: 'Kedaluwarsa',
  completed: 'Lengkap',
  zeroedOut: 'Dipusatkan',
  active: 'Aktif',
  lapsed: 'Bekas',
  yourActiveBonus: 'Bonus Aktif Anda',
  currentActiveBonus: 'Bonus Aktif Saat Ini',
  bonusStatus: 'Status',
  bonusWager: 'Taruhan',
  bonusActivate: 'Mengaktifkan',
  bonusForfeit: 'Kehilangan Bonus',
  notUsableBonus: 'Jika ya, Anda tidak akan dapat menggunakan bonus ini lagi.',
  bonusLapsed: 'Kerugian Anda tidak cukup untuk mendapatkan cashback. ',
  inProcess: 'Dalam Proses',
  claiming: 'Mengklaim',
  loyaltyPopover: 'Poin Loyalitas ini dapat digunakan untuk mendapatkan cashback. ',
  loyaltyPage: 'Halaman Loyalitas',
  loyaltyPerCurrency: 'Loyalitas per mata uang',
  maxLevelReached: 'Level Maks Tercapai',
  pointsToReach: 'poin yang ingin dicapai ',
  loyaltyBannerHeading: 'Anda Layak Mendapatkan Kesetiaan Baru Kami',
  loyaltyBannerDesc: 'Bonus Selamat Datang 100% Hingga $200',
  loyaltyHeadingOne: 'Anda Selalu Dihadiahi di Gammastack',
  loyaltyHeadingOneDesc: 'Di Gammastack, kami membawa kesetiaan ke tingkat kegembiraan yang benar-benar baru, menghujani Anda dengan hadiah di setiap putaran! ',
  loyaltySubHeadOne: 'Mendapatkan Poin Loyalitas',
  loyaltySubHeadOneDesc: 'Untuk setiap €10 taruhan tunai yang dipasang di kasino kami, Anda akan mendapatkan 1 poin loyalitas. ',
  loyaltySubHeadTwo: 'Menaiki Tangga Kesetiaan',
  loyaltySubHeadTwoDesc: 'Semakin banyak poin yang Anda kumpulkan, semakin tinggi pula level loyalitas Anda, dan membuka hadiah yang lebih fantastis lagi. ',
  loyaltySubHeadThree: 'Naik level, dapatkan imbalan ',
  loyaltySubHeadThreeDesc: 'Tingkatkan pengalaman bermain Anda dengan maju melalui tingkatan hadiah kami yang menarik, di mana setiap peningkatan level akan membuka serangkaian putaran gratis yang menggembirakan untuk memaksimalkan peluang Anda untuk menang. ',
  loyaltyTestimonialHeadOne: 'DI Gammastack CASINO KAMI PASTIKAN BAHWA',
  loyaltyTestimonialHeadTwo: 'Setiap Putaran Penting',
  loyaltyTestimonialDesc: 'Setiap putaran yang Anda lakukan di slot tidak hanya untuk bersenang-senang, tetapi juga membuat Anda lebih dekat dengan beberapa hadiah yang sangat manis! ',
  loyaltyPoints: 'Poin',
  headerLevel: 'tingkat',
  loyaltyCashback: 'Uang kembali',
  loyaltyBannerBtn: 'Periksa Kemajuan',
  loyaltyHeadingTwo: 'Buka Pengalaman Gaming Terbaik: Program VIP Gammastack',
  loyaltyHeadingTwoDesc: 'Setelah Anda mencapai Level 6 dalam program loyalitas kami, Anda akan membuka dunia hak istimewa eksklusif sebagai anggota Program VIP Gammastack yang bergengsi. ',
  loyaltySubHeadFour: 'Batas Setoran dan Penarikan Lebih Tinggi',
  loyaltySubHeadFourDesc: 'Nikmati fleksibilitas peningkatan batas deposit dan penarikan sebagai anggota VIP.',
  loyaltySubHeadFive: 'Bonus dan Promosi Eksklusif',
  loyaltySubHeadFiveDesc: 'Dapatkan akses ke bonus dan promosi khusus yang dibuat khusus untuk pemain VIP kami',
  loyaltySubHeadSix: 'Manajer Akun Pribadi',
  loyaltySubHeadSixDesc: 'Dapatkan dukungan khusus dan bantuan yang dipersonalisasi dari manajer akun Anda.',
  loyaltySubHeadSeven: 'Waktu Penarikan Lebih Cepat',
  loyaltySubHeadSevenDesc: 'Sebagai VIP, Anda akan menikmati waktu penarikan yang lebih cepat, memastikan Anda dapat mengakses kemenangan Anda lebih cepat.',
  loyaltySubHeadEight: 'Hadiah Cashback Bulanan',
  loyaltySubHeadEightDesc: 'Tingkatkan uang Anda dengan hadiah uang kembali bulanan yang berlimpah khusus untuk anggota VIP kami.',
  loyaltySubHeadNine: 'Undangan ke Acara dan Turnamen Eksklusif',
  loyaltySubHeadNineDesc: 'Dapatkan undangan berharga ke acara dan turnamen eksklusif, tempat Anda dapat bergaul dengan sesama VIP dan bersaing untuk mendapatkan hadiah luar biasa.',
  loyaltySubHeadTen: 'Bonus Ulang Tahun',
  loyaltySubHeadTenDesc: 'Rayakan hari istimewa Anda dengan penuh gaya dengan bonus ulang tahun menyenangkan hanya untuk Anda!',
  loyaltySubHeadEleven: 'Hadiah Mewah',
  loyaltySubHeadElevenDesc: 'Manjakan diri dengan pilihan hadiah mewah istimewa yang dipilih sendiri untuk memberi penghargaan kepada pemain kami yang paling berharga.',
  loyaltyTableHeading: 'Ikhtisar Level dan Hadiah',
  loyaltyTableHeaderOne: 'Tingkat Loyalitas',
  loyaltyTableHeaderTwo: 'Poin Loyalitas',
  loyaltyTableHeaderThree: 'Uang Kembali Harian',
  promReadMore: 'Baca selengkapnya',
  currentPortalBlock: 'jika Anda ingin memblokir akun Anda hanya untuk portal ini.',
  allPortalBlock: 'untuk memblokir akun Anda dari semua portal.',
  limit24Reset: 'Setelah Anda menetapkan batas Taruhan, Kalah, Deposit maka batas tersebut akan dapat diedit dan dihapus setelah 24 jam tetapi batas tersebut dapat segera dikurangi.',
  enterAmount: 'Masukan jumlah',
  limit: 'Membatasi',
  loss: 'Kehilangan',
  takeABreak: 'Istirahat',
  session: 'Sidang',
  selfExclusion: 'Pengecualian Diri',
  used: 'Digunakan',
  edit: 'Sunting',
  updatedAt: 'Diperbarui Pada',
  cannotBeRemoved: 'Tidak bisa dihapus sebelumnya',
  timePeriod: 'Jangka waktu',
  custom: 'Kebiasaan',
  hours: 'Jam',
  days: 'hari',
  timePeriodPlaceholder: 'Jumlah hari',
  months: 'Bulan',
  permanent: 'Permanen',
  onlyNumbers: 'Hanya Angka yang diperbolehkan',
  notNumbers: 'Nomor Tidak Diizinkan',
  noSpaceAllow: 'Sapce tidak diperbolehkan',
  userNameAllowed: 'Harus Dimulai dengan Alfabet. ',
  timePeriodRequired: 'Diperlukan Jangka Waktu',
  cannotBeIncreased: 'Tidak bisa ditingkatkan sebelumnya',
  amountGTZero: 'Jumlahnya harus lebih besar dari 0',
  amountEqualOrLess: 'Jumlahnya harus sama atau kurang dari mingguan dan bulanan',
  amountInBetween: 'Jumlahnya harus antara harian dan bulanan (inklusif)',
  amountEqualOIrGreater: 'Jumlahnya harus sama atau lebih besar dari harian dan bulanan',
  limitRemoved: 'Batasan berikut Anda akan dihapus.',
  limitSet24Hrs: 'Anda akan menetapkan batas berikut pada akun Anda. ',
  takeABreakInfo: 'Anda akan memblokir akses ke akun Anda untuk jangka waktu yang telah ditentukan. ',
  sessionLimitInfo: 'Anda akan menetapkan batas berikut pada akun Anda. ',
  limitCantSetBefore: 'Batas sesi Anda tidak dapat disetel sebelumnya',
  selfExclusionInfo: 'Anda akan memblokir akses ke akun Anda untuk jangka waktu yang telah ditentukan. ',
  quickSearch: 'Pencarian Cepat',
  cash: 'Uang tunai',
  nonCash: 'Non Tunai',
  userDetail: 'Detil Pengguna',
  gameIdentifier: 'Pengenal Permainan',
  rollback: 'Kembalikan',
  rollbackBeforeBetWin: 'Kembalikan Sebelum Taruhan Menang',
  freeSpins: 'Putaran Gratis',
  betInternal: 'Taruhan Internal',
  winInternal: 'Menangkan Internal',
  addMoney: 'Tambahkan Uang',
  removeMoney: 'Hapus Uang',
  addMoneyInternal: 'Tambahkan Uang Internal',
  removeMoneyInternal: 'Hapus Uang Internal',
  depositInternal: 'Setoran Internal',
  withdrawInternal: 'Penarikan Internal',
  promotionTitle: 'Judul Promosi',
  cancelWithdrawRequest: 'Anda ingin Membatalkan permintaan ini',
  bankname: 'Nama Bank',
  selectBank: 'Pilih Bank Anda',
  accountNumber: 'Nomor akun',
  enterAccountNumber: 'Masukkan nomor akun',
  rowsPerPage: 'Baris Per Halaman',
  availedBonus: 'Anda sudah memiliki bonus aktif. ',
  capForfeit: 'KEHILANGAN',
  itFirst: 'itu dulu.',
  selectYourBonus: 'Pilih Bonus Anda',
  skip: 'MELEWATI',
  with: 'dengan',
  without: 'tanpa',
  selectPaymentMethod: 'Silakan pilih Metode Pembayaran Anda',
  paymentMethod: 'Cara Pembayaran',
  selectPaymentmethod: 'Pilih metode pembayaran',
  mobileNo: 'Nomor handphone',
  enterMobileNo: 'Masukkan nomor ponsel',
  volatility: 'Keriangan',
  paylines: 'Garis pembayaran',
  theme: 'Tema',
  tryForFree: 'Coba gratis',
  resultsFound: 'Hasil Ditemukan',
  games: 'permainan',
  in: 'di dalam',
  low: 'Rendah',
  medium: 'Sedang',
  high: 'Tinggi',
  'medium-high': 'Sedang Tinggi',
  'very-high': 'Sangat tinggi',
  'low-medium': 'Rendah Sedang',
  fantasy: 'Fantasi',
  ancient_civilizations: 'Peradaban kuno',
  fruits: 'Buah-buahan',
  africa: 'Afrika',
  military: 'Militer',
  joker: 'Pelawak',
  asia: 'Asia',
  luxurylife: 'Hidup mewah',
  underwater_world: 'Dunia bawah air',
  book_off: 'Pembukuan',
  western: 'Barat',
  retro: 'mundur',
  egypt: 'Mesir',
  party: 'Berpesta',
  st_patrick_day: 'Hari St Patrick',
  space: 'Ruang angkasa',
  easter: 'Paskah',
  girls: 'Cewek-cewek',
  branded: 'Bermerek',
  x_mas_and_new_year: 'Natal dan Tahun Baru',
  horrors: 'Kengerian',
  other: 'Lainnya',
  loginFirst: 'Silakan Login Terlebih Dahulu Untuk Menambahkan Game Di Favorit',
  loginFirstPlay: 'Silakan Login Terlebih Dahulu Untuk Memainkan Game ini',
  sport: 'Olahraga',
  pirates: 'Bajak laut',
  sweets: 'Permen',
  luxury_life: 'Hidup mewah',
  st_valentines_day: 'Hari St. Valentine',
  halloween: 'Hallowen',
  food: 'Makanan',
  lobby: 'lobi',
  favourite: 'Favorit',
  playNGO: 'Mainkan dan Pergi',
  loyaltySystem: 'Sistem Loyalitas',
  progressionSystem: 'Sistem kemajuan',
  startPoint: 'Titik awal',
  endPoint: 'Titik Akhir',
  loyaltyBonus: 'Bonus Loyalitas',
  pageRemoved: 'Halaman ini tidak ada atau telah dihapus',
  suggestToBackHome: 'Kami menyarankan Anda kembali ke rumah',
  backToHome: 'Kembali ke rumah',
  goHome: 'Pulang ke rumah',
  oopps: 'Ups',
  somethingNotWorking: 'Maaf, ada yang tidak berfungsi di sini!',
  tournament: 'Turnamen',
  none: 'Tidak ada',
  validOnDays: 'Berlaku Pada Hari',
  validTill: 'Berlaku sampai',
  yourRequest: 'Permintaanmu',
  withdrawalRequestPending: 'Anda memiliki satu permintaan penarikan dalam status tertunda. ',
  realBalance: 'Keseimbangan Nyata',
  bonusInCasino: 'Bonus Di Kasino',
  bonusSelected: 'Bonus Dipilih',
  payWith: 'Bayar menggunakan',
  reached: 'Dicapai',
  receiptOfYour: 'Tanda terima Anda',
  capEmail: 'SUREL',
  capCreditCard: 'NOMOR KARTU KREDIT',
  capExpiry: 'KEDALUWARSA',
  capCvv: 'CVV',
  expiryIncorrectFormat: 'EXPIRY tidak dalam format yang benar',
  min13Numbers: 'Diperlukan Minimal 13 Nomor',
  max19Numbers: 'Maksimum 19 Nomor Diizinkan',
  min3Numbers: 'Diperlukan Minimal 3 Nomor',
  max4Numbers: 'Maksimum 4 Nomor Diizinkan',
  Other: 'Lainnya',
  forfeit: 'Kehilangan',
  withdrawRequestCreated: 'Permintaan Penarikan Berhasil Dibuat',
  noBetHistory: 'Tidak Ada Riwayat Taruhan yang Ditemukan',
  noPromotions: 'Tidak Ada Promosi yang Ditemukan',
  noBonuses: 'Tidak Ada Bonus yang Ditemukan',
  noHistory: 'Tidak Ada Riwayat yang Ditemukan',
  noWithdrawalHistory: 'Tidak Ditemukan Riwayat Penarikan',
  noCMS: 'Tidak Ada CMS yang Ditemukan',
  noProvidersFound: 'Tidak Ada Penyedia yang Ditemukan',
  noDataFound: 'Tidak ada data ditemukan',
  countryBlocked: 'NEGARA DIBLOKIR',
  forbidden: 'TERLARANG',
  notAcceptingVisitors: 'Kami sangat menyesal mengenai hal ini, tetapi mengkilapwilds.com tidak tersedia di negara Anda.',
  contactUsAt: 'Jika menurut Anda ini ditampilkan kepada Anda karena suatu kesalahan, harap hubungi dukungan kami melalui email di',
  siteUnderMaintenance: 'Situs dalam perbaikan',
  weWillBeRightBack: 'Kami akan kembali!',
  serviceUnavailable: 'Layanan kami untuk sementara tidak tersedia. ',
  tryAgainLater: 'Silakan coba lagi nanti.',
  checkYourEmail: 'Periksa email Anda',
  sentAVerification: 'Kami telah mengirimkan tautan verifikasi',
  toYourEmail: 'ke email Anda.',
  backToLogin: 'Kembali untuk masuk',
  goToLogin: 'Pergi ke Masuk',
  accountVerified: 'Akun Terverifikasi',
  yourAccountVerified: 'Akun Anda telah diverifikasi',
  successfully: 'Berhasil',
  verificationFailed: 'Verifikasi gagal',
  emailFailedContact: 'Verifikasi Gagal. Jika ini terus terjadi, Silakan hubungi ',
  setNewPass: 'Tetapkan kata sandi baru',
  passResetLink: 'Kami telah mengirimkan tautan pengaturan ulang kata sandi ke',
  toYourAccount: 'Ke Akun Anda',
  sendPasswordResetLink: 'Permintaan untuk Mengatur Ulang Kata Sandi',
  gamePlayWagerComplete: 'Selamat, Anda telah berhasil menyelesaikan taruhan dan uang bonus yang dimenangkan lebih besar dari jumlah kemenangan maksimal yang diperbolehkan. ',
  real: 'Nyata',
  demo: 'Demo',
  money: 'Uang',
  formatRquired: 'Diperlukan format .png, .jpg, .jpeg',
  chooseImage: 'Silakan Pilih gambar',
  imageSize3MB: 'Masukkan Gambar yang Memiliki Ukuran Kurang dari 3 MB',
  noProfileImage: 'Tidak ada Gambar Profil yang dapat dihapus',
  limitSetSuccess: 'batas yang ditetapkan berhasil',
  accountDisabled: 'Akun Berhasil Dinonaktifkan',
  loginSuccess: 'Login berhasil',
  signupSuccess: 'Pendaftaran berhasil',
  linkExpired: 'Tautan Kedaluwarsa',
  linkSent: 'Tautan Terkirim',
  accAlreadyVerified: 'Akun Sudah Terverifikasi. ',
  profileImage: 'Gambar profil',
  profileImageRemoved: 'Gambar Profil Berhasil Dihapus',
  docs: 'Dokumen',
  updatedSuccess: 'Berhasil Diperbarui',
  MATCH: 'COCOK',
  BALANCE: 'KESEIMBANGAN',
  FREESPINS: 'GRATISSPIN',
  MATCH_1: 'COCOK',
  DEPOSIT: 'MENYETORKAN',
  WAGERING: 'TARUHAN',
  PROMOTION: 'PROMOSI',
  CREDITCARD: 'KARTU KREDIT',
  WALLET: 'DOMPET',
  CRYPTO: 'KRIPTO',
  OTHER: 'LAINNYA',
  INSTANTBANKING: 'PERBANKAN INSTAN',
  cookieTitle: 'Kue!',
  declineButton: 'Menolak',
  acceptButton: 'Menerima',
  cookieDeclinedMessage: 'Untuk pengalaman terbaik, cookie harus diterima untuk terus menggunakan situs web ini. ',
  cookieDescription: 'Selamat datang di Shinywilds.com! ',
  forfeitMessageFirst: 'Saat ini Anda memiliki bonus aktif berikut:',
  forfeitMessageSecond: 'Anda harus kehilangan bonus ini sebelum mengklaim yang baru.',
  forfeitButton: 'Kehilangan',
  pleaseVerifyFirstText: 'Untuk dapat meminta penarikan, kami perlu memverifikasi alamat email Anda terlebih dahulu. ',
  clickHere: 'Klik disini',
  pleaseVerifyLastText: 'sehingga kami dapat mengirim ulang email verifikasi kepada Anda dan melanjutkan.',
  pleseWait: 'Harap tunggu',
  toResend: 'untuk mengirim ulang tautan tersebut',
  notVerified: 'Anda harus memverifikasi alamat email Anda untuk menarik uang',
  verification: 'Verifikasi',
  UPLOAD_IMG_MSG: 'Seret dan letakkan file di sini, atau klik untuk memilih file',
  addressIsRequired: 'alamat diperlukan',
  withdrawRequestSent: 'Permintaan penarikan dikirim ke Admin, Harap tunggu persetujuan!',
  withdrawAddressCannotBeSame: 'Alamat penarikan tidak boleh sama dengan alamat Anda',
  pasteYourCurrencyAddressHere: 'Tempelkan milik Anda {{currency}} alamat dompet di sini...',
  noGamesAvailable: 'Tidak ada permainan yang tersedia',
  or: 'atau',
  copyPasteNot: 'salin/tempel tidak diperbolehkan',
  selectDob: 'Pilih tanggal lahir',
  iAgreeWith: 'Saya setuju dengan perjanjian pengguna, dan mengonfirmasi bahwa saya berusia minimal 18 tahun!',
  iAgreeto: 'Saya setuju untuk ',
  alreadyHave: 'Sudah memiliki akun?',
  kyc: 'Kyk',
  changePicture: 'Mengganti gambar',
  selectGender: 'Pilih jenis kelamin',
  male: 'Pria',
  female: 'Perempuan',
  zipCode: 'Kode Pos',
  selectCurrency: 'Pilih Mata Uang Anda',
  bannerTitle: 'Kasino Luar Biasa',
  bannerSubTitle: 'HANYA DENGAN KLIK',
  address: 'Alamat',
  streetAddress: 'Alamat jalan',
  signupAddressState: 'Negara',
  onlyNumberAllowed: '{{fieldName}} harus berisi angka saja',
  profileImageSizeFormat: 'Izinkan ukuran gambar kurang dari {{imageSize}} dan memformat {{imageFormat}}',
  fileSizeFormat:'Izinkan ukuran file kurang dari {{imageSize}} dan format {{imageFormat}}',
  comingNewUpdate: 'Kami sedang mengerjakan sesuatu yang menarik. ',
  ooops: 'Ups...',
  redirectingMessage: 'Mengarahkan kembali {{redirectTime}} detik...',
  logIn: 'Gabung',
  signUp: 'Mendaftar',
  Username: 'Nama belakang',
  day: 'hari',
  month: 'bulan',
  year: 'tahun',
  forgetPassword: 'Lupa Password',
  verifyEmail: 'verifikasi email',
  loggedInUser: 'Pengguna yang Masuk',
  favorites: 'Favorit',
  liveGames: 'Permainan langsung',
  videoSlots: 'Slot Video',
  beforeAmount: 'Sebelum Jumlah',
  startDateCanNotGreterThanEnd: 'tanggal mulai tidak boleh lebih besar dari tanggal akhir',
  Wallet: 'Dompet',
  Casino: 'Kasino',
  affiliate: 'Afiliasi',
  affiliateTitle: 'Program Afiliasi',
  affiliateDescription: ' Referensikan merek kami dan dapatkan uang dengan berpartisipasi dalam Program Afiliasi Deuces! ',
  affiliateButtonText: 'Menjadi Afiliasi',
  refferelLink: 'Tautan Referensi',
  affiliateLinkCopy: 'Tautan Berhasil Disalin',
  startDate: 'Mulai tanggal',
  endDate: 'Tanggal Berakhir',
  referral: 'Rujukan',
  referralCode: 'Kode Referensi',
  requiredReferralCode: 'Kode Referensi Diperlukan untuk mendaftar',
  referralProgram: 'Program Rujukan',
  overview: 'Ringkasan',
  referralDetails: 'Detail Referensi',
  referralIncentive: 'Referensikan merek kami dan dapatkan uang dengan berpartisipasi dalam Program Referensi Deuces! ',
  copyTitleMessage: 'Silakan salin {{title}} untuk melamar',
  referralCopiedMessage: 'Kode Referensi Berhasil Disalin',
  isReferralCode: 'Kode Referensi (Osional)',
  greaterThan18years: 'dan konfirmasikan bahwa saya setidaknya berusia 18 tahun!',
  userId: 'Identitas pengguna',
  totalCommissionsRewards: 'Hadiah Total Komisi',
  registrationDate: 'tanggal registrasi',
  isReferralCheckbox: 'Deu ke Kode Afiliasi, tidak dapat menerapkan Kode Referensi ',
  notAvailable: 'T/A',
  serialNumber: 'hal',
  searchBy: 'Cari berdasarkan {{searchableKeyword}}',
  name: 'Nama',
  noDataAvailable: 'Tidak ada data yang tersedia',
  reloadPage: 'memuat ulang halaman',
  commission: 'Komisi',
  promotions: 'Promosi',
  sponsorships: 'Sponsor',
  refreshYourPage: 'Segarkan Halaman Anda',
  latestPromotions: 'Promosi Terbaru',
  promotionsNotAvailable: 'Saat ini detail promosi tidak tersedia ',
  luckyWinners: 'Pemenang yang Beruntung',
  favoriteGames: 'Permainan Favorit',
  gameProviders: 'Penyedia Permainan',
  affiliateEligibleDescription: 'Anda tidak memenuhi syarat untuk menjadi afiliasi, harap selesaikan KYC hingga Level 2 agar memenuhi syarat untuk Afiliasi',
  moveOnKycPage: 'untuk pindah ke halaman KYC.',
  docDownload: 'Klik Disini untuk Mengunduh Dokumen yang Diunggah',
  documentUploadedSuccess: 'Dokumen Berhasil Diunggah',
  uploadFile: 'Unggah data',
  completeKycLevel: 'Silakan Selesaikan level  {{kycLevel}} Verifikasi KYC dulu',
  leadingCasinoTitle: 'Kasino Kripto Online Terkemuka',
  leadingCasinoDescription: 'Temukan kegembiraan tanpa akhir dengan koleksi permainan kasino Deuces yang luas. ',
  casinoButtonTitle: 'Pergi ke Kasino',
  leadingSportsTitle: 'Taruhan Olahraga Kripto Online Terbaik',
  leadingSportsDescription: 'Rasakan sensasi taruhan olahraga dengan Deuces. ',
  sportsButtonTitle: 'Pergi ke Buku Olahraga',
  welcomeBack: 'Selamat Datang kembali, {{userName}}',
  playSmarter: 'Mainkan Lebih Cerdas',
  loginNow: 'Masuk sekarang',
  yourKYCProgress: 'Milikmu {{value}} Kemajuan',
  copiedMessage: '{{title}} Berhasil Disalin',
  depositAddress: 'Alamat Setoran',
  emailVerified: 'email terverifikasi',
  verify: 'Diverifikasi',
  notVerify: 'Bukan Verifikasi',
  winAmount: 'Jumlah Kemenangan',
  referralAmountMessage: 'Jumlah referral hanya akan ditransfer jika email pengguna terdaftar diverifikasi.',
  vipClub: 'Klub VIP',
  bronze: 'Perunggu',
  silver: 'Perak',
  gold: 'Emas',
  platinum: 'Platinum',
  vipRankingMsg: 'Sistem Peringkat VIP Deuces',
  level1: 'tingkat 1',
  level2: 'level 2',
  level3: 'tingkat 3',
  level4: 'tingkat 4',
  level5: 'tingkat 5',
  casinoLevelMessage: 'Kasino - Jumlah taruhan {{amount}}',
  sportsLevelMessage: 'Olahraga - Jumlah taruhan {{amount}}',
  level5message: 'Pemain dapat mencapai level ini hanya diberikan oleh admin super',
  totalReferredAmount: 'Jumlah Total yang Direferensikan {{amount}}',
  invalidReferralCode: 'Kode Referensi Tidak Valid',
  wantToLogin: 'Apakah Anda yakin ingin masuk?',
  totalWager: 'Jumlah Taruhan',
  totalCommissionRewards: 'Total Hadiah Komisi',
  noGamesFound: 'Tidak Ada Permainan yang Ditemukan',
  bonusReferral: 'Bonus Referensi',
  affiliateCode: 'Kode afiliasi',
  bonus: 'Bonusnya',
  latestBonus: 'Bonus Terbaru',
  bonusNotAvailable: 'Saat ini rincian bonus tidak tersedia',
  lose: 'Kehilangan',
  genderInvalid: 'Tipe gender sebaiknya hanya laki-laki atau perempuan',
  walletSettings: 'Pengaturan Dompet',
  yearShouldBeBetween: 'Tahun yang dipilih harus berada di antara tahun tersebut {{from}} - {{to}}',
  accessingSiteWarning: 'Dengan mengakses situs ini saya menyatakan bahwa saya berusia minimal 18 tahun dan telah membaca dan menyetujui Ketentuan Layanan.',
  rake_Back: '{{percent}} MENGGERAK KEMBALI',
  logoutSuccess: 'logout Berhasil',
  registerSuccess: 'Pendaftaran Berhasil',
  forgetPasswordEmailVerifyMsg: 'Email verifikasi berhasil dikirim ke email Anda yang terdaftar, email pengaturan ulang kata sandi berlaku {{time}} hanya',
  mailSentSuccess: 'Surat Berhasil Terkirim',
  passwordChanged: 'Kata Sandi Berubah',
  passwordResetLogin: 'Kata sandi Anda berhasil diubah, Apakah Anda ingin login',
  passwordChangedSuccess: 'Kata sandi Anda berhasil diubah',
  profileUpdate: 'Profil berhasil diperbarui',
  invalidCredentials: 'Kredensial tidak valid',
  emailAlreadyExists: 'Email sudah ada',
  usernameAlreadyExists: 'Nama pengguna sudah ada',
  emailNotExists: 'Email Tidak Ada',
  resetPassInvalidToken: 'Tautan setel ulang kata sandi Anda telah kedaluwarsa, harap verifikasi email lagi',
  IncorrectCurrentPassword: 'Password saat ini salah',
  formatIsRequired: '{{formatTypes}} format diperlukan',
  anErrorOccurs: 'Terjadi kesalahan, silakan coba lagi',
  imageUploaded: 'Gambar berhasil diunggah',
  insufficientBalance: 'Saldo tidak mencukupi',
  withdrawApprovalReqMsg: 'Permintaan penarikan berhasil dikirim, uang akan ditransfer suatu saat nanti',
  hideZeroBalancesTitle: 'Sembunyikan saldo nol',
  hideZeroBalancesSubTitle: 'Saldo nol Anda tidak akan muncul di dompet Anda',
  displayCryptoInFiatTitle: 'Tampilkan kripto dalam fiat',
  displayCryptoInFiatSubTitle: 'Semua taruhan',
  documentsAlreadyUploaded: 'Dokumen sudah diunggah',
  documentsUploadedSuccessfully: 'Dokumen berhasil diunggah',
  gameAddedtoFavourite: 'Game berhasil ditambahkan ke favorit',
  gameRemovedFromFavourite: 'Game berhasil dihapus dari favorit',
  favoriteGameExists: 'Game Favorit sudah ada',
  favoriteGameNotFound: 'Game Favorit tidak ditemukan',
  internalServerError: 'Kesalahan server internal, silakan hubungi admin',
  realPlay: 'Permainan Nyata',
  funPlay: 'Permainan Menyenangkan',
  gameNotActive: 'Permainan tidak aktif',
  captcha: 'Situs ini dilindungi oleh reCAPTCHA dan Kebijakan Privasi Google serta Persyaratan Layanan berlaku.',
  signUpAndGet : 'Daftar dan dapatkan',
  rakeDisc: '50% RAKE BACK',
  bannerFooter : 'dengan mengakses situs ini saya menyatakan bahwa saya berusia minimal 18tahun dan telah membaca serta menyetujui Persyaratan Layanan.',
  DeucesOriginal : 'Deuces Original',
  Promotion: 'Promosi',
  orLoginWith: 'atau masuk dengan',
  RegisterAnAccount: 'Daftar akun',
  MakeADepositAndPlay: 'Lakukan Deposit Dan Mainkan',
  ReceiveRakeBack: 'TERIMA RAKEBACK 50%',
  PreferNotToSay: 'Memilih untuk tidak menyebutkan',

  // Privacy
  privacy: 'Pribadi',
  ghostMode: 'Mode hantu',
  ghostModeOn: 'Mode Hantu Aktif',
  ghostModeOff: 'Mode Hantu Mati',
  enableGhostMode: 'Aktifkan Mode Hantu',
  yourBetsWontAppear: 'Taruhan Anda tidak akan muncul di feed taruhan publik dan pratinjau taruhan'
}
