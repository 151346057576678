import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setDepositCurrency, setDepositData, setPaymentCodeData, setShowWalletPopup, setWalletCurrency } from 'redux-thunk/redux/slices/wallet.slice'
import { Modal, Typography, Box, Tab, IconButton } from '@mui/material/index'
import { TabContext, TabList, TabPanel } from '@mui/lab/index'
import { WalletModalStyle } from './style'
import { Deposit } from './Deposit/Deposit'
import { Withdraw } from './Withdraw/Withdraw'
import CloseIcon from '@mui/icons-material/Close'
import { getUserWalletCurrencyLimit } from 'redux-thunk/thunk/user.thunk'
import { Visibility, VisibilityOff } from '@mui/icons-material/index';


const Wallet = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [TabPanelValue, setTabPanelValue] = React.useState('1')
  const userData = useSelector((state) => state?.user?.user?.getUser)
  const [balanceValue, setBalanceValue] = useState(0);



  const walletHandleClose = () => {
    dispatch(setShowWalletPopup(false))
    dispatch(setDepositCurrency('default'))
    dispatch(setWalletCurrency('default'))
    dispatch(setDepositData(null))
    dispatch(setPaymentCodeData())
  }
  const { showWalletPopup, walletCurrencyLimit } = useSelector((state) => state.wallet)
  const handleChange = (event, newValue) => {
    setTabPanelValue(newValue)
  }

  const depositCurrencylimit = walletCurrencyLimit?.filter(currencyLimit => currencyLimit.key === 'DEPOSIT_LIMIT')
  const withdrawCurrencylimit = walletCurrencyLimit?.filter(currencyLimit => currencyLimit.key === 'WITHDRAW_LIMIT')


  const [isVisibleBalance, setIsVisibleBalance] = useState(true);
  const toggleVisibility = () => {
    setIsVisibleBalance(!isVisibleBalance);
    console.log(balanceValue);
  };



  // useEffect(() => {
  //   if (!walletCurrencyLimit) {
  //     dispatch(getUserWalletCurrencyLimit())
  //     setValueBalance()
  //   }
  // }, [dispatch])

  useEffect(() => {
    if (!walletCurrencyLimit) {
      const fetchAndCalculateBalance = async () => {
        try {
          await dispatch(getUserWalletCurrencyLimit());
          if (Array.isArray(userData.userWallets)) {
            const totalAmount = userData.userWallets.reduce((sum, item) => sum + item.amount, 0);
            setBalanceValue(totalAmount);
          } else {
            console.error('Data is not an array:', userData.userWallets);
            setBalanceValue(0);
          }
        } catch (error) {
          console.error('Error in fetching and calculating balance:', error);
        }
      };

      // Call the async function inside useEffect
      fetchAndCalculateBalance();
    }

  }, [dispatch]); //

  return (
    <Modal
      open={showWalletPopup}
      onClose={walletHandleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <WalletModalStyle>
        <Box style={{ position: 'absolute', top: '10px', right: '10px', padding: '10px' }}>
          <IconButton onClick={walletHandleClose}>
            <CloseIcon sx={{ color: '#433F4C' }} />
          </IconButton>
        </Box>
        <Box className='login-modal'>
          <Box>
            <Typography id='modal-modal-title' variant='h6' component='h2' className='modal-heading'>
              {t('Wallet')}
            </Typography>
            <Typography variant="body1" component="body1"
              style={{ color: 'grey', marginBottom: '0 '}}
            >
              Estimated Balance
            </Typography>
            <IconButton
              sx={{ color: 'white' }}
              color="primary"
              onClick={toggleVisibility}
            >
              {isVisibleBalance ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </Box>
          <Box>
            <Typography
              variant="h5" component="h5"
              style={{ color: 'white' }}
            >
              {isVisibleBalance ? balanceValue : "*****"}
            </Typography>
          </Box>
          <Box>
          <div className="currency-user">
            <div className="box-flex-currency">
              <div className="heading-currency">
                <p style={{color: 'white'}}>Your currencies</p>
              </div>
              <div className="heading-value">
                <p style={{color: 'white'}}>Value</p>
              </div>
            </div>
            <div className="currency">
                {userData.userWallets.filter(wallet => wallet.amount > 0).map(wallet => (
                  <div key={wallet.walletId}
                    style={{ color: 'white', display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div className="currencyName">
                      {wallet.currency.name}
                    </div>
                    <div className="amountCurrency">
                      {isVisibleBalance ? wallet.amount : "*****"}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          </Box>
          <Box className='login-tab'>
            <TabContext value={TabPanelValue}>
                <Box>
                  <TabList aria-label='lab API tabs example' className='login-inner-tab' onChange={handleChange}>
                    <Tab label='Deposit' value='1' className='item-one' />
                    <Tab label='Withdraw' value='2' className='item-two' />
                  </TabList>
                </Box>
              <TabPanel value='1' className='tab-panel'>
                <Deposit currencyLimit={depositCurrencylimit?.[0] || {}} />
              </TabPanel>
              <TabPanel value='2' className='tab-panel'>
                <Withdraw currencyLimit={withdrawCurrencylimit?.[0] || {}} />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </WalletModalStyle >
    </Modal >
  )
}

export default Wallet
