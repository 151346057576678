import { createSlice } from '@reduxjs/toolkit'
import {
  getAllGames,
  getCasinoGamesList,
  getGameData,
  getSearchedGames

} from 'redux-thunk/thunk/game.thunk'

const initialState = {
  providers: null,
  subCategoryId: null,
  selectedSubCategory: null,
  casinoSubCategoriesGames: null,
  favoriteGames: null,
  initGameFailure: null,
  initGameData: null,
  casinoGameCategories: null,
  gameDataLoad: false,
  favoriteGamesLoader: false
}

const {
  actions: {
    setFilteredGames,
    setGameCategoryId,
    updateAllCasinoGamesFav,
    updateCasinoGamesFav,
    setCasinoSubCategories,
    setAllCasinoGameList,
    removeFavoriteCasinoGamesFav,
    getGameDataSucces,
    getGameDataFailure,
    setAllCasinoGameCategories,
    getFavouriteGameSuccess,
    clearGameUrl,
    setFavoriteGamesLoader
  },
  reducer
} = createSlice({
  name: 'games',
  initialState: initialState,
  reducers: {
    setFilteredGames: (state, action) => ({
      ...state,
      filteredGames: action.payload
    }),
    setFavoriteGamesLoader: (state, action) => ({
      ...state,
      favoriteGamesLoader: action.payload
    }),
    setGameCategoryId: (state, action) => ({
      ...state,
      subCategoryId: action?.payload?.id,
      selectedSubCategory: action?.payload?.subCategory
    }),

    removeFavoriteCasinoGamesFav: (state, { payload }) => {
      const { id } = payload
      const newfilteredGames = {
        rows: state.favoriteGames.rows?.filter(
          (item) => item?.CasinoGames?.id !== id
        ),
        count: state.favoriteGames.count
      }
      return { ...state, favoriteGames: { ...newfilteredGames } }
    },

    updateAllCasinoGamesFav: (state, { payload }) => {
      const { casinoGameId, isFavorite, gameSubCategoryId } = payload
      const newfilteredGames = {
        rows: state.casinoSubCategoriesGames.rows?.map((item) =>
          item.gameSubCategoryId === gameSubCategoryId
            ? {
                ...item,
                CasinoGames: item.CasinoGames.map((cData) =>
                  cData.casinoGameId === casinoGameId
                    ? { ...cData, isFavorite: !isFavorite }
                    : cData
                )
              }
            : item
        ),
        count: state.casinoSubCategoriesGames.count
      }

      return {
        ...state,
        casinoSubCategoriesGames: { ...newfilteredGames },
        favoriteGames: {}
      }
    },

    updateCasinoGamesFav: (state, { payload }) => {
      const { casinoGameId, isFavorite } = payload
      const newfilteredGames = {
        categoryGames: {
          rows: state.filteredGames.categoryGames.rows?.map((item) =>
            item.casinoGameId === casinoGameId
              ? { ...item, isFavorite: !isFavorite }
              : item
          ),
          count: state.filteredGames.categoryGames.count
        }
      }
      return { ...state, filteredGames: { ...newfilteredGames } }
    },

    getFavouriteGame: (state, action) => ({
      ...state
      // favoriteGames: action.payload,
    }),
    getFavouriteGameSuccess: (state, action) => ({
      ...state,
      favoriteGames: action.payload,
      favoriteGamesLoader: false
    }),
    setCasinoSubCategories: (state, action) => ({
      ...state,
      casinoSubCategoriesGames: action.payload
      // favoriteGames: action.payload?.favoriteGames
    }),
    setAllCasinoGameList: (state, action) => ({
      ...state,
      casinoGameList: action?.payload
    }),
    setAllCasinoGameCategories: (state, action) => ({
      ...state,
      casinoGameCategories: action?.payload
    }),
    getGameDataSucces: (state, action) => ({
      ...state,
      initGameData: action?.payload,
      gameDataLoad: false
    }),
    getGameDataFailure: (state, action) => ({
      ...state,
      initGameFailure: action?.payload
    }),
    clearGameUrl: (state, action) => ({
      ...state,
      initGameData: null,
      gameDataLoad: false
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCasinoGamesList.fulfilled, (state, action) => {
        return {
          ...state,
          categoryWiseGamesList: action?.payload
        }
      })
      .addCase(getAllGames.fulfilled, (state, action) => {
        return {
          ...state,
          viewAllGames: action?.payload
        }
      })
      .addCase(getSearchedGames.fulfilled, (state, action) => {
        if (action?.payload?.popupGames) {
          return {
            ...state,
            searchPopupGames: action?.payload
          }
        } else {
          return {
            ...state,
            filteredGames: action?.payload
          }
        }
      })
      .addCase(getGameData.pending, (state, action) => {
        return {
          ...state,
          gameDataLoad: true
        }
      })
      .addCase(getGameData.rejected, (state, action) => {
        return {
          ...state,
          gameDataLoad: false
        }
      })
  }
})

export default reducer
export {
  updateCasinoGamesFav,
  updateAllCasinoGamesFav,
  setFilteredGames,
  setGameCategoryId,
  setCasinoSubCategories,
  setAllCasinoGameList,
  removeFavoriteCasinoGamesFav,
  getGameDataSucces,
  getGameDataFailure,
  setAllCasinoGameCategories,
  getFavouriteGameSuccess,
  clearGameUrl,
  setFavoriteGamesLoader
}
