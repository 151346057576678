import React from 'react'
import { TopSportsBarContainer } from './style'

const TopSportsBar = () => {
	return (
		<TopSportsBarContainer>
			Top Bar
		</TopSportsBarContainer>
	)
}

export default TopSportsBar