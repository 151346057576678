/* eslint-disable no-unused-vars */
import React from 'react'

import { MaintenanceModeContainer } from './MaintenanceMode.style'

const MaintenanceMode = () => {
  return (
    <MaintenanceModeContainer>
      <div className='mainen-mode-wrap '>
        <div className='logo-wrap profile-card-wrap'>
          <img className='logo' src='/assets/images/logo/brand-logo2.webp' alt='Company Logo' />
        </div>
        <h6>Site Under Maintenance</h6>
        <p>We are currently performing some scheduled maintenance. Please check back soon.</p>
        <p>For any urgent matters, please contact us:</p>
        <p className='contact'>Email: support@gammastack.com<br />
        </p>
        <div className='footer'>
          &copy; 2023 Gammastack. All Rights Reserved
        </div>
      </div>

    </MaintenanceModeContainer>
  )
}

export default MaintenanceMode
