export const fiatCurrency = [
  {
    name: 'INR',
    code: 'INR'
  },
  {
    name: 'USD',
    code: 'USD'
  },
  {
    name: 'JPY',
    code: 'JPY'
  },
  {
    name: 'EUR',
    code: 'EUR'
  },
  {
    name: 'IDR',
    code: 'IDR'
  },
  {
    name: 'TRY',
    code: 'TRY'
  }

]
