import { openSuccessToaster } from 'helpers/toaster.helpers'
import { getTranslation } from 'helpers/translations.helpers'
import { stopLoader } from 'redux-thunk/redux/slices/loader.slice'
import { removeAlertMessage } from 'redux-thunk/redux/slices/alert.slice'
import { store } from 'App'

export const responseSuccessHandler = (response) => {
  const { responseType = 'json', successMessage, loader } = response.config || {}
  if (responseType === 'blob') {
    return { file: response.data, headers: response.headers }
  }
  // // Loader logic to remove loader
  if (loader) {
    store.dispatch(stopLoader(loader))
  }
  if (successMessage) {
    store.dispatch(removeAlertMessage())
  }
  // Open Success Toaster
  successMessage && openSuccessToaster({ message: getTranslation(successMessage) })
  return response.data.data
}
