import { findPlainNumber, formatNumberWithCommas } from 'utils/numberFormatter'
import * as Yup from 'yup'

export const walletDepositeSchema = (t, conditions) => Yup.object().shape({
  paymentMethod: Yup.string().notOneOf(['default'], t('Please select a valid bank')),
  // mobileNo: Yup.string().matches(/^[0-9]+$/, t('Mobile number must contain only numbers'))
  //   .min(4, t('Mobile number must be at least 4 characters'))
  //   .max(12, t('Mobile number can have at most 12 characters')),
  // .required(t('Mobile number is required')),
  amount: Yup.string()
    .required(t('Amount is required'))
    .test({
      name: 'check-minimum-amount-value',
      message: t('minMaxDepositAmount', {
        min: formatNumberWithCommas(conditions?.MIN_MAX_WITHDRAW_AMOUNT?.min),
        max: formatNumberWithCommas(conditions?.MIN_MAX_WITHDRAW_AMOUNT?.max)
      }),
      test: function (value) {
        const currentValue = findPlainNumber(value)
        return currentValue >= conditions?.MIN_MAX_WITHDRAW_AMOUNT?.min && currentValue <= conditions?.MIN_MAX_WITHDRAW_AMOUNT?.max
      }
    })
})
export const walletWithdrawSchema = (t, conditions) => Yup.object().shape({
  bankName: Yup.string().notOneOf(['default'], t('Please select a valid bank')),
  // mobileNo: Yup.string().matches(/^[0-9]+$/, t('Mobile number must contain only numbers'))
  //   .min(4, t('Mobile number must be at least 4 characters'))
  //   .max(12, t('Mobile number can have at most 12 characters')),
  // .required(t('Mobile number is required')),
  accountNo: Yup.string().matches(/^[0-9]+$/, t('Account number must contain only numbers'))
    .min(4, t('Account number must be at least 4 characters'))
    .max(50, t('Account number can have at most 50 characters'))
    .required(t('Account number is required')),
  amount: Yup.string()
    .required(t('Amount is required'))
    .test({
      name: 'check-minimum-amount-value',
      message: t('minMaxDepositAmount', {
        min: formatNumberWithCommas(conditions?.MIN_MAX_WITHDRAW_AMOUNT?.min),
        max: formatNumberWithCommas(conditions?.MIN_MAX_WITHDRAW_AMOUNT?.max)
      }),
      test: function (value) {
        const currentValue = findPlainNumber(value)
        return currentValue >= conditions?.MIN_MAX_WITHDRAW_AMOUNT?.min && currentValue <= conditions?.MIN_MAX_WITHDRAW_AMOUNT?.max
      }
    })
})

export const walletWithdrawCryptoSchema = (t, conditions) => Yup.object().shape({
  amount: Yup.number().typeError(t('Amount must be a number'))
    .required(t('Amount is required'))
    .positive(t('Amount must be positive'))
    .min(
      conditions?.MIN_MAX_WITHDRAW_AMOUNT,
      t('minEqualWithdrawAmount', { min: conditions?.MIN_MAX_WITHDRAW_AMOUNT })
    ),
  address: Yup
    .string()
    .required(t('addressIsRequired'))
    .test('not-equal', t('withdrawAddressCannotBeSame'), (value) => {
      return value !== conditions?.address
    })
})
