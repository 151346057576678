import { LANGUAGE, ROLLOVER, STRATEGY, USER_DISCLAIMER, WALLETSETTING } from 'constants/index'

export const setItem = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const getItem = (key) => {
  const value = localStorage.getItem(key)
  return value ? JSON.parse(value) : null
}

export const setLocalLanguage = (lang) => {
  setItem(LANGUAGE, lang)
}

export const getLocalLanguage = () => {
  return getItem(LANGUAGE)
}

export const getLocalWalletSetting = () => {
  return getItem(WALLETSETTING)
}

export const setLocalWalletSetting = (wallet) => {
  setItem(WALLETSETTING, wallet)
}

export const setRollOver = (value) => {
  setItem(ROLLOVER, value)
}

export const getRollOver = () => {
  return getItem(ROLLOVER)
}

export const setStrategies = (value) => {
  setItem(STRATEGY, value)
}

export const getStrategies = () => {
  return getItem(STRATEGY)
}

export const setUserDisclaimer = (value) => {
  setItem(USER_DISCLAIMER, value)
}

export const getUserDisclaimer = () => {
  return getItem(USER_DISCLAIMER)
}
