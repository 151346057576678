
import { Box, styled } from '@mui/material'

export const IndexStyled = styled(Box)(({ theme }) => {
  return {
    '& .sidebar-open': {
      background: '#0b0116',
      backgroundColor: '#0b0116',

      '& .dynamic-width': {
        width: 'calc(100% - 15.5rem)',

        [theme.breakpoints.down('lg')]: {
          width: 'calc(100% - 4.75rem)'
        },

        [theme.breakpoints.down(1024)]: {
          width: '100%'
        }

      },

      '& .nav-link': {

        '& .bonus-item': {
          display: 'none',
          background: '#2A2633',
          margin: '0 13px',
          padding: '10px',
          borderRadius: '10px',
          transition: 'all 0.3s',

          '& .bonus-main': {
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '30px',
            '& .MuiListItemIcon-root': {
              width: 'auto',
              justifyContent: 'start',
              minWidth: 'auto',
              paddingRight: '1.5rem'

            }
          }
        },

        '& .all-bonus': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',

          '& .MuiTypography-root': {
            fontWeight: '400 !important',
            fontSize: theme.spacing(1.75)
          }
        }
      }
    },

    '& .sidebar-close': {
      background: '#0b0116',
      backgroundColor: '#0b0116',

      '& .nav-link': {

        '& .bonus-item': {
          transition: 'all 0.3s',

          '& .bonus-main': {
            '& .MuiListItemText-root': {
              display: 'none'
            }
          }
        }

      },

      '& .bonus-list': {
        display: 'none'
      },

      '& .common-btn': {
        display: 'none'

      },

      '& .nav-link-hidden': {
        justifyContent: 'center',
        '& .nav-link': {
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          paddingTop: '0 !important',
          '& .MuiListItemText-root': {
            display: 'none'
          },
          '& .MuiListItemIcon-root': {
            backgroundColor: theme.palette.primary.contrastTextDark,
            padding: theme.spacing(1),
            borderRadius: theme.spacing(1),
            width: 'auto !important',
            minWidth: 'auto !important',
            '& svg': {
              width: '15px',
              height: '15px',
              '& path': {
                fill: `${theme.palette.primary.contrastText} !important`
              }
            }
          }
        },

        '&:hover': {
          '& .MuiListItemIcon-root': {
            backgroundColor: theme.palette.button.hoverBtn
          }
        },

        '& .active': {

          alignItems: 'center',
          gap: '8px',
          '&:hover': {
            '& .MuiListItemIcon-root': {
              backgroundColor: theme.palette.button.hoverBtn
            }
          },
          '& .MuiListItemIcon-root': {
            backgroundColor: theme.palette.button.activeBtn,
            padding: theme.spacing(1),
            borderRadius: theme.spacing(1),
            '& svg': {
              width: '15px',
              height: '15px',
              '& path': {
                fill: `${theme.palette.primary.contrastText} !important`
              }
            }
          }
        }

      }
    },
    '& .screen-layout': {
      display: 'flex'
    },
    '.filter-group': {
      marginBottom: theme.spacing(2.75),
      alignItems: 'end',
      '& .input-label': {
        color: theme.palette.primary.contrastText,
        fontSize: theme.spacing(1.75),
        fontWeight: '500',
        paddingBottom: theme.spacing(1)
      },
      '& .react-select': {
        background: theme.palette.primary.light,
        borderRadius: theme.spacing(1.25),
        color: theme.palette.primary.contrastTextDark,

        '& .MuiSvgIcon-root': {
          color: theme.palette.primary.contrastTextDark
        }
      },
      '& .filter-btn': {
        button: {
          fontSize: theme.spacing(1.75),
          padding: theme.spacing(2, 3)
        },
        '& .filter-rest-btn': {
          background: theme.palette.button.light
        }
      }
    },

    '.css-1q5f8o5-MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
      background: theme.palette.primary.dark
    },
    '& .react-datepicker__year-dropdown': {
      background: '#000',

      '& .react-datepicker__year-option': {
        '&:hover': {
          background: '#57535E'
        }
      }
    },
    '& .react-datepicker': {
      background: theme.palette.primary.dark,
      border: 'none',

      '&  .react-datepicker__triangle::after,  .react-datepicker__triangle::before': {
        display: 'none'
      },
      '& .react-datepicker__day': {
        color: theme.palette.primary.contrastText,
        '&:hover': {
          background: theme.palette.button.activeBtn
        }
      },
      '& .react-datepicker__header ': {
        background: theme.palette.primary.dark,
        border: 'none',
        color: theme.palette.primary.contrastText
      },
      '& .react-datepicker__current-month, .react-datepicker__day-name': {
        color: theme.palette.primary.contrastText
      },
      '& .react-datepicker__day--selected': {
        background: theme.palette.button.activeBtn
      }
    },
    '& .custom-datepicker': {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',

      '.react-datepicker-wrapper': {
        width: '100%',
        '& .react-datepicker__input-container': {
          width: '100%',

          [theme.breakpoints.down('xm')]: {
            width: '100%'
          },

          input: {
            background: theme.palette.primary.inputBg,
            height: theme.spacing(7),
            borderRadius: theme.spacing(1.25),
            border: 'none',
            padding: theme.spacing(0, 2),
            width: '100%',
            cursor: 'pointer',
            color: theme.palette.primary.contrastText,
            '&:focus-visible': {
              outline: 'none'
            }
          }
        }
      },

      '& svg': {
        position: 'absolute',
        right: '1rem',
        pointerEvents: 'none'
      }
    },
    '& .my-datepicker': {

      '& .MuiInputBase-root': {
        background: theme.palette.primary.inputBg
      },
      input: {
        background: theme.palette.primary.inputBg,
        height: theme.spacing(7),
        borderRadius: theme.spacing(1.25),
        border: 'none',
        padding: theme.spacing(0, 2),
        width: '100%',
        cursor: 'pointer',
        color: theme.palette.primary.contrastText,
        fontSize: theme.spacing(1.5),
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.spacing(2)
        },
        '&:focus-visible': {
          outline: 'none'
        }
      },
      '& svg': {
        color: theme.palette.primary.contrastText
      }
    },
    '& .pagination-container': {
      display: 'flex',
      justifyContent: 'flex-end'
    },

    '& .pagination-btn': {
      border: 'none',
      borderRadius: '5px',
      margin: '0 5px',
      color: '#fff',
      background: theme.palette.primary.light,
      cursor: 'pointer',
      '& svg': {
        filter: 'invert(100%) sepia(60%) saturate(0%) hue-rotate(164deg) brightness(106%) contrast(100%)',
        width: '10px',
        height: '10px'
      },
      '&.active': {
        backgroundColor: '#3F86FC !important'
      }
    },
    '& .empty-loader-wrap': {
      minHeight: 'calc(100vh - 440px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '& .flex-itemCenter-justifySpaceBetween': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  }
})
