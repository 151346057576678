import React, { useEffect, useState, useRef, useCallback, memo } from 'react'
import { UserCoinWrapper } from '../../style'
import { useTheme } from '@emotion/react'
import { Box, Button, MenuItem, Typography, Stack, CircularProgress, ClickAwayListener, Backdrop } from '@mui/material'
import { ChecronDown, WalletIcon, WalletSettingsIcon } from 'assets/icons/index'
import { setFilteredCurrencyData, setShowWalletPopup, setUpdateCurrencyCodeDataLoader } from 'redux-thunk/redux/slices/wallet.slice'
import { CustomMainButton } from 'components/layout/Common/StyledButton/CustomButton'
import { useDispatch, useSelector } from 'react-redux'
import ButtonGroup from '@mui/material/ButtonGroup'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuList from '@mui/material/MenuList'
import { getAuthToken } from 'helpers/cookie.helpers'
import { currencyCodeUpdateRequest } from 'redux-thunk/thunk/user.thunk'
import { currencyIcon } from '../../../../../utils/currencyIcon'
import { useTranslation } from 'react-i18next'
import WalletSettings from './WalletSettings'
import { useLocation } from 'react-router-dom'
import MenuItemComponent from './component/MenuItemComponent'
import SearchComponent from './component/SearchComponent'
import SymbolComponents from './component/SymbolComponents'
import { useStyles } from './style'
import { BellIcon } from 'assets/icons/index'


const UserCoin = memo(() => {
  const { t } = useTranslation()
  const location = useLocation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const userData = useSelector((state) => state?.user?.user?.getUser)
  const { filteredCurrencData, updateCurrencyCodeDataLoader } = useSelector((state) => state?.wallet)
  const token = !!getAuthToken()

  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const [selectedIndex, setSelectedIndex] = useState(1)
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState({})
  const [openWalletSettingsPopup, setOpenWalletSettingsPopup] = useState(false)
  const [isWalletButtonClicked, setIsWalletButttonClicked] = useState(true)
  const classes = useStyles()
  const [openBackdrop, setOpenBackdrop] = useState(false)

  const [changeCurrencySetting, setChangeCurrencySetting] = useState({
    hideZeroBalance: false,
    displayCryptoInFiat: false,
    currentCurrencyCode: '',
    faitCurrencyCode: 'USD'
  })
  const isCurrencyDisabled = location.pathname?.includes('/casino/play-game')

  const handleToggle = () => {
    setOpen(!open)
    dispatch(setFilteredCurrencyData(renderCurrencyArr()))
  }
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const walletHandleOpen = () => {
    setIsWalletButttonClicked(false)
    dispatch(setShowWalletPopup(true))
  }

  const handleMenuItemClick = (...args) => {
    const [currency, index] = args
    dispatch(setUpdateCurrencyCodeDataLoader(true))
    setIsWalletButttonClicked(true)
    setSelectedIndex(index)
    setOpen(false)
    const reqBody = {
      hideZero: currency?.hideZeroBalance,
      displayCryptoInFlat: currency?.displayCryptoInFiat,
      currencyCode: currency?.currentCurrencyCode || userData?.currencyCode || '',
      faitCurrencyCode: currency?.faitCurrencyCode || userData?.other?.faitCurrencyCode || 'USD'
    }
    dispatch(currencyCodeUpdateRequest({ values: reqBody }))
  }
  const arrForRenderList = (arr) => {
    const mappedArrData = arr?.map((currencydata) => {
      const result = {
        ...currencydata?.currency,
        amount: currencydata.amount,
        nonCashAmount: currencydata.nonCashAmount
      }
      return result
    })
    return mappedArrData
  }

  const changeDropDownValue = (dropDownData) => {
    const filterCurrencyCodeData = dropDownData?.filter((currencyCodeDetails, index) => currencyCodeDetails?.currency?.code === userData?.currencyCode)
    setSelectedCurrencyCode(...arrForRenderList(filterCurrencyCodeData))
    dropDownData?.map((currencyCode, ind) => {
      if (currencyCode?.code === userData?.currencyCode) { setSelectedIndex(ind) }
      return []
    })
    dispatch(setUpdateCurrencyCodeDataLoader(false))
  }

  const renderCurrencyArr = useCallback(() => {
    changeDropDownValue(userData?.userWallets || [])
    setChangeCurrencySetting({
      ...changeCurrencySetting,
      hideZeroBalance: userData?.other?.hideZero,
      displayCryptoInFiat: userData?.other?.displayCryptoInFlat,
      currencyCode: userData?.currencyCode,
      faitCurrencyCode: userData?.other?.faitCurrencyCode
    })
    if (userData?.other?.hideZero) {
      const filterZeroAmountCurrency = userData?.userWallets && userData?.userWallets?.filter((currencyCodeDetails, index) => currencyCodeDetails?.amount !== 0)
      return arrForRenderList(filterZeroAmountCurrency)
    }
    return arrForRenderList(userData?.userWallets)
  }, [userData])

  useEffect(() => {
    dispatch(setFilteredCurrencyData(renderCurrencyArr()))
  }, [renderCurrencyArr])
  useEffect(() => {
    document.body.style.overflow = !open ? 'auto' : 'hidden'
    setOpenBackdrop(open)
  }, [open])

  return token &&
    <>
      <UserCoinWrapper theme={theme} className='header-user-coin'>
        <div className='show-web'>
          {updateCurrencyCodeDataLoader && isWalletButtonClicked
            ? (
              <Box className='loderBox'>
                <Stack sx={{ color: 'grey.500', mr: 1 }} spacing={2} direction='row'>
                  <CircularProgress color='success' />
                </Stack>
              </Box>
            )
            : (
              <Button disabled={isCurrencyDisabled} className={`dropdown-btn wallet-btn ${isCurrencyDisabled ? 'currency-disabled' : ''}`} onClick={handleToggle}>
                <Box ref={anchorRef}>
                  <Box className='btc-coin-box'>
                    <ButtonGroup variant='contained' aria-label='split button'>
                      {currencyIcon[selectedCurrencyCode?.code]} &nbsp;
                      <Button>
                        <Typography className='currency-text'>  {selectedCurrencyCode?.code}</Typography>
                      </Button>
                      <Button
                        size='small'
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? true : undefined}
                        aria-label='select merge strategy'
                        aria-haspopup='menu'
                      >
                        <ChecronDown />
                      </Button>
                    </ButtonGroup>
                    <ClickAwayListener onClickAway={handleClose}>
                      <Popper
                        className='theme-dropdown'
                        sx={{
                          zIndex: 9999
                        }}
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom'
                            }}
                          >
                            <Paper sx={{ mt: 2.2, width: 240 }}>
                              <SearchComponent />
                              <MenuList>
                                {filteredCurrencData?.map((currency, index) => {
                                  return (
                                    <MenuItem
                                      className='coin-list-item'
                                      key={currency?.currencyId}
                                      selected={index === selectedIndex}
                                      disabled={currency?.code === 'USD'}
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        handleMenuItemClick({ currentCurrencyCode: currency?.code }, index)
                                      }}
                                    >
                                      <MenuItemComponent option={currency} />
                                    </MenuItem>
                                  )
                                })}
                                <Button onClick={() => setOpenWalletSettingsPopup(true)} className='wallet-btn'>
                                  <Typography><WalletSettingsIcon /> &nbsp; {t('walletSettings')}</Typography>
                                </Button>
                              </MenuList>

                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </ClickAwayListener>
                  </Box>
                  {
                    userData && selectedCurrencyCode && ( // new addition
                      <Box>
                        <Typography className='btc-coins'>
                          <SymbolComponents option={selectedCurrencyCode} />
                          &nbsp;
                          {
                            isCurrencyDisabled
                              ? 'In Play'
                              : userData?.other?.displayCryptoInFlat
                                ? selectedCurrencyCode?.code !== 'IDR'
                                  ? (Number(selectedCurrencyCode?.amount) / Number(userData?.convertedRate?.[selectedCurrencyCode?.code])).toFixed(2) || 0
                                  : Number(selectedCurrencyCode?.amount) || 0
                                : Number(selectedCurrencyCode?.amount) || 0
                          }
                        </Typography>
                      </Box>
                    )
                  }
                </Box>
              </Button>
            )}
          <Backdrop
            className={classes.backdrop}
            open={openBackdrop}
          />
          <CustomMainButton
            type='button'
            onClick={walletHandleOpen}
            className={`btn-head-wallet ${isCurrencyDisabled ? 'currency-disabled' : ''}`}
            disabled={isCurrencyDisabled}
            aria-label="WalletIcon"
          >
            <WalletIcon />
          </CustomMainButton>
        </div>



      </UserCoinWrapper>
      <WalletSettings
        openWalletSettingsPopup={openWalletSettingsPopup}
        setOpenWalletSettingsPopup={setOpenWalletSettingsPopup}
        changeCurrencySetting={changeCurrencySetting}
        setChangeCurrencySetting={setChangeCurrencySetting}
        handleSwitchItemClick={handleMenuItemClick}
      />
    </>
})

export default UserCoin
