import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, withRouter } from 'react-router-dom'
import {
  setChat,
  setSelectedWallet
} from 'redux-thunk/redux/slices/user.slice'
import {
  setShowLoginPopup,
  setShowSignupPopup
} from 'redux-thunk/redux/slices/gameSetting.slice'
import { setLoginData } from 'redux-thunk/redux/slices/auth.slice'
import { getAllBonus } from 'redux-thunk/thunk/bonus.thunk'
import { fetchUserInformation } from 'redux-thunk/thunk/user.thunk'
import {
  getAllCurrencyStart
} from 'redux-thunk/thunk/gameSetting'
import Wallet from 'pages/Wallet'
import Login from 'pages/Login'

import { getAuthToken } from 'helpers/cookie.helpers'
import {
  CHAT,
  ROUTE_PATHS
} from 'constants/index'
import { getItem } from 'helpers/localstorage.helpers'
import Signup from 'pages/Signup/index'
import { getLanguages } from 'redux-thunk/thunk/language.thunk'
import { getSiteData, getSiteSettingData } from 'redux-thunk/thunk/general.thunk'
import { getCasinoProviderList, getGeneralData } from 'redux-thunk/thunk/casino.thunk'
import { useTheme, Box } from '@mui/material'
import { StyledHeader } from './style'
import UserNav from './components/UserNav/UserNav'
import UserCoin from './components/UserCoin/UserCoin'
import LogoIcon from './components/LogoIcon/index'


const Header = (props) => {
  const theme = useTheme()
  const token = !!getAuthToken()

  const { search } = useLocation()
  const emailVarified = new URLSearchParams(search).get('emailVerified')
  const { showLoginPopup, showSignupPopup } =
    useSelector((state) => state.gameSetting)

  const { showWalletPopup } = useSelector((state) => state.wallet)

  const dispatch = useDispatch()
  const { user, selectedWallet } =
    useSelector((state) => state.user)

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        )
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        )
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none')
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none')
        }
      }
    })
  }, [])

  useEffect(() => {
    dispatch(getCasinoProviderList())
    if (token) {
      dispatch(fetchUserInformation())
      dispatch(setLoginData({ accessToken: getAuthToken() }))
    }
  }, [])

  useEffect(() => {
    if (window.location.pathname === ROUTE_PATHS.SIGNUP) {
      dispatch(setShowSignupPopup(true))
    } else if (window.location.pathname === ROUTE_PATHS.LOGIN && !emailVarified) {
      dispatch(setShowLoginPopup(true))
    }
  }, [])

  useEffect(() => {
    if (user) {
      if (!selectedWallet) {
        if (user?.userSetting?.currencyId) {
          dispatch(setSelectedWallet(user?.userSetting?.currencyId))
        } else {
          const currentWallet =
            user?.wallets?.find((ele) => ele.primary)
          dispatch(setSelectedWallet(currentWallet))
        }
      }
    }
  }, [user])

  useEffect(() => {
    dispatch(getAllCurrencyStart())
    dispatch(getAllBonus())
    const chattingData = getItem(CHAT)
    if (chattingData?.length > 0) {
      dispatch(setChat({ chatData: chattingData }))
    }
    dispatch(getLanguages())
    dispatch(getSiteData())
    dispatch(getGeneralData())
    dispatch(getSiteSettingData())
  }, [])

  return (
    <StyledHeader theme={theme}>
      <Box className='nav-wrap brand-logo-wrap'>
        <LogoIcon />
        <UserCoin />
        <UserNav />
      </Box>

      {showLoginPopup && <Login />}
      {showWalletPopup && <Wallet />}
      {showSignupPopup && <Signup />}

    </StyledHeader>
  )
}

export default React.memo(withRouter(Header))
