import { Box, styled } from '@mui/material'

export const SidebarWrapper = styled(Box)(({ theme }) => {
  return {
    width: theme.spacing(9.5),
    backgroundColor: theme.palette.primary.dark,
    borderRight: '1px solid #30538E',
    position: 'fixed',
    zIndex: '2',
    paddingTop: theme.spacing(8.375),
    paddingBottom: '4rem',
    top: theme.spacing(0),
    left: theme.spacing(0),
    height: '100vh',
    overflow: 'auto',

    '& .search-wrap': {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 2, 0, 2),

      [theme.breakpoints.down('md')]: {
        width: '100%',
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2)
      },
      '& .input-holder': {
        width: '100%',
        marginLeft: 'auto',
        position: 'relative',
        '& .MuiFormControl-root': {
          width: '100%',
          '& .MuiInputBase-root': {
            borderRadius: theme.spacing(1.25),
            '& .MuiInputBase-input': {
              color: theme.palette.primary.contrastText,
              fontSize: theme.spacing(1.75),
              fontWeight: theme.typography.fontWeightRegular,
              padding: theme.spacing(1.75, 1.75, 1.75, 6),
              [theme.breakpoints.down('xl')]: {
                fontSize: '16px'
              }
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.contrastTextDark
            }
          }
        },
        '& .icon-box': {
          width: theme.spacing(6),
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          '& svg': {
            fill: theme.palette.primary.contrastTextLight
          }
        }
      }
    },
    [theme.breakpoints.down('xm')]: {
      flexWrap: 'wrap',
      '& .navbar-wrap': {
        order: '3'
      },
      '& .search-wrap': {
        order: '2'
      }
    },
    [theme.breakpoints.down('md')]: {
      '& .search-wrap': {
        width: '100%',
        padding: theme.spacing(0, 2, 0, 2),
        marginTop: theme.spacing(3),
        minWidth: 'auto',
        '& .MuiFormControl-root': {
          width: theme.spacing('100%')
        }
      }
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.scrollbar.transparent,
      borderRadius: theme.spacing(0)
    },
    '&::-webkit-scrollbar': {
      width: theme.spacing(0),
      height: theme.spacing(0)
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.scrollbar.thumb,
      borderRadius: theme.spacing(0)
    },
    '& .sidebar-paper': {
      position: 'relative',
    },
    '& .MuiPaper-root': {
      backgroundColor: '#0b0116',
      borderRadius: theme.spacing(0),
      boxShadow: theme.shadows[0],
      '& .MuiList-root': {
        '& .MuiMenuItem-root': {
          padding: theme.spacing(0),
          '& .nav-link': {
            width: theme.spacing('100%'),
            textDecoration: 'none',
            display: 'flex',
            padding: theme.spacing(1.2, 0),

            '& .MuiListItemIcon-root': {
              backgroundColor: 'transparent',
              width: theme.spacing(7),
              minWidth: theme.spacing(7),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '& svg': {
                width: theme.spacing(2),
                height: theme.spacing(2),
              },
              '& img': {
                width: theme.spacing(2),
                height: theme.spacing(2),
              }
            },
            '& .MuiListItemText-root': {
              color: theme.palette.primary.contrastTextDark,
              fontSize: theme.spacing(1),
              transition: '0.3s all',
              '& .MuiTypography-root': {
                fontWeight: '500',
                whiteSpace: 'normal',
                fontSize: theme.spacing(1.75),
              }
            },
            '&:hover': {
              '& img': {
                filter: 'invert(12%) sepia(89%) saturate(6079%) hue-rotate(281deg) brightness(107%) contrast(118%)',
              },
              '& .MuiListItemIcon-root svg': {
                transition: '0.3s all',
                '& path': {
                  fill: theme.palette.button.activeBtn,
                  transition: '0.3s all'
                }
              },
              '& .MuiListItemText-root': {
                transition: '0.3s all'
              },
              '& .MuiTypography-body1': {
                color: theme.palette.button.activeBtn
              }

            },
            '&.active': {
              '& .MuiListItemIcon-root': {
                '& img': {
                  filter: 'invert(100%) sepia(100%) saturate(2%) hue-rotate(202deg) brightness(107%) contrast(100%)',
                }
              },
              '& .MuiListItemIcon-root svg': {
                '& path': {
                  fill: '#fff',
                  transition: '0.3s all'
                }
              },
              '& .MuiTypography-body1': {
                color: 'white',
              },
              '& .MuiListItemText-root': {
                transition: '0.3s all'
              }
            },
          }
        },
      }
    },
    '& .MuiDivider-root': {
      borderColor: theme.palette.secondary.light,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    '&.sidebar-open': {
      width: theme.spacing(31),
      [theme.breakpoints.down('customPoint_lg')]: {
       width: '100%'
      },

      '& .nav-link-hidden': {
        display: 'none'
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&.sidebar-expand': {
        width: theme.spacing(25),
        left: theme.spacing(0),
      },
      '&.sidebar-shrink': {
        width: theme.spacing(25),
        left: theme.spacing('-100%'),
      }
    },

    '& .common-btn': {
      display: 'flex',
      gap: theme.spacing(1),
      justifyContent: 'center',
      paddingTop: `${theme.spacing(1.5)} !important`,
      paddingBottom: `${theme.spacing(1.5)} !important`,
      marginBottom: theme.spacing(2),
      transition: 'all 0.3s ease',
      '& :hover': {
        background: 'linear-gradient(90deg, #3F86FC 4%, #0D2F6E 100%)',
        border: '0 !important'
      },

      '& .MuiBox-root': {
        position: 'relative',
        overflow: 'hidden',
        display:'flex',
        padding: theme.spacing(1, 2),
        borderRadius: theme.spacing(0.5),
        borderRadius: '5px',
        border: '1px solid #30538E',

        '&.active': {
          background: 'linear-gradient(90deg, #3F86FC 4%, #0D2F6E 100%)',
          border: 'none',
        },
        '& :hover': {
          background: 'none',
          border: '0 !important',
        },

        '& .dice-left': {
          minWidth: 'auto',
          '& svg': {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '35px',
            height: '35px',
            zIndex: '1'
          },
        },

        '& .dice-right': {
          minWidth: 'auto',
          '& svg': {
            position: 'absolute',
            right: '0',
            bottom: '0',
            width: '35px',
            height: '35px',
            zIndex: '1'
          },
        },

        '& .MuiTypography-root': {
          textTransform: 'uppercase',
          fontSize: theme.spacing(2),
          color: '#efefef',
          fontWeight: theme.typography.fontWeightSemiBold
        }
      }
    },

    '&.sidebar-section': {
      '&.sidebar-close': {
        '& .link-hidden': {
          display: 'none'
        },
        '& .MuiTypography-root': {
          display: 'none'
        },
        '& .MuiMenuItem-root': {
          justifyContent: 'center'
        },
        '& .MuiListItemText-root, & .MuiIconButton-root': {
          display: 'none'
        },
        '& .inner-menu-list': {
          '& li': {
            '& a': {
              alignItems: 'center',
              justifyContent: 'center'

            }

          }

        }
      },
      '& .MuiDivider-root':{
        '&.sidebar-divider': {
          borderColor:'#57535E'
        },
      },
      '& .inner-to-inner-list': {
        '& .MuiListItem-gutters': {
          width: '100%',
          padding: '0',
          marginLeft: theme.spacing(4.75)
        },
        '& .text-capitalize': {
          textTransform:'capitalize'
        },
      },
      '& .inner-menu-list, & .inner-to-inner-list': {
        height: 'auto',
        padding: '0',
        width: '100%',
        '& .MuiListItem-gutters': {
          width: '100%',
          padding: '0',
          '& .MuiLink-root': {
            width: '100%',
            alignItems: 'center'
          }
        }

      },
      '& .inner-menu-list': {
        '& .MuiSvgIcon-root, & path': {
          height: theme.spacing(3),
          width: theme.spacing(3)
        },
        '& .sidebar-dropdown':{
          width:'100%',
          alignItems: 'center',
          justifyContent: 'center'
        },
      }

    }
  }
})
