import { HamburgerIcon, MainLogo } from 'assets/icons/index'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTheme, Box, Button } from '@mui/material'
import { LogoIconWrapper } from '../../style'
import { useDispatch } from 'react-redux'
import { setChangeHamburgerIconClass } from 'redux-thunk/redux/slices/user.slice'
import { setTab } from 'redux-thunk/redux/slices/lobby.slice';

export default function LogoIcon () {
  const theme = useTheme
  const [changeClass, setChangeClass] = useState(false)
  const dispatch = useDispatch()

  const hamburgerIcon = () => {
    const payload = changeClass ? 'sidebar-open' : 'sidebar-close'
    dispatch(setChangeHamburgerIconClass(payload))
  }

  useEffect(() => {
    hamburgerIcon()
  }, [changeClass])

  const handleLogoClick = () => {
    dispatch(setTab('none'));
  };


  return (
    <>
      <LogoIconWrapper theme={theme} className='header-logo'>
        <Box className='logoicon-box'>
          <Button aria-label="Icon" className='hamburger-icon hamburger-btn' onClick={() => setChangeClass(!changeClass)}>
            <HamburgerIcon />
          </Button>
          <Link to='/' aria-label='Home' underline='none' onClick={handleLogoClick}>
            <MainLogo className='brand-logo' />
          </Link>
        </Box>
      </LogoIconWrapper>
    </>
  )
}
