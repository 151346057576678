import { Box, styled, InputBase } from '@mui/material'
// import { upperCase } from 'lodash'

export const StyledHeader = styled(Box)(({ theme }) => {
  return {
    backgroundColor: '#0b0116',
    borderBottom: '1px solid #30538E',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    zIndex: '3',
    position: 'sticky',
    top: theme.spacing(0),
    padding: theme.spacing(1.5, 2),

    '.show-mobile': {
      alignSelf : 'center',
      borderRight: '2px solid blue',
      height: '100%',
      paddingRight: '10px',
      alignContent: 'center',
      display: 'none',
      // [theme.breakpoints.down(1024)]: {
      //   display: 'block',
      // },
    },

    '.show-web': {
      // display: 'none',
      [theme.breakpoints.up(1024)]: {
        display: 'flex',
      },
    },


    '& .brand-logo-wrap': {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1.5),
      width: '100%',
      '& .brand-logo': {
        width: theme.spacing(9.6),
        maxWidth: '100%',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xl')]: {
          width: theme.spacing(8.6)
        }
      }
    },
    '& .nav-wrap': {
      display: 'flex',
      justifyContent: 'space-between',
      width: theme.spacing('100%'),
      // marginLeft: theme.spacing(2),
      '& .header-logo': {
        [theme.breakpoints.up('md')]: {
          flex: '1'
        }
      },
      '& .header-user-coin': {
        backgroundColor: 'transparent',
        borderRadius: '10px',
        gap: '10px',

        [theme.breakpoints.down('md')]: {
          flex: '1'
        },
        [theme.breakpoints.down('sm')]: {
          flex: '0'
        }
      },
      '& .theme-dropdown': {
        '& .MuiPaper-root': {
          backgroundColor: theme.palette.primary.modalPopoverBg,
          boxShadow: 'none',
          // minWidth: '400px',
          marginTop: theme.spacing(5.1),
          marginLeft: '0px',
          '& .MuiButtonBase-root': {
            padding: theme.spacing(1, 1.5),
            '& .MuiTypography-root': {
              color: theme.palette.primary.contrastText,
              fontWeight: theme.typography.fontWeightRegular,
              fontSize: theme.spacing(1.8),
              marginRight: theme.spacing(1)
            }
          }

        },
        '& .wallet-btn': {
          width: '100%',
          borderWidth: '1px 0 0 0',
          borderStyle: 'solid',
          borderColor: theme.palette.primary.contrastTextDark,
          borderRadius: 0,
          marginTop: theme.spacing(1),
          padding: `${theme.spacing(1.75, 1.25, 0, 1.25)} !important`,
          '& .MuiTypography-body1 ': {
            color: `${theme.palette.primary.contrastTextDark} !important`,
            fontWeight: theme.typography.fontWeightRegular
          },
          '&:hover': {
            background: 'none'
          }
        },

        '& .coin-list-item': {
          width: '100%',

          '.currencies-list': {
            display: 'flex',
            width: '100%',
            '& .currencies-list-content': {
              flexDirection: 'column',
              '& .coin-sub-value': {
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                '& .MuiTypography-body1': {
                  fontSize: theme.spacing(1.5),
                  color: '#9E9E9E',
                  margin: theme.spacing(0, 0.875, 0, 0),
                  display: 'flex',
                  alignItems: 'center',
                  color: 'yellow'
                },
                '& .MuiSvgIcon-root': {
                  fontSize: theme.spacing(1.6),
                  marginRight: theme.spacing(0.875),
                  fill: 'yellow'
                }
              }
            },
            '& .coin-value-box': {
              display: 'flex',
              width: '140px',
              alignItems: 'center',

              '& .MuiTypography-root': {
                marginRight: theme.spacing(0.25)
              },
              '& .coin-value': {

                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                textAlign: 'start'

              }
            },
            // '& .coin-sub-value': {
            //   width: '140px',
            //   alignItems: 'center'
            // },

            '& .coin-type': {
              marginRight: '0',
              '& svg': {
                display: 'inline-block !important'
              }

            }
          }

        }

      },
      '& .header-user-nav': {

        '.mx-2' :{
          marginLeft: '2px',
          marginRight: '2px',
        },
        '& .user-nav-menu': {
          display: 'flex',
          alignItems: 'center',
          '& .MuiButtonBase-root': {
            [theme.breakpoints.down('sm')]: {
              minWidth: '45px'
            }
          }
        },
        [theme.breakpoints.up('md')]: {
          flex: '1',
          justifyContent: 'end'
        }
      },
      '& .MuiButtonBase-root': {
        marginLeft: 0,
        padding: theme.spacing(0, 2),
        borderRadius: '10px',
        '&:hover': {
          backgroundColor: 'none'
        },
        '& .MuiTypography-root': {
          fontWeight: theme.typography.fontWeightRegular
          // fontSize: theme.spacing(1.5)
        },
        '&:first-child': {
          marginLeft: theme.spacing(0)
        },
        // '& svg': {
        //   transform: 'scale(1.25)'
        // },
        // '& .MuiTypography-root': {
        //   [theme.breakpoints.down('sm')]: {
        //     display: 'none'
        //   }
        // },
        '&.signup-btn, &.login-btn': {
          '& svg': {
            marginRight: theme.spacing(1),
            [theme.breakpoints.down('sm')]: {
              marginRight: theme.spacing(0)
            }
          }
        },
        '&.signup-btn, &.login-btn, &.mobile-menu-btn': {
          [theme.breakpoints.down('sm')]: {
            minWidth: theme.spacing(5.375),
            padding: theme.spacing(1),
            fontSize: theme.spacing(1.625)
          }
        },
        '&.mobile-menu-btn': {
          [theme.breakpoints.up('sm')]: {
            display: 'none'
          }
        }
      }
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1.5, 3)
    },
    [theme.breakpoints.down('md')]: {
      '& .brand-logo-wrap': {
        '& .brand-logo': {
          width: '8.3rem'
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 2),
      '& .brand-logo-wrap': {
        '& .brand-logo': {
          width: theme.spacing(10)
        }
      },
      '& .nav-wrap': {
        '& .MuiButtonBase-root': {
          marginLeft: 0,
          padding: 0,

          '& .MuiTypography-body1 ': {
            fontSize: theme.spacing(1.725),
            color: theme.palette.primary.contrastText
          }
        }
      }
    }
  }
})

export const UserNavWrapper = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    '& .profile-btn': {
      height: theme.spacing(5.375),
      backgroundColor: 'transparent',
      borderWidth: theme.spacing(0.175),
      borderStyle: 'solid',
      borderColor: 'transparent',
      borderRadius: theme.spacing(1.25),
      color: theme.palette.primary.contrastText,
      fontSize: theme.spacing(2),
      fontWeight: '400',
      textTransform: 'capitalize',
      padding: theme.spacing(1.75, 3.75),
      marginTop: theme.spacing(0),
      '&.chat-btn': {
        padding: theme.spacing(0, 0),
        [theme.breakpoints.down(1024)]: {
          display: 'none !important'
        }
      },

      '& .user-name-text': {
        display: 'flex',
        gap: theme.spacing(1),

        [theme.breakpoints.down('sm')]: {
          display: 'none !important'
        }
      },
      '& .user-img': {
        width: theme.spacing(5),
        height: theme.spacing(5),
        borderRadius: '50%',

        [theme.breakpoints.up('sm')]: {
          marginRight: theme.spacing(1.5)
        }
      },
      '& .MuiTypography-root': {
        fontWeight: theme.typography.fontWeightMedium,
        display: 'flex',
        alignItems: 'center'
      },
      // '& svg': {
      //   fill: theme.palette.primary.contrastText,
      //   transform: 'scale(1.5)',
      //   marginLeft: theme.spacing(1.5),
      // },
      [theme.breakpoints.down('sm')]: {
        minWidth: theme.spacing('auto'),
        padding: theme.spacing(0.1875),
        '& .user-img': {
          marginRight: theme.spacing(1)
        }
        // '& .MuiTypography-root': {
        //   fontSize: theme.spacing('0.75rem'),
        //   display: 'none'
        // }
      },

      '&.login-btn': {
        background: '#152D54',
        borderRadius: '20px',
        fontSize: theme.spacing(1.625),
        padding: '0 40px',
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.spacing(1.625),
          padding: '0 10px',
          background: 'transparent',
        }
      },

      '&.register-btn': {
        background: '#3F86FC',
        borderRadius: '20px',
        fontSize: theme.spacing(1.625),
        whiteSpace: 'nowrap',
        padding: '0 30px',
        '& :hover': {
          backgroundColor: 'none',
        },

        [theme.breakpoints.down('sm')]: {
          fontSize: theme.spacing(1.625),
          padding: '0 10px',
          borderRadius: '15px',
        }
      }
    },

    '& .profile-btn-section': {
      display: 'flex',
      color: '#FFF',
      alignItems: 'center',
      padding: theme.spacing(0, 0),
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      '& p': {
        [theme.breakpoints.down('sm')]: {
          display: 'none'
        }
      },
      '& .user-img': {
        width: theme.spacing(5),
        height: theme.spacing(5),
        borderRadius: '50%',

        [theme.breakpoints.up('sm')]: {
          marginRight: theme.spacing(1.5)
        }
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: theme.spacing('auto'),
        padding: theme.spacing(0.1875),
        '& .user-img': {
          marginRight: theme.spacing(1)
        }
      }
    },

    '& .header-search-box': {
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    '& .user-icon-box': {
      display: 'flex',
      gap: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),

      '& .header-icon': {
        width: theme.spacing(5.75),
        height: theme.spacing(5.75),
        padding: theme.spacing(2, 1),
        backgroundColor: theme.palette.primary.dark,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        marginLeft: theme.spacing(0),

        '&.flag-icon': {
          display: 'none'
        }
      },

      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    '& .line': {
      width: '35px',
      height: '3px',
      backgroundColor: '#3570D2',
      transform: 'rotate(90deg)'
    }
  }
})

export const UserCoinWrapper = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    '& .wallet-btn': {
      // height: theme.spacing(5.375),
      borderRadius: theme.spacing(1.25, 2.75, 2.75, 1.25),
      color: theme.palette.primary.contrastText,
      textTransform: 'capitalize',
      position: 'relative',
      // '& .MuiTypography-body1': {
      //   fontWeight: theme.typography.fontWeightBold
      // },

      '& .btc-coin-box': {
        display: 'flex',
        alignItems: 'center',
        // gap: theme.spacing(1),
        '& .MuiTypography-body1': {
          '&.currency-text': {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.spacing(1.5)
          }
        },
        '& .MuiButtonGroup-contained': {
          diplay: 'flex',
          alignItems: 'center',
          boxShadow: 'none',
          '& .MuiButtonBase-root': {
            marginLeft: theme.spacing(0.5),
            padding: theme.spacing(0),
            background: 'transparent',
            lineHeight: '1',
            textTransform: 'uppercase',
            color: theme.palette.primary.contrastTextLight,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.spacing(1.75),

            '&:last-child': {
              marginLeft: theme.spacing(0),
              padding: 0,
              minWidth: 'auto'
            }
          }
        },

        '& .MuiPopper-root': {
          transform: 'translate(-45px, 25px) !important',
          '& .MuiPaper-root': {
            boxShadow: 'none',

            '& .MuiList-root': {
              color: theme.palette.primary.contrastTextLight,
              '& .MuiMenuItem-root': {
                marginLeft: theme.spacing(0)
              }
            }
          }
        },

        // '& .btc-text': {
        //   textTransform: 'uppercase',
        //   color: theme.palette.primary.contrastTextLight,
        //   fontWeight: theme.typography.fontWeightBold,
        //   fontSize: theme.spacing(1.75)
        // },

        '& .btc-coins': {
          fontSize: theme.spacing(2),
          fontWeight: theme.typography.fontWeightRegular
        },

        '& .theme-dropdown': {
          '& .MuiPaper-root': {
            padding: theme.spacing(1),
            borderRadius: theme.spacing(1),
            position: 'relative',

            '&::before': {
              content: "''",
              width: '0',
              height: '0',
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderBottom: '10px solid #2A2633',
              // borderColor: theme.palette.primary.modalPopoverBg,
              position: 'absolute',
              top: '-9px'
            }
          }
        },
        '& .search-wrap': {
          minWidth: '100%', // theme.spacing(38.375),
          background: 'transparent',
          display: 'flex',
          alignItems: 'center',
          marginBottom: theme.spacing(1),
          padding: theme.spacing(0),
          '& .input-holder': {
            width: '100%',
            marginLeft: 'auto',
            position: 'relative',
            '& .MuiFormControl-root': {
              width: '100%',
              '& .MuiInputBase-root': {
                borderRadius: theme.spacing(1.25),
                '& .MuiInputBase-input': {
                  color: theme.palette.primary.contrastText,
                  fontSize: theme.spacing(1.75),
                  fontWeight: theme.typography.fontWeightRegular,
                  padding: theme.spacing(1.25, 1.25, 1.25, 6)
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.primary.contrastTextDark
                }
              }
            },
            '& .icon-box': {
              width: theme.spacing(6),
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              '& svg': {
                fill: theme.palette.primary.contrastTextLight
              }
            }
          }
        }

      },
      '& .btn-icon': {
        marginRight: theme.spacing(1)
      },
      '& .MuiTypography-root': {
        fontSize: theme.spacing('1rem'),
        display: 'flex',
        alignItems: 'center'
      },
      // '& svg': {
      //   fill: theme.palette.primary.contrastTextLight,
      // },
      '& .wallet-icon-box': {
        width: theme.spacing(5.375),
        height: theme.spacing(5.375),
        background: theme.palette.button.mainGradient,
        // borderWidth: theme.spacing(0.175),
        // borderStyle: 'solid',
        // borderColor: theme.palette.border.green,
        boxShadow: theme.shadows[2],
        borderRadius: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        PointerEvent: 'none',
        position: 'absolute',
        right: theme.spacing(0)
      },
      [theme.breakpoints.down('sm')]: {
        borderRadius: theme.spacing(1.25),
        padding: theme.spacing(1.5),
        '& .MuiTypography-root': {
          fontSize: theme.spacing('0.75rem'),
          display: 'inline-block !important',
          '& svg': {
            display: 'none'
          }
        },
        '& .wallet-icon-box': {
          display: 'none'
        }
      }
    },
    '.btn-head-wallet': {
      margin: theme.spacing(0),
      padding: '0.75rem',
      minWidth: '2.875rem',
      height: theme.spacing(6),
      boxShadow: theme.shadows[2],
      border: '1px solid #30538E',
      background: 'radial-gradient(124.42% 124.42% at 50% -30.23%, #05216C 0%, #04172D 100%)',
      [theme.breakpoints.down('sm')]: {
       display: 'none'
      },
      '& svg': {
        fill: 'transparent'
      }
    },
    '& .currency-disabled': {
      color: theme.palette.primary.contrastText,
      opacity: 0.5,
      '& p': {
        color: theme.palette.primary.contrastText,
        opacity: 0.5
      }
    }
  }
})

export const LogoIconWrapper = styled(Box)(({ theme }) => {
  return {
    '& .logoicon-box': {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1.5),
      '& .hamburger-icon': {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1.5),

        [theme.breakpoints.down(1024)]: {
          display: 'none'
        }
      },
      '& .hamburger-btn': {
        padding: '0px',
        minWidth: '0px',
        marginRight: '0px'
      },
    }

  }
})

export const Search = styled('div')(({ theme }) => {
  return {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: alpha(theme.palette.common.white, 0.15),
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '6rem',
    '&:hover': {
      // backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
      marginRight: theme.spacing(1.5)
    }
  }
})

export const SearchIconWrapper = styled('div')(({ theme }) => {
  return {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

export const StyledInputBase = styled(InputBase)(({ theme }) => {
  return {
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 0, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      [theme.breakpoints.up('xs')]: {
        width: '0',
        color: theme.palette.primary.contrastText,
        '&:focus': {
          // width: '15rem',
          width: '0'
        }
      }
    }
  }
})
export const LanguageWrapper = styled(Box)(({ theme }) => {
  return {
    '& .lang-img': {
      width: '35px',
    },
  }
})
