import { createAsyncThunk } from '@reduxjs/toolkit'
import { openSuccessToaster } from 'helpers/toaster.helpers'
import { getAllBanksService, getLiminalApproxFeeService, getPaymentCodeService, getPaymentMethodsService, getWithdrawAmountService } from 'network/services/gameSetting.service'
import { setShowWalletPopup } from 'redux-thunk/redux/slices/wallet.slice'

export const getAllBanks = createAsyncThunk(
  'wallet/getAllBanks',
  async (_, thunkApi) => {
    try {
      const res = await getAllBanksService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
export const getLiminalApproxFee = createAsyncThunk(
  'wallet/getLiminalApproxFees',
  async (payload, thunkApi) => {
    try {
      const res = await getLiminalApproxFeeService(payload)
      return res
    } catch (error) {
      payload?.onWithDrawError(error)
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
export const getPaymentMethods = createAsyncThunk(
  'wallet/getPaymentMethods',
  async (_, thunkApi) => {
    try {
      const res = await getPaymentMethodsService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
export const getPaymentCode = createAsyncThunk(
  'wallet/getPaymentCode',
  async (payload, thunkApi) => {
    try {
      const res = await getPaymentCodeService(payload)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
export const getWithdrawAmount = createAsyncThunk(
  'wallet/getWithdrawAmount',
  async (payload, thunkApi) => {
    try {
      const res = await getWithdrawAmountService(payload)
      thunkApi.dispatch(setShowWalletPopup(false))
      openSuccessToaster({ message: res?.message })
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
