import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Footer from '../Footer/index'
import MaintenanceMode from '../Maintenance/index'
import { Styledlayout } from './style'
import FooterMenu from '../FooterMenu/FooterMenu'
import { isMobile } from 'react-device-detect'
import Toaster from 'components/Toaster/Toaster'
import { useHistory } from 'react-router-dom'

const MainLayout = ({ showFooter, children }) => {
  const { siteData } = useSelector((state) => state.general)
  const { toaster } = useSelector((state) => state.settings)

  const history = useHistory()
  const isGamePlay = history.location.pathname.includes("/play-game/")

  return (
    <>
      <Styledlayout component='main' className='dynamic-width'>
        <div className="buble2"></div>
        <div className="buble3"></div>
        <div className="buble4"></div>
        <div className="buble5"></div>

        {(!siteData?.tenantDetail?.maintenance) ? children : <MaintenanceMode />}

        {showFooter && (!siteData?.tenantDetail?.maintenance) ? <Footer /> : <></>}

        {isMobile && !(isGamePlay) &&  <FooterMenu />}
        {toaster?.isVisible && <Toaster message={toaster?.message} />}
      </Styledlayout>
    </>
  )
}
export default MainLayout
