import { Box, styled } from '@mui/material'

export const ChatBoxWrapper = styled(Box)(({ theme }) => {
  return {
    '.chat-sidebar': {
      position: "fixed",
      right: "0",
      background: '#0B0116',
      zIndex: 999,
      width: "370px",
      height: "100vh",
      padding: theme.spacing(1, 2, 2, 2),
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: "100vh",
      },
      [theme.breakpoints.down('customPoint_lg')]: {
        height: "100vh",
      },
      // display: "flex",
      // alignItems: "center",
      // justifyContent: "center",

      '& .chat-close-icon': {
        textAlign: 'end',

        '& button': {
          justifyContent: 'end',
          paddingRight: '0',
          minWidth: 'auto',
        }
      },

      '& .time-section': {
        display: 'flex',
        justifyContent: 'space-between',
        margin: theme.spacing(1, 0, 1, 0),

        '& .MuiTypography-root': {
          color: theme.palette.primary.contrastTextDark,
          fontSize: theme.spacing(1.25),
        }

      },

      '& .chat-box': {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        '& .chat-item': {
          fontSize: theme.spacing(1.5),
          lineHeight: '1.2',
          display: 'inline-block',
          color: theme.palette.primary.contrastText,
          background: theme.palette.primary.contrastTextDark,
          padding: theme.spacing(1),
          borderRadius: theme.spacing(1),

          '& .chat-by-user':{
            paddingRight: theme.spacing(0.5),
            color:'#b4aaaa',
          },

          '& .chat-tag-user': {
            background: "#221125",
            padding: "2px 6px",
            lineHeight: "normal",
            borderRadius: "4px",
            display: 'inline-block',
          }
        }
      }


    }
  }
})
