import React, { useEffect, useState, useCallback } from "react";
import { useLocation, NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { setShowSideNav } from "redux-thunk/redux/slices/settings.slice";
import { setTab } from "redux-thunk/redux/slices/lobby.slice";
import { setGameCategoryId } from "redux-thunk/redux/slices/games.slice";
import { getAuthToken } from "helpers/cookie.helpers";
import { TextField, Select } from '@mui/material'
import { SearchNewIcon } from 'assets/icons/index'
import { BsFillDice2Fill } from "react-icons/bs";


import { SidebarWrapper } from "./style";
import {
  useTheme,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Box,
  Grid,
  List,
  ListItem,
  Collapse,
  IconButton,
  Tooltip,
  Fade,
} from "@mui/material";
import {
  CasinoIcon,
  FavoritesIcon,
  TransactionIcon,
  SlotMachine,
  SportsIcon,
  AffiliateIcon,
  ReferralIcon,
  PromotionsIcon,
  SponsorshipsIcon,
  BonusesIcon,
  LanguageIcon,
  SoccerLeft,
  SoccerRight,
  DiceLeft,
  DiceRight
} from "assets/icons/index";
import {
  getCasinoCategoryGame,
  getCasinoGamesCategories,
} from "redux-thunk/thunk/game.thunk";
import { ROUTE_PATHS } from "constants/index";
import { getPromotions } from "redux-thunk/thunk/bonus.thunk";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { setChangeHamburgerIconClass } from "redux-thunk/redux/slices/user.slice";

const languageNames = {
  en: 'English',
  fr: 'French',
  es: 'Spanish',
  pt: 'Portuguese',
  id: 'Indonesian'
};

const getFullLanguageName = (languageCode) => {
  return languageNames[languageCode] || languageCode;
};

function SideNavbar () {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const isLoggedIn = getAuthToken();
  const { tab } = useSelector((state) => state?.lobby);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [languageOpen, setLanguageOpen] = useState(false);

  const { casinoGameCategories, subCategoryId } = useSelector(
    (state) => state.games
  );
  const { selectedCategoryId } = useSelector((state) => state.casino);
  const [activeTab, setActiveTab] = useState(
    pathname === "/" || pathname === "/casino" ? t("allGames") : ""
  );

  const handleButtonClick = (tabName) => {
    setActiveTab(tabName);
    const element = document.getElementById("category-slider");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  // ----------------- handling the tab switch issue of sideNavBar
  useEffect(() => {
    if (isMobile) {
      dispatch(setShowSideNav(false));
    }

    if (
      (pathname !== "/sports" && tab === "sports") ||
      pathname.slice(0, 7) === "/casino"
    ) {
      dispatch(setTab("casino"));
    }

    const hasSponsor = pathname.includes("sponsorships");
    const hasPromotion = pathname.includes("promotion");
    const hasBonus = pathname.includes("bonus");

    const isSubModule = hasSponsor || hasBonus || hasPromotion;
    if (isSubModule || (pathname === '/' && activeTab === 'sponsorships')) return;

    if (pathname !== activeTab) {
      setActiveTab('');
    }

  }, [pathname]);
  // --------------------------------

  useEffect(() => {
    dispatch(setShowSideNav(true));
    dispatch(getCasinoGamesCategories({ categoryId: selectedCategoryId }));
  }, []);

  useEffect(() => {
    if (subCategoryId !== null && (pathname === "/" || pathname === "/casino"))
      setActiveTab(subCategoryId);
    else if (
      subCategoryId === null &&
      (pathname === "/" || pathname === "/casino")
    )
      setActiveTab(t("allGames"));
  }, [subCategoryId]);
  const { hamburgerIconClass } = useSelector((state) => state?.user);

  const getCategoryGames = (item) => {
    dispatch(setGameCategoryId({ id: item?.id, subCategory: item }));
    history.push("/casino");
  };

  const getAllCategoryGames = () => {
    dispatch(setGameCategoryId({ id: null, subCategory: null }));
    dispatch(getCasinoCategoryGame({}));
    history.push("/casino");
  };

  const tabChangeHandler = (tabName) => {
    setActiveTab(tabName); // Update the active tab state here
    dispatch(setTab(tabName));
    history.push(`/${tabName}`);
  };

  const [isCollapsed, setCollapsed] = useState(false); // State to manage collapse/expand
  const handleCollapseToggle = () => {
    setCollapsed(!isCollapsed);
  };

  const { promotionsData } = useSelector((state) => state.bonus);
  const { language } = useSelector((state) => state.auth);
  const fetchPromotions = () => {
    const reqBody = {
      category: 3,
      limit: 10,
      pageNo: 1,
    };
    dispatch(getPromotions(reqBody));
  };

  useEffect(() => {
    fetchPromotions();
  }, []);

  const sponsorshipsList = useCallback(() => {
    if (
      !promotionsData ||
      !promotionsData?.promotions ||
      !promotionsData?.promotions?.rows
    ) {
      return [];
    }
    return promotionsData?.promotions?.rows.map((promotionData) => {
      return {
        ...promotionData,
        title: promotionData?.title?.[language?.toUpperCase()]
          ? promotionData?.title?.[language?.toUpperCase()]
          : promotionData?.title?.["EN"?.toUpperCase()],
        content: promotionData?.content?.[language?.toUpperCase()]
          ? promotionData?.content?.[language?.toUpperCase()]
          : promotionData?.content?.["EN"?.toUpperCase()],
        description: promotionData?.description?.[language?.toUpperCase()]
          ? promotionData?.description?.[language?.toUpperCase()]
          : promotionData?.description?.["EN"?.toUpperCase()],
      };
    });
  }, [promotionsData, language]);

  const casinoGameCategoriesList = useCallback(() => {
    if (
      !casinoGameCategories ||
      !casinoGameCategories?.subCategoryList ||
      !casinoGameCategories?.subCategoryList?.rows
    ) {
      return [];
    }
    return casinoGameCategories?.subCategoryList?.rows.map(
      (casinoGameCategoriesData) => {
        return {
          ...casinoGameCategoriesData,
          name: casinoGameCategoriesData?.name?.[language?.toUpperCase()]
            ? casinoGameCategoriesData?.name?.[language?.toUpperCase()]
            : casinoGameCategoriesData?.name?.["EN"?.toUpperCase()],
        };
      }
    );
  }, [casinoGameCategories, language]);

  const sponsorshipsSubCategory = sponsorshipsList()?.map(
    (sponsorship) => sponsorship?.title
  );

  useEffect(() => {
    if (
      activeTab !== "sponsorships" &&
      !sponsorshipsSubCategory?.includes(activeTab)
    ) {
      setCollapsed(false);
    }
  }, [activeTab]);


  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
    setLanguageOpen(false);
  };

  return (
    <SidebarWrapper
      theme={theme}
      className={`sidebar-section ${hamburgerIconClass}`}
    >
      <Paper className='sidebar-paper'>
        {/* <div className="buble"></div>
        <div className="buble6"></div> */}

        <MenuList className="top-menulist" style={{ paddingTop: theme.spacing(2), paddingBottom: theme.spacing(1) }}>

          <MenuItem className="common-btn">
            <Tooltip
              title={t("casino")}
              arrow
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              placement="top"
            >
              <Box
                className={` casino-btn ${tab === "casino" ? " active" : ""}`}
                onClick={() => {
                  tabChangeHandler("casino");
                  getAllCategoryGames();
                }}
              >
                <ListItemIcon className='dice-left'>
                  <DiceLeft/>
                </ListItemIcon>
                <ListItemIcon className='dice-right'>
                  <DiceRight/>
                </ListItemIcon>
                <ListItemText>{t("casino")}</ListItemText>
              </Box>
            </Tooltip>

            <Tooltip
              title={t("sports")}
              arrow
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              placement="top"
            >
              <Box
                className={` sport-btn ${tab === "sports" ? " active" : ""}`}
                onClick={() => tabChangeHandler("sports")}
              >
                <ListItemIcon className='dice-left'>
                  <SoccerLeft/>
                </ListItemIcon>
                <ListItemIcon className='dice-right'>
                  <SoccerRight/>
                </ListItemIcon>
                <ListItemText>{t("sports")}</ListItemText>
              </Box>
            </Tooltip>
          </MenuItem>
        </MenuList>

        <MenuList>

          <MenuItem
            className="nav-link-hidden"
            onClick={() => {
              tabChangeHandler("casino");
              getAllCategoryGames();
            }}
          >
            <Box className={` nav-link ${tab === "casino" ? " active" : ""}`}>
              <Tooltip
                title={t("casino")}
                arrow
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                placement="right"
              >
                <ListItemIcon>
                  <BsFillDice2Fill />
                </ListItemIcon>
              </Tooltip>
            </Box>
          </MenuItem>

          <MenuItem
            className="nav-link-hidden"
            onClick={() => tabChangeHandler("sports")}
          >
            <Box className={` nav-link ${tab === "sports" ? " active" : ""}`}>
              <Tooltip
                title={t("sports")}
                arrow
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                placement="right"
              >
                <ListItemIcon>
                  <SportsIcon />
                </ListItemIcon>
              </Tooltip>
            </Box>
          </MenuItem>


          {tab === "casino" && (
            <Box>

              {casinoGameCategoriesList()?.map((item) => {
                return (
                  <MenuItem
                    className={`${activeTab === item?.id ? " tabsidebar" : ""
                      }`}
                    key={uuidv4()}
                    onClick={() => {
                      handleButtonClick(item?.id);
                      getCategoryGames(item);
                    }}
                  >
                    <Grid>
                      <Tooltip
                        title={t(item?.name)}
                        arrow
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        placement="right"
                      >
                        <Box
                          className={` nav-link ${activeTab === item?.id ? " active" : ""
                            }`}
                        >
                          <ListItemIcon>
                            {item?.imageUrl ? <img src={`${process.env.REACT_APP_IMG_URL}${item?.imageUrl}`} alt='casinoImg' loading="lazy" /> : <SlotMachine />}
                          </ListItemIcon>
                          <ListItemText>{t(item?.name)}</ListItemText>

                        </Box>
                      </Tooltip>
                    </Grid>
                  </MenuItem>
                );
              })}
            </Box>
          )}

          {isLoggedIn && (
            <MenuItem onClick={() => setActiveTab("referral")}>
              <Tooltip
                title={t("referral")}
                arrow
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                placement="right"
              >
                <NavLink
                  className={` nav-link ${activeTab === "referral" ? " active" : ""
                    }`}
                  to="/referral"
                >
                  <ListItemIcon>
                    <ReferralIcon />
                  </ListItemIcon>
                  <ListItemText>{t("referral")}</ListItemText>
                </NavLink>
              </Tooltip>
            </MenuItem>
          )}

          <MenuItem className='nav-link-bg' onClick={() => setActiveTab("Affiliate")}>
            <Tooltip
              title={t("Affiliate")}
              arrow
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              placement="right"
            >
              <NavLink
                className={` nav-link ${activeTab === "Affiliate" ? " active" : ""
                  }`}
                to="/affiliate"
              >
                <ListItemIcon>
                  <AffiliateIcon />
                </ListItemIcon>
                <ListItemText>{t("Affiliate")}</ListItemText>
              </NavLink>
            </Tooltip>
          </MenuItem>

          <MenuItem onClick={() => setActiveTab("Promotions")}>
            <Tooltip
              title={t("Promotions")}
              arrow
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              placement="right"
            >
              <NavLink
                className={` nav-link ${activeTab === "Promotions" ? " active" : ""
                  }`}
                to={ROUTE_PATHS.PROMOTION}
              >
                <ListItemIcon>
                  <PromotionsIcon />
                </ListItemIcon>
                <ListItemText>{t("Promotions")}</ListItemText>
              </NavLink>
            </Tooltip>
          </MenuItem>

          <MenuItem onClick={() => setActiveTab("Bonus")}>
            <Tooltip
              title={t("Bonus")}
              arrow
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              placement="right"
            >
              <NavLink
                className={` nav-link ${activeTab === "Bonus" ? " active" : ""
                  }`}
                to="/bonus"
              >
                <ListItemIcon>
                  <BonusesIcon />
                </ListItemIcon>
                <ListItemText>{t("Bonus")}</ListItemText>
              </NavLink>
            </Tooltip>
          </MenuItem>

          <MenuItem
            onClick={() => {
              setActiveTab("Sponsorship");
              dispatch(setChangeHamburgerIconClass("sidebar-open"));
            }}
          >
            <List className="inner-menu-list">
              <ListItem onClick={handleCollapseToggle}>
                <Tooltip
                  title={t("Sponsorship")}
                  arrow
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  placement="right"
                >
                  <Box
                    className={` nav-link sidebar-dropdown ${activeTab === "Sponsorship" ? " active" : ""
                      }`}
                  >
                    <ListItemIcon>
                      <SponsorshipsIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("Sponsorship")} />

                    <IconButton aria-label="delete" sx={{ color: "#57535E" }}>
                      {isCollapsed ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <ChevronRightIcon />
                      )}
                    </IconButton>
                  </Box>
                </Tooltip>
              </ListItem>
              <Collapse in={isCollapsed} timeout="auto" unmountOnExit>
                <List className="inner-to-inner-list">
                  {sponsorshipsList().length > 0 ? (
                    sponsorshipsList()?.map((sponsershipData) => {
                      return sponsershipData?.url ? (
                        <Tooltip
                          title={sponsershipData?.title}
                          arrow
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          placement="right"
                          key={uuidv4()}
                        >
                          <ListItem >
                            <a href={sponsershipData?.url} target='_blank' rel='noreferrer' className='nav-link text-capitalize' key={uuidv4()}>  <ListItemText>{sponsershipData?.title}</ListItemText></a>
                          </ListItem>
                        </Tooltip>
                      ) : (
                        <ListItem
                          key={uuidv4()}
                          onClick={(e) => {
                            e.stopPropagation();
                            setActiveTab(sponsershipData?.title);
                          }}
                        >
                          <Tooltip
                            title={sponsershipData?.title}
                            arrow
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            placement="right"
                          >
                            <NavLink
                              button
                              className={` nav-link ${activeTab === sponsershipData?.title
                                ? " active"
                                : ""
                                }`}
                              to={{
                                pathname: `/sponsorships/${sponsershipData?.slug}`,
                                state: { data: sponsershipData },
                              }}
                            >
                              <ListItemText className="text-capitalize">{sponsershipData?.title}</ListItemText>
                            </NavLink>
                          </Tooltip>
                        </ListItem>
                      );
                    })
                  ) : (
                    <ListItem>
                      <Box className="nav-link">
                        <ListItemText>{t("noDataAvailable")}</ListItemText>
                      </Box>
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </List>
          </MenuItem>

          {/* <MenuItem onClick={() => handleButtonClick('transaction')}>
            {isLoggedIn
              ? (
                <NavLink className={` nav-link ${activeTab === 'transaction' ? ' active' : ''}`} to='/transaction'>
                  <ListItemIcon>
                    <TransactionIcon />
                  </ListItemIcon>
                  <ListItemText>{t('accountMenuTransactions')}</ListItemText>
                </NavLink>
                )
              : (
                <Grid />
                )}
          </MenuItem> */}
          {isLoggedIn ? (<MenuItem onClick={() => handleButtonClick("vipclub")}>
              <Tooltip
                title={t("vipClub")}
                arrow
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                placement="right"
              >
                <NavLink
                  className={` nav-link ${activeTab === "vipclub" ? " active" : ""
                    }`}
                  to={ROUTE_PATHS.VIPCLUB}
                >
                  <ListItemIcon>
                    <TransactionIcon />
                  </ListItemIcon>
                  <ListItemText>{t("vipClub")}</ListItemText>
                </NavLink>
              </Tooltip>
          </MenuItem> ) : (
              <Grid />
            )}

          {isLoggedIn ? (<MenuItem onClick={() => handleButtonClick("favorite")}>
              <Tooltip
                title={t("favorites")}
                arrow
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                placement="right"
              >
                <NavLink
                  className={` nav-link ${activeTab === "favorite" ? " active" : ""
                    }`}
                  to="/favorite"
                >
                  <ListItemIcon>
                    <FavoritesIcon />
                  </ListItemIcon>
                  <ListItemText>{t("favorites")}</ListItemText>
                </NavLink>
              </Tooltip>
          </MenuItem> ) : (
              <Grid />
            )}

          <MenuItem
            onClick={() => {
              setActiveTab("language");
              dispatch(setChangeHamburgerIconClass("sidebar-open"));
            }}>
          <List className="inner-menu-list">
            <ListItem>
            <Tooltip
                  title={t("language")}
                  arrow
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  placement="right"
                >
                  <Box
                    className={` nav-link sidebar-dropdown ${activeTab === "language" ? " active" : ""
                      }`} onClick={() => setLanguageOpen(!languageOpen)}
                  >
                    <ListItemIcon>
                      <LanguageIcon />
                    </ListItemIcon>
                    <ListItemText primary={getFullLanguageName(selectedLanguage)} />

                    <IconButton aria-label="delete" sx={{ color: "#57535E" }}>
                      {languageOpen ? (<KeyboardArrowDownIcon />) : (<ChevronRightIcon />)}
                    </IconButton>
                  </Box>
            </Tooltip>
            </ListItem>
            <Collapse in={languageOpen} timeout="auto" unmountOnExit>
              <List className="inner-to-inner-list link-hidden">
                <ListItem onClick={() => handleLanguageChange("en")}>
                  <Box className="nav-link">
                    <ListItemText> English</ListItemText>
                  </Box>

                </ListItem>
                <ListItem onClick={() => handleLanguageChange("es")}>
                  <Box className="nav-link">
                    <ListItemText> Spanish</ListItemText>
                  </Box>
                </ListItem>
                <ListItem onClick={() => handleLanguageChange("pt")}>
                  <Box className="nav-link">
                    <ListItemText> Portuguese</ListItemText>
                  </Box>
                </ListItem>
                <ListItem onClick={() => handleLanguageChange("id")}>
                  <Box className="nav-link">
                    <ListItemText> Indonesian</ListItemText>
                  </Box>

                </ListItem>
              </List>
            </Collapse>
            </List>
          </MenuItem>

        </MenuList>

      </Paper>
    </SidebarWrapper>
  );
}

export default React.memo(SideNavbar);
