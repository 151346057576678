import { Box, styled } from '@mui/material'
import checkIcon from '../../assets/icons/check.svg'
export const WalletModalStyle = styled(Box)(({ theme }) => {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: theme.spacing(60),
    width: '100%',
    backgroundColor: '#1a2c38',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    maxHeight: '100vh',
    overflowY: 'auto',

    '.input-with-button' : {
      display: 'flex',
      alignItems: 'center',
    },

    '.box-flex-currency' : {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid #3b5364',
      'p': {
        marginBottom: '.5rem',
        color: '#6c7b8e',
      }
    },

    '.currency-user': {
      backgroundColor: '#213743',
      borderRadius: '10px',
      padding: '20px',
    },

    '.currency': {
      marginTop: '.5rem',
    },

    '.currencyName': {
      textAlign: 'left',
    },

    '.amountCurrency': {
      textAlign: 'right',
    },

    '.input-with-button:hover' : {
      backgroundColor: 'transparent',
    },

    '.input-with-button button' : {
      marginLeft: '10px',
      marginRight: '10px',
    },

    '& .login-modal': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& .MuiTabs-indicator': {
        display: 'none'
      },

      '& .modal-heading': {
        textAlign: 'left',
        fontSize: theme.spacing(2.5),
        color: theme.palette.primary.contrastText
      },

      '& .login-tab': {
        marginTop: theme.spacing(3.5),
        '& .login-inner-tab': {
          width: '100%',
            '& button': {
              color: '#fff',
              textTransform: 'capitalize',
              fontSize: '14px',
              borderRadius: theme.spacing(0.625),
            },
            '.item-one': {
              width: '50%'
            },
            '.item-two': {
              width: '50%'
            },
            '& .Mui-selected': {
              color: 'rgb(255, 255, 255)',
              background: 'linear-gradient(90deg, rgb(63, 134, 252) 4%, rgb(13, 47, 110) 100%)',
            },
        },

        '& .tab-panel': {
          padding: theme.spacing(0)
        },

        '& .input-label': {
          color: theme.palette.primary.contrastText,
          fontSize: theme.spacing(2),
          fontWeight: theme.typography.fontWeightMedium,
          padding: theme.spacing(1),
          display: 'flex',
          '& .required-indicator': {
            color: 'red'
          }
        },
        '& .address-submit-box': {
          padding: theme.spacing(2)
        },

        '& .wallet-form-group': {

          color: theme.palette.primary.contrastTextDark,

          '& .MuiInputBase-input': {
            color: theme.palette.primary.contrastText,
            border: '0',
            borderColor: 'none',
            backgroundColor: '#213743',
            display: 'flex',
            alignItems: 'center'
          },

          '& .QRCodeGenerator': {
            background: theme.palette.primary.contrastText,
            borderRadius: theme.spacing(1.625),
            display: 'inline-block',
            padding: theme.spacing(2),
            lineHeight: theme.spacing(0)
          },
          '& .loderBox': {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginTop: theme.spacing(1.625)
          },
          '& .MuiSvgIcon-root': {
            color: theme.palette.primary.contrastText
          }
        },
        '& .custom-alert-box': {
          '& .MuiSvgIcon-root': {
            color: 'inherit'
          }
        },

        '& .custom-checkbox-group': {
          paddingTop: theme.spacing(2.5),
          display: 'flex',
          alignItems: 'flex-start',
          '& .primary-dark-text': {
            color: theme.palette.primary.contrastTextDark,
            fontSize: theme.spacing(2),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(0)
          },

          '& .custom-checkbox': {

            '& .custom-input': {
              appearance: 'none',
              padding: theme.spacing(1.25),
              border: `1px solid ${theme.palette.primary.contrastTextDark}`,
              borderRadius: theme.spacing(0.5),
              cursor: 'pointer',

              ':checked': {
                backgroundImage: `url(${checkIcon})`
              }
            }

          }

        },

        '& .social-media-box': {
          gap: theme.spacing(1),
          marginTop: theme.spacing(2),
          '& .social-media-icon': {
            background: theme.palette.primary.iconBg,
            textAlign: 'center',
            padding: theme.spacing(1.6, 6),
            borderRadius: theme.spacing(0.625)
          }
        },
        '& .forgot-text': {
          display: 'flex',
          justifyContent: 'center',
          marginTop: theme.spacing(3.5),

          a: {
            color: theme.palette.primary.contrastText,
            fontSize: theme.spacing(2),
            fontWeight: theme.typography.fontWeightMedium
          }
        }

      },

      '.or-divider': {
        textAlign: 'center',
        padding: theme.spacing(3.5, 0, 2, 0),

        '& .custom-divider': {
          color: theme.palette.primary.contrastTextDark,
          padding: theme.spacing(0, 1),
          position: 'relative',
          '&::before': {
            content: '""',
            width: theme.spacing(10.25),
            height: theme.spacing(0.125),
            background: theme.palette.primary.contrastTextDark,
            position: 'absolute',
            top: '50%',
            left: '100%'
          },

          '&::after': {
            content: '""',
            width: theme.spacing(10.25),
            height: theme.spacing(0.125),
            background: theme.palette.primary.contrastTextDark,
            position: 'absolute',
            top: '50%',
            right: '100%'
          }
        }

      },

      '& .primary-dark-text': {
        color: theme.palette.primary.contrastTextDark,
        paddingRight: theme.spacing(1)
      },

      '.wallet-bottom-text, .wallet-list': {
        color: theme.palette.primary.contrastTextDark
      },

      '.text-white': {
        color: theme.palette.primary.white
      }

    },

    '& .theme-select': {
      '& .MuiList-root': {
        background: 'red'
      }
    }
  }
})
