export const EN = {
  404: '404',
  register: 'Register',
  login: 'Login',
  grand: 'Grand',
  major: 'Major',
  minor: 'Minor',
  mini: 'Mini',
  crashGameTitle: 'Crash',
  betAmount: 'Bet Amount',
  profit: 'Profit',
  payout: 'Target Payout',
  chance: 'Win Chance',
  betBtn: 'Bet',
  autoCashout: 'Auto Cashout',
  btnHalf: '½',
  btnDouble: '2×',
  btnMax: 'Max',
  wagered: 'Wagered',
  lost: 'Lost',
  won: 'Won',
  streak: 'Streak',
  highestWin: 'Highest Win',
  highestLost: 'Highest Lost',
  highestBet: 'Highest Bet',
  maxStreak: 'Max Streak',
  maxProfit: 'Max Profit: ',
  bal: 'Bal: ',
  messagesNoBetHistory: 'Your bets history is empty',
  messagesCopyNotSupported: 'Your browser does not support automatic Copy to Clipboard.\nPlease try to copy the code manually.',
  messagesCopied: 'Address copied.',
  deposit: 'Deposit',
  statReset: 'Reset stats',
  rollOver: 'Roll Over',
  inputFieldBtnHalf: '½',
  inputFieldBtnDouble: '2×',
  inputFieldBtnMax: 'max',
  buttonTextPlaceBet: 'Place Bet',
  buttonTextCancelBet: 'Cancel Bet',
  buttonTextEscapeBet: 'Cashout',
  buttonTextStartAutoBet: 'Start Autobet',
  buttonTextCancelAutoBet: 'Cancel Autobet',
  buttonTextLoading: 'Loading',
  tabsMyBets: 'My Bets',
  tabsDeposit: 'Deposit',
  tabsCashout: 'Cashouts',
  tabsAllbets: 'All Bets',
  tabsTopbets: 'Top Bets',
  tabsBonus: 'Bonus',
  tabsjackpot: 'Jackpot',
  tabsBetHistory: 'Bet History',
  timerTextCurrentPayout: 'Current payout',
  timerTextStarting: 'Starting in',
  timerTextSec: 's',
  timerTextFlewAway: 'Crashed',
  timerTextWait: 'Please wait..',
  timerTextPreparingRound: 'Preparing round',
  timerTextLoadingRound: 'Loading Round',
  comingSoon: 'Coming Soon !',
  labelsProfitOnWin: 'Profit on win',
  inputsNoOfBets: 'Number of bets',
  inputsAuto: 'Auto',
  inputsHalf: '1/2',
  inputsDouble: '2×',
  inputsStart: 'Start',
  inputsCancel: 'Cancel',
  buttonsMin: 'Min',
  buttonsMax: 'Max',
  prediction: 'Prediction',
  luckyNumber: 'Lucky Number',
  multiplier: 'Multiplier',
  chanceToWin: 'Chance to win',
  bets: 'Bets',
  depositBtn: 'Deposit',
  rollUnder: 'Roll Under',
  symbolsX: 'x',
  symbolsPercent: '%',
  navBarTitle: 'History',
  navBarMyBets: 'My Bets',
  navBarAllBets: 'All Bets',
  navBarHighRollers: 'High Rollers',
  navBarRoundHistory: 'Round History',
  navBarTotal: 'Total Bets',
  topHeaderBetId: 'Bet ID',
  topHeaderUser: 'User',
  topHeaderPayout: 'Payout',
  topHeaderAmount: 'Amount',
  topHeaderTime: 'Time',
  topHeaderProfit: 'Profit',
  topHeaderRoundCrashed: 'Crashed',
  topHeaderRoundEscape: '@',
  topHeaderRoundRoundHash: 'Hash',
  topHeaderRoundBet: 'Bet',
  topHeaderRoundProfit: 'Profit',
  topHeaderRoundSignature: 'Signature',
  roundHistoryTitle: 'GAME INFORMATION',
  roundHistoryDescGame: 'Game #',
  roundHistoryDescCrashAt: 'Crashed At:',
  roundHistoryDescDate: 'Date :',
  roundHistoryDescBetHeader: 'Bet#',
  roundHistoryTableTitlePlayer: 'PLAYER',
  roundHistoryTableTitleBet: 'BET',
  roundHistoryTableTitleCashedOut: 'CASHED OUT',
  roundHistoryTableTitleProfit: 'PROFIT',
  roundHistoryTableTitleBetId: 'BET ID',
  roundHistoryMessagesNoBetHistory: 'Your bets history is empty',
  placedBetsTabPlaced: 'Placed Bet',
  placedBetsTabPrev: 'Previous Bet',
  placedBetsTitleUser: 'User',
  placedBetsTitleEscape: 'Multi.x',
  placedBetsTitleBet: 'Bet',
  placedBetsTitleProfit: 'Profit',
  placedBetsTitleCashout: 'Cashout',
  placedBetsNoPlacedBet: 'No placed Bets.',
  provablyFairContentCheckBtn: 'Check Provable Fair',
  provablyFairContentTitle: 'Provably Fair',
  provablyFairContentDesc: 'Four side lead’s outcome can be proven as fair. Here you can verify the game hashes and calculate the results.',
  provablyFairContentInputFieldsCrashRateTitle: 'Crash Rate',
  provablyFairContentInputFieldsCrashRatePlaceHolder: 'Crash rate without x',
  provablyFairContentInputFieldsRoundHashTitle: 'Round Hash',
  provablyFairContentInputFieldsRoundHashPlaceHolder: 'e.g. 0xaaaaaa',
  provablyFairContentInputFieldsSubmitBtn: 'Generate Signature',
  provablyFairContentSignature: 'Round Signature',
  provablyFairContentErrorsCrashRate: 'please input  number in the crash rate field',
  resetPasswordTitle: 'Reset Password',
  resetPasswordNewPasswordTitle: 'New Password',
  resetPasswordNewPasswordErrorsRequired: 'password is required',
  resetPasswordNewPasswordErrorsMinLength: 'Minimum 8 characters allowed.',
  resetPasswordNewPasswordErrorsMaxLength: 'Maximum 16 characters allowed.',
  resetPasswordNewPasswordErrorsPattern: '*Password should be 8 to 16 alphanumeric and special characters. It is case-sensitive.',
  resetPasswordConfirmPasswordTitle: 'Confirm Password',
  resetPasswordConfirmPasswordErrorsNotMatched: 'Passwords do not match.',
  resetPasswordResetBtn: 'Reset',
  passwordResetSuccess: 'Password successfully changed.',
  resetPasswordWentWrong: 'Something went wrong!',
  levelGrand: 'GRAND',
  levelMajor: 'MAJOR',
  levelMinor: 'MINOR',
  levelMini: 'MINI',
  bannerContentWinDaily: 'Win Daily $1000 ',
  bannerContentContest: 'Contests now ',
  bannerContentjoin: 'Join our daily wagering contest and become the Hero! higher your position in the ranking when the bigger your prize is! ',
  bannerContentPlayNow: 'Play Now',
  bannerContentTitle: 'Win Jackpot',
  bannerContentContent: 'Play more & more games and get a chance to win jackpot. The more you wager the highest chance of your for jackpot winning.',
  bannerContentGameName: 'CRASH GAME',
  bannerContentGameDesc: 'Play more & more games and get a chance to win. The more you wager the highest chance of your for winning Rewards.',
  partners: 'Partners',
  gamesGoldenDragon: 'Golden Dragon',
  gamesCrashGame: 'Crash Game',
  gamesDice: 'Dice',
  gamesHilo: 'Hilo',
  allBetsTitle: 'All Bets',
  allBetsBetId: 'Bet ID',
  allBetsUser: 'User',
  allBetsPayout: 'Payout',
  allBetsAmount: 'Amount',
  allBetsTime: 'Time',
  allBetsProfit: 'Profit',
  LoginwithOtp: 'Login with Otp',
  EnterLoginOtp: 'Enter Login Otp',
  submit: 'Submit',
  footerAboutUs: 'About Us',
  footerFaq: 'FAQs',
  footerSitePolicy: 'Site Policy',
  footerResponsibleGambling: 'Responsible Gambling',
  footerTerms: 'Terms and Conditions',
  footerCopywrite: '2022 CRYPTO LINE. All rights reserved',
  signinTitle: 'Login',
  signinUsername: 'Username or Email',
  signinPassword: 'Password',
  signinLoginBtn: 'Login',
  signinSignup: 'Sign Up',
  signinForgetPassword: 'Forgot Password?',
  signinDontHaveAccount: 'Don’t have an account? ',
  signinCreateAccount: 'Create account',
  signinLoginDescription: 'Clutch Gaming might on occasion send you free offers and special promotions by email or SMS. You can opt out of receiving these at any time in your account settings.',
  signinErrorsUserNameRequired: '*Please enter Username/Email.',
  signinErrorsUserNameMinLength: 'Minimum 4 characters allowed.',
  signinErrorsUserNameMaxLength: 'Maximum 20 characters allowed.',
  signinErrorsUserNamePattern: 'Please enter valid username, only letters and numbers are allowed.',
  signinErrorsPasswordRequired: 'Password is required',
  signinErrorsPasswordMinLength: 'Minimum 8 characters allowed.',
  signinErrorsPasswordMaxLength: 'Maximum 16 characters allowed.',
  signinErrorsPasswordPattern: '*Password should be 8 to 16 alphanumeric and special characters. It is case-sensitive.',
  signinSuccessfullyLoggedIn: 'Successfully logged in.',
  signinResetMailSent: 'Reset password email sent.',
  signinTryAgain: 'Something went wrong!, Please try again.',
  signinResetPasswordUsernameErr: 'Please enter username/email to reset password.',
  signinResetMailSentSuccessContent: 'We sent you a reset password link on your email. Please check your email and reset your password.',
  signupTitle: 'Register',
  signupUsername: 'Username',
  signupEmail: 'Email',
  signupTermsConditions: 'Terms & Conditions',
  signupWalletConnect: 'Login with Wallet ',
  signupPassword: 'Password',
  signupEighteenPlus: 'I’m over 18 years of age and I agree to the Terms of Service',
  signupRegisterBtn: 'SignUp',
  signupCreateNewAccount: 'Create New Account',
  signupFullName: 'Full Name',
  signupReferralCode: 'Referral Code',
  signupConfirmPassword: 'Confirm Password',
  signupUseSocialAccount: 'Or Use Social Account',
  signupFacebook: ' Facebook',
  signupGoogle: ' Google',
  signupHaveAccount: 'Have an account? ',
  signupLoginBtn: 'Login',
  signupErrorsUserNameRequired: '*Please enter username.',
  signupErrorsUserNameMinLength: 'Minimum 4 characters allowed.',
  signupErrorsUserNameMaxLength: 'Maximum 20 characters allowed.',
  signupErrorsUserNamePattern: 'Please enter valid username, only letters and numbers are allowed.',
  signupErrorsFullNameRequired: '*Please enter full name.',
  signupErrorsFullNamePattern: 'Please enter valid full name, only letters and numbers are allowed.',
  signupErrorsEmailRequired: '*Please enter email.',
  signupErrorsEmailMinLength: 'Minimum 4 characters allowed.',
  signupErrorsEmailMaxLength: 'Maximum 20 characters allowed.',
  signupErrorsEmailPattern: 'Please enter valid email.',
  signupErrorsPasswordRequired: 'Password is Required',
  signupErrorsPasswordMinLength: 'Minimum 8 characters allowed.',
  signupErrorsPasswordMaxLength: 'Maximum Deposit16 characters allowed.',
  signupErrorsPasswordPattern: '*Password should be 8 to 16 digi alphanumeric(upper and lower case) and special characters. It is case-sensitive.',
  signupErrorsConfirmPasswordMatch: 'Password must match.',
  signupPlaceholderUserName: 'Min. 4 character and only number & letters',
  signupPlaceholderEmail: 'e.g. abc@gmail.com',
  signupPlaceholderPassword: 'Min. 8 character with a number or symbol',
  signupSomethingWentWrong: 'Something went wrong!',
  signupSignupGoogle: 'Successfully signup with Google.',
  signupRegistered: 'Successfully registered.',
  signupConfirmAge: 'Please confirm you are 18 years old.',
  signupSignupFacebook: 'Successfully signup with Facebook.',
  signupCookiesConsent: 'Please allow third party cookies from your browser’s settings to use Social login.',
  signupRegisterEmailPopupContent: 'We have sent you a verification email. Please check your email and verify your account.',
  accountMenuDeposit: 'Deposit',
  accountMenuCashout: 'Cashout',
  accountMenujackpotWinners: 'Jackpot Winners',
  accountMenuTransactions: 'Transactions',
  accountMenuPromotions: 'Promotions',
  accountMenuProfile: 'Profile',
  accountMenuLogout: 'Logout',
  accountMenuSound: 'Sound',
  accountMenuMusic: 'Music',
  accountMenuWithdraw: 'Withdraw',
  pageNotFound: 'We can’t find the page you are looking for!',
  error: 'Error',
  somethingWentWrong: 'Something went wrong!',
  verifyEmailThankYou: 'Thank You!',
  verifyEmailEmailIsVerified: 'Your email is verified. Please login to enjoy Gaming!',
  verifyEmailGoToLogin: 'Go to Login page',
  verifyEmailWentWrong: 'Something went wrong!',
  verifyEmailTokenExpired: 'Email already verified or link expired please resend email verification link by providing your registered email below. If this is happening consistently, Please contact support@casinogaming.com',
  verifyEmailGoToHome: 'Go to Home page',
  verifyEmailSuccessMsg: 'Email successfully verified.',
  gameCardTitle: 'Clutch Gaming Provably Fair Crypto Gambling Site',
  bonusSectionTitle: 'CLUTCH GAMING',
  bonusSectionSpanText: 'presents the most generous and various',
  bonusSectionBonuses: 'Bonuses',
  bonusSectionSubtitleText: 'Play with pleasure and get additional rewards during the game. Choose the most attractive bonuses or use them all at once.',
  bonusSectionHeadingText: 'Bonuses from Clutch Gaming',
  bonusSectionDesc: 'As the best Bitcoin casino, Clutch Gaming has an elaborate system of generous bonuses. Let’s look at the main categories of Bonuses.',
  JoiningBonusTitle: 'Joining Bonus',
  JoiningBonusButton: 'Claim Now',
  JoiningBonusDesc: 'Such bonuses encourage online casino players to gamble using Bitcoin or other cryptos. As a rule, these are additional funds to the game account or some game perks. So, in classic online casinos, they may come in the form of free spins or additional game rounds. In the case of our Bitcoin online casino, these are free Gold. In the near future we expect to add more bonuses in particular Deposit Bonus.',
  depositBonusTitle: 'Deposit Bonus',
  depositBonusButton: 'Deposit',
  depositBonusDesc: 'Such bonuses encourage online casino players to gamble using Bitcoin or other cryptos. As a rule, these are additional funds to the game account or some game perks. So, in classic online casinos, they may come in the form of free spins or additional game rounds. In the case of our Bitcoin online casino, these are free Gold. In the near future we expect to add more bonuses in particular Deposit Bonus.',
  refferBonusTitle: 'Reffer Bonus',
  refferBonusButton: 'Deposit',
  refferBonusDesc: 'Such bonuses encourage online casino players to gamble using Bitcoin or other cryptos. As a rule, these are additional funds to the game account or some game perks. So, in classic online casinos, they may come in the form of free spins or additional game rounds. In the case of our Bitcoin online casino, these are free Gold. In the near future we expect to add more bonuses in particular Deposit Bonus.',
  aboutSectionTitle: 'What Is Clutch Gaming?',
  aboutSectionSpanText1: ' Clutch Gaming is an innovative Bitcoin casino site, created with new concepts of online gambling in mind. We have designed this site following the latest industry standards. Moreover, we have taken into account the players wishes and needs.',
  aboutSectionSpanText2: 'The online casino allows you to play your favorite games either for free or for cryptocurrency. Also, the player gets a lot of additional bonuses and gifts. Rewards are granted for creating an account, for a successful game, and for upgrading your account/character.',
  aboutSectionSpanText3: ' In this virtual world of Bitcoin gambling, you can enjoy many opportunities — playing your favorite casino games, getting bonuses, participating in contests and promotions.',
  verifiedSectionTitle: 'Verified and Licensed Software',
  verifiedSectionSpanText1: 'We don’t copy or adopt software from other websites. To make Tower.bet the best Bitcoin casino, we accuratel developed scripts, software, and each algorithm. After that, our engineers tested the operation of the platform. That’s why Tower.bet is a brand-new product that has no analogs.',
  faqSectionTitle: 'FAQ from Players',
  faqSectionForgetPasswordTitle: 'I forgot my password.',
  faqSectionForgetPasswordDesc: 'You did not provide a recovery email address for your account. Unfortunately it is not possible to reset an account’s password if you did not provide a recovery email address for it.',
  faqSectionMyDepositCreditedTitle: 'My deposit hasn’t been credited.',
  faqSectionMyDepositCreditedDesc: ' Placeholder content for this accordion, which is intended to demonstrate the class. This is the second item’s accordion body. Let’s imagine this being filled with some actual content.',
  SupportedCurrencyTitle: 'Supported Currency',
  SupportedCurrencySpanText1: 'We don’t copy or adopt software from other websites. To make Tower.bet the best Bitcoin casino, we accuratel developed scripts, software, and each algorithm. After that, our engineers tested the operation of the platform. That’s why Tower.bet is a brand-new product that has no analogs.',
  CarouselSectionTitle: 'HODL CRASH',
  CarouselSectionSubTitle: ' Play more more games and get a chance to win. The more you wager the highest chance of your for winning Rewards.',
  WalletSettingTitle: 'Wallet Setting',
  WalletSettingHideZero: 'Hide zero balances',
  WalletSettingHideZeroInfo: 'Your zero balance won’t appear in your wallet',
  WalletSettingDisplayFiat: 'Display crypto in fiat',
  WalletSettingDisplayFiatInfo: 'All bets & transactions will be settled in the crypto equivalent',
  WalletSettingNoteForApprox: 'Please note that these are currency approximations.',
  WalletSettingSave: 'Save',
  WalletSettingNoWalletFound: 'No wallet Found',
  jackpotWinnersNoWinnerAvailable: 'No winners Available',
  jackpotWinnersNew: 'New',
  jackpotWinnersHeader: 'Jackpot Winner',
  jackpotDetailsHeader: 'Jackpot detail',
  jackpotDetailsCampaignPeriod: 'Campaign Period',
  jackpotDetailsFrom: 'From',
  jackpotDetailsTo: 'To',
  jackpotDetailsBettingRule: 'Betting Rule',
  jackpotDetailsMinBetAmount: 'Min bet amount',
  jackpotDetailsMaxBetAmount: 'Max bet amount',
  jackpotDetailsBetPercentage: 'Bet percentage',
  jackpotDetailsWinningRuleHeader: 'Winning Rule',
  jackpotDetailsWinningRule: 'Placing bets with higher bet amount will increase chances of winning.',
  jackpotDetailsProfitRule: 'If bet amount is between minBetAmount and maxBetAmount then betPercentage% of bet amount will be added to jackpot amount.',
  profileTitle: 'Personal Details',
  profileFName: 'First Name',
  profileLname: 'Last Name',
  profileUserName: 'Username',
  profileDob: 'Date of birth',
  profileEmail: 'Email Address',
  profilePhone: 'Phone Number',
  profileChange: 'Change',
  profileSave: 'Save Change',
  profilePassword: 'Password',
  profileSubmit: 'Submit',
  profileUploadProfile: 'Upload Profile',
  profileCurrentPassword: 'Current Password',
  profileNewPassword: 'New Password',
  profileConfirmPassword: 'Confirm Password',
  profileScanQRCode: 'Scan QR Code',
  profileEnterSecretCode: 'Enter Secret Code',
  profileErrorsFirstNameRequired: 'First name is required.',
  profileErrorsFirstNamePattern: 'Invalid characters in your FirstName. Please use only letters.',
  profileErrorsFirstNameMinLength: 'Minimum 3 characters allowed.',
  profileErrorsFirstNameMaxLength: 'Maximum 20 characters allowed.',
  profileErrorsLastNameRequired: 'Last name is required.',
  profileErrorsLastNamePattern: 'Invalid characters in your LastName. Please use only letters.',
  profileErrorsLastNameMinLength: 'Minimum 3 characters allowed.',
  profileErrorsLastNameMaxLength: 'Maximum 20 characters allowed.',
  profileErrorsChangePasswordOldRequired: 'Old password is required.',
  profileErrorsChangePasswordOldPattern: 'Please enter valid password.',
  profileErrorsChangePasswordOldMinLength: 'Minimum 8 characters allowed.',
  profileErrorsChangePasswordOldMaxLength: 'Maximum 16 characters allowed.',
  profileErrorsChangePasswordNewRequired: 'New password is required.',
  profileErrorsChangePasswordNewPattern: 'Please enter valid password.',
  profileErrorsChangePasswordNewMinLength: 'Minimum 8 characters allowed.',
  profileErrorsChangePasswordNewMaxLength: 'Maximum 16 characters allowed.',
  profileErrorsChangePasswordNewPasswordNotMatched: 'Passwords do not match.',
  profileErrorsChangePasswordNewPasswordMatched: 'New password can not be the same as current password.',
  profileErrorsChangePasswordNewRequiredConfirmPass: 'Confirm password is required',
  profileErrorsUserNameRequired: 'User name is required.',
  profileErrorsUserNamePattern: 'Please enter valid name.',
  profileErrorsUserNameMinLength: 'Minimum 3 characters allowed.',
  profileErrorsUserNameMaxLength: 'Maximum 20 characters allowed.',
  profileErrorsDobRequired: 'Date of birth is required.',
  profileErrorsEmailRequired: 'Email is required.',
  profileErrorsPostcodeRequired: 'Postcode is required.',
  profileErrorsCityRequired: 'City is required.',
  profileErrorsCurrencyRequired: 'Currency is required.',
  profileErrorsAddressRequired: 'Address is required.',
  profileErrorsCountryRequired: 'Country is required.',
  profileErrorsEmailPattern: 'Please enter valid email.',
  profileErrorsEmailMinLength: 'Minimum 3 characters allowed.',
  profileErrorsEmailMaxLength: 'Maximum 20 characters allowed.',
  profileMessagesProfileUpdate: 'Profile updated successfully.',
  profileMessagesChangePassword: 'Password updated successfully.',
  profileProfilePicProfilePicUpload: 'Upload profile image',
  profileNoNewChanges: 'Couldn’t update profile! No new changes.',
  profileLoggedInWithSocial: 'You have logged in with social login.',
  profileSideNavigatorSound: 'Sound',
  profileSideNavigatorMusic: 'Music',
  profileSideNavigatorProfile: 'My Profile',
  profileSideNavigatorBetHistory: 'Bet History',
  profileSideNavigatorTransactions: 'Transactions',
  profileSideNavigatorDeposit: 'Deposit',
  profileSideNavigatorWithdraw: 'Withdraw',
  profileSideNavigatorGameLimits: 'Game Limits',
  profileSideNavigatorReferral: 'Referral',
  profileSideNavigatorChangePassword: 'Change Password',
  profileSideNavigatorTermsConditions: 'Terms & Conditions',
  profileSideNavigatorLogOut: 'Log Out',
  profileSideNavigatorProvablyFair: 'Provably Fair',
  WalletInfoSectionNotAvailable: 'Not Available',
  WalletInfoSectionRealBalance: 'Real Balance',
  GameLimitsMinimumBet: 'Minimum bet',
  GameLimitsMaximumBet: 'Maximum bet',
  GameLimitsMaxWinFor1Bet: 'Maximum win fir one bet',
  ProvablyFairSubTitle: 'This game uses Provably Fair technology to determine game result. This tool gives you ability to change your seed and check fairness of the game.',
  ProvablyFairMaximumBet: 'Maximum bet:',
  ProvablyFairMaxWinFor1Bet: 'Maximum win fir one bet',
  promotionsTitle: 'Promotions',
  promotionsNoLosingData: 'Sorry, No Losing bonus promotions available for ',
  promotionsNoDepositData: 'Sorry, No Deposit bonus promotions available for ',
  promotionsCurrency: ' currency.',
  promotionsViewBtn: 'View',
  promotionsClaimSuccess: 'You have successfully claimed',
  promotionsAvailability: 'Availability: ',
  promotionsAvailabilityTabLosing: 'Losing bonus',
  promotionsAvailabilityTabDeposit: 'Deposit bonus',
  promotionsDepositBonusTableCode: 'Code',
  promotionsDepositBonusTableMinDeposit: 'Min. Deposit',
  promotionsDepositBonusTablePercentage: 'Percentage',
  promotionsDepositBonusTableMaxBonus: 'Max Bonus',
  promotionsDepositBonusTableRolloverMultipler: 'Rollover multiplier',
  promotionsDepositBonusTableMaxRolloverPerBet: 'Max rollover per bet',
  promotionsDepositBonusTableTimeToAchieveRolloverTarget: 'Time to achieve rollover target',
  promotionsBonusCode: 'BONUS CODE: ',
  promotionsLossesClaim: 'Losses can be claimed for(within campaign period): ',
  promotionsLossesClaimLosingBonusTablePercentage: 'Percentage',
  promotionsLossesClaimLosingBonusTableMinLosingAmount: 'Min. losing amount',
  promotionsBonusStatus: 'Bonus Status: ',
  promotionsBonusAmount: 'Bonus Amount: ',
  promotionsRolloverTarget: 'Rollover target: ',
  promotionsRolloverAchieved: 'Rollover achieved: ',
  promotionsActivatebtn: 'ACTIVATE',
  promotionsClaimBtn: 'CLAIM',
  promotionsCancelBtn: 'CANCEL',
  promotionsBackBtn: 'BACK',
  promotionsBonus: 'Bonus',
  promotionsTermAndConditions: ' Terms & Conditions',
  promotionsClaimDepositBonusTitle: 'Steps for claiming deposit bonus',
  promotionsClaimDepositBonusFirst: 'Activate Bonus code',
  promotionsClaimDepositBonusSecond: 'Deposit amount to wallet',
  promotionsClaimDepositBonusThird: 'Achieve rollover target for claiming bonus',
  promotionsClaimLosingBonusTitle: 'Steps for claiming losing bonus',
  promotionsClaimLosingBonusFirst: 'Activate Bonus code',
  promotionsClaimLosingBonusSecond: 'Play your favorite games',
  promotionsClaimLosingBonusThird: 'Click ’Claim losses’ to claim losses within campaign period',
  promotionsWentWrong: 'Something went wrong!',
  transactionTitle: 'Transactions',
  transactionBetId: 'Bet ID',
  transactionUser: 'User',
  transactionPayout: 'Payout',
  transactionAmount: 'Amount',
  transactionTime: 'Date',
  transactionProfit: 'Profit',
  transactionCashout: 'Cashout',
  transactionBet: 'Bet',
  transactionMultix: 'Multi.x',
  transactionWin: 'Win',
  transactionFairness: 'Fairness',
  transactionReferFriend: 'Refer a Friend',
  transactionTotal: 'Total Bets',
  transactionWins: 'Wins',
  transactionRefresh: 'Refresh',
  transactionFilterTopMultipliers: 'Top Multipliers',
  transactionFilterHugeWins: 'Huge Wins',
  transactionFilterBiggestWins: 'Biggest Wins',
  transactionFilterMultipliers: 'Multipliers',
  transactionRealBalance: 'Real Balance',
  depositWithdrawTXDate: 'Date',
  depositWithdrawTXAmount: 'Amount',
  depositWithdrawTXTxId: 'TxID',
  depositWithdrawTXTxType: 'Transaction type',
  depositWithdrawTXStatus: 'Status',
  depositWithdrawTXNoData: 'No data available.',
  depositTitle: 'Deposit',
  depositHistory: 'History',
  depositSubTitle: 'User can not deposit or withdraw fiat currency. Please contact Admin to transact in fiat currency.',
  depositCopyNotSupported: 'Your browser does not support automatic Copy to Clipboard.\nPlease try to copy the code manually.',
  depositCopied: 'Address copied.',
  depositAddressCreated: 'Address created successfully.',
  depositGenerateAddress: 'Generate address',
  depositWarningText: 'Please do NOT deposit via cross-chain transfer',
  withdrawTitle: 'Withdraw',
  withdrawSubTitle: 'User can not deposit or withdraw fiat currency. Please contact Admin to transact in fiat currency.',
  withdrawNoteDesc: 'Please ensure that you do NOT input BEP2, BEP20 (BSC) address as a LTC withdraw address wallets',
  withdrawNoteSublist: ' Please do NOT withdraw as cross-chain transfer',
  withdrawHistory: 'History',
  withdrawWithdrawBtn: 'Withdraw Request',
  withdrawFees: 'Withdrawal fee {{fees}} {{token}}',
  withdrawWithdrawPending: 'Your withdraw request is pending. Admin will Appprove it soon.',
  withdrawEnterFields: 'Enter withdraw amount and your wallet address.',
  withdrawSelectWallet: 'Please select wallet to withdraw.',
  withdrawAmountFieldTitle: 'Amount (Min. 0.03 {{wallet.currency.code}})',
  withdrawAmountFieldPlaceholder: '0',
  withdrawAddressFieldTitle: 'Your {{wallet.currency.code}} address',
  withdrawAddressFieldPlaceholder: 'Type your address',
  withdrawNoBalance: 'Not enough balance.',
  withdrawMinAmount: 'Please enter amount greater than or equal to {{min}} .',
  fiatCurrencyInfo: 'User can not deposit or withdraw fiat currency. Please contact Admin to transact in fiat currency.',
  paginationNextBtn: 'Next',
  paginationPrevBtn: 'Previous',
  wentWrong: 'Something went wrong.',
  referralTitle: 'Referral',
  referralSubTitle: 'Refer your Friends',
  referralEarn: ' Earn ₹150 each',
  referralReferAndEarn: 'Refer and earn bonus for each referral',
  referralReferPara: ' If you refer this code to any user & user signup with your referral code then you will get 100% bonus in your account',
  referralReferLink: 'Refer Link',
  referralCopyReferralCode: 'Copy Referral Code',
  referralTotalRewards: 'Total Reward',
  TwoWayAuthentication: 'Two Way Authentication',
  LoginRequired: 'Login required!',
  BetPlacedSuccessfully: 'Bet Placed  successfully',
  BetAddedInQueueSuccessfully: 'Bet added in queue successfully',
  BetRemovedFromQueueSuccessfully: 'Bet removed from queue successfully',
  BetCancelledSuccessfully: 'Bet cancelled  successfully',
  PlayerEscapedSuccessfully: 'Player escaped  successfully',
  CopiedSuccessfull: 'Copied successfull',
  NotEnoughBalance: 'Not enough balance',
  AutoBetStarted: 'Auto bet started ',
  AutoBetFinished: 'Auto bet finished ',
  IdCopied: 'Id copied',
  usersConsent: 'By accessing this site, I confirm that I am 18 years old, and have reviewed, \n understood and consented to the',
  termsOfService: 'Terms of Service',
  email: 'Email',
  playNow: 'Play Now',
  orContinueWith: 'Or Continue With',
  metamaskExtensionNotAvailable: 'Please install the metaMask extension and refresh the page.',
  cashier: 'CASHIER',
  account: 'ACCOUNT',
  hello: 'Hello',
  balance: 'Balance',
  referrals: 'Referrals',
  settings: 'Settings',
  withdrawals: 'Withdrawals',
  demoWallet: 'Demo Wallet',
  myAccount: 'My Account',
  wallet: 'Wallet',
  user: 'User',
  USD: 'USD',
  amountIsRequired: 'Amount is required',
  minDepositAmount: 'Deposit amount must be greater than {{min}}.',
  minEqualDepositAmount: 'Deposit amount must be greater than or equal to {{min}}.',
  minMaxDepositAmount: 'Deposit amount must be minimum {{min}} and maximun {{max}}.',
  maxDepositAmount: 'Deposit amount must be less than {{max}}.',
  minWithdrawAmount: 'Withdraw amount must be greater than {{min}}',
  minEqualWithdrawAmount: 'Withdraw amount must be greater than or equal to {{min}}',
  withdrawMustLessThanWallet: 'Withdraw amount must be less than your wallet amount',
  accountTypeIsRequired: 'Account Type is required.',
  mobileNumberLength: 'Mobile number must be of {{length}} digits',
  phoneIsRequired: 'Phone is required',
  cpfError: 'CPF must be of {{length}} characters',
  cpfRequiredError: 'CPF is required',
  cnpjError: 'CNPJ must be of {{length}} characters',
  mainMenu: 'MAIN MENU',
  casino: 'Casino',
  sports: 'Sports',
  crypto: 'Crypto',
  NFT: 'NFT',
  poker: 'Poker',
  earnRewards: 'EARN REWARDS',
  rankUp: 'Rank Up',
  inviteFriends: 'Invite Friends',
  partnerProgram: 'Partner Program',
  home: 'Home',
  sportsbook: 'Sportsbook',
  menu: 'Menu',
  liveBets: 'LIVE BETS',
  allBets: 'All bets',
  highRollers: 'High Rollers',
  luckyBets: 'Lucky Bets',
  trade: 'Trade',
  game: 'Game',
  player: 'Player',
  time: 'TIME',
  wager: 'WAGER',
  multiplierBan: 'MULTIPLIER',
  payoutText: 'PAYOUT',
  bannerHeadOne: 'Experience true innovation with the highest',
  bannerHeadTwo: 'rewards program within the industry.',
  registerNow: 'Register now',
  liveWins: 'LIVE WINS',
  monthly: 'M',
  weekly: 'W',
  daily: 'D',
  clutchEcosystem: 'clutch ecosystem',
  allGames: 'All Games',
  allSports: 'All Sports',
  cryptoFutures: 'crypto futures',
  tradeNow: 'Trade Now',
  $clutch: '$clutch',
  allTables: 'All Tables',
  nft: 'NFT',
  viewAll: 'View All',
  rewards: 'REWARDS',
  profile: 'Profile',
  clutchProfile: 'CLUTCH PROFILE',
  totalBets: 'Total Bets',
  totalWagered: 'Total Wagered',
  joinDate: 'Join Date',
  displaySocialLinks: 'Display Linked Social Accounts',
  privateProfile: 'Private Profile',
  rank: 'Rank',
  nextRank: 'Next Rank',
  changeUsername: 'Change username',
  newPassword: 'New password',
  changeName: 'Change name',
  linkSocialAccount: 'Link social account',
  privateProfileTooltip: "After making your profile private, other users won't see your user name alongside bets, races, etc.",
  socialLinksTooltip: 'Content not available',
  rankTooltip: 'Content not available',
  balances: 'balances',
  createReferralBalance: 'Create referral balance',
  total: 'Total',
  switchBalance: 'Switch Balance',
  mainAccount: 'Main Account',
  tips: 'Tips',
  receivedAmount: 'Received amount',
  dateOrTime: 'Date/Time',
  view: 'View',
  allChains: 'All Chains',
  creditAmount: 'Credit amount',
  setCustomReferralCode: 'Set custom referrals code',
  changeEmail: 'Change email',
  newEmail: 'New email',
  createPassword: 'Create password',
  newUsername: 'New username',
  username: 'Username',
  changePassword: 'Change password',
  twoFactorAuthentication: 'two-factor authentication',
  twoFactorAuthenticationTitle: 'Using two-factor authentication is highly recommended because it protects your account with both your password and your phone.',
  twoFactorAuthenticationSubTitle: 'An email and password must be set to activate 2FA. While 2FA is enabled, you will not be able to login via Steam.',
  enable2fa: 'ENABLE 2FA',
  verifyIdentityByKyc: 'Verify your identity (kyc)',
  oldPassword: 'Old password',
  live: 'Live',
  soccer: 'Soccer',
  tennis: 'Tennis',
  fifa: 'Fifa',
  basketball: 'Basketball',
  iceHockey: 'Ice Hokey',
  volleyball: 'Volleyball',
  tableTennis: 'Table Tennis',
  baseball: 'Baseball',
  rubgy: 'Rugby',
  golf: 'Golf',
  boxing: 'Boxing',
  myBets: 'My Bets',
  favourites: 'Favourites',
  americanFootball: 'American Football',
  buyCrypto: 'Buy Crypto',
  oldEmail: 'Old Email',
  loggedInSuccess: 'Logged in successfully',
  loggedOutSuccess: 'Logged out successfully',
  wagerLimit: 'Wager limit set successfully',
  lossLimit: 'Loss limit set successfully',
  accountDisable: 'Account disabled successfully',
  sessionTimeout: 'Session timeout set successfully',
  signup: 'Signup successfully',
  depositLimit: 'Deposit limit set successfully',
  tokenVerify: 'Token has been sent to your Email, please verify',
  emailVerify: 'Email had been verified',
  emailChangedSuccess: 'Email has been changed successfully',
  profileUpdatedSuccess: 'Profile updated',
  otpVerified: 'OTP verified',
  twoFADeactivated: '2FA Authentication deactivated',
  unAuthorized: 'Unauthorized',
  dataNotFound: 'Data Not Found',
  setReferralCode: 'Set referral code...',
  ultimate: 'ULTIMATE',
  notFound: 'Not Found',
  casinoTransactions: 'Casino Transactions',
  clutchOriginals: 'Clutch Originals',
  sportsbookTransactions: 'Sportsbook Transactions',
  status: 'Status',
  success: 'Success',
  failed: 'Failed',
  pending: 'Pending',
  date: 'Date',
  to: 'To',
  gameName: 'Game Name',
  transactionId: 'Transaction Id',
  roundId: 'Round ID',
  bet: 'Bet',
  win: 'Win',
  transactionType: 'Transaction Type',
  at: 'at',
  downloadAll: 'Download All',
  gameText: 'Game',
  betId: 'Bet ID',
  gameId: 'Game ID',
  actionType: 'Action Type',
  searchGames: 'Search Games',
  provider: 'Provider',
  search: 'Search',
  sortBy: 'Sort by',
  popular: 'Popular',
  challengePool: 'Challenge Pool',
  loadMore: 'Load More',
  bitcoin: 'Bitcoin',
  chat: 'Chat',
  trades: 'Trades',
  battles: 'Battles',
  loginToChat: 'Login to chat',
  rules: 'Rules',
  chatRule1: 'Do not harass or insult other users',
  chatRule2: 'Do not beg, ask for loans, trivias or tips',
  chatRule3: 'Do not insinuate Clutch has bad intent ("scam site" etc)',
  chatRule4: 'Do not spam or post non-Clutch links',
  chatRule5: 'Do not advertise any form of trading, buying or selling services',
  chatRule6: 'Do not share or advertise your referral code',
  chatRule7: 'Do not ask to become a staff member',
  chatRule8: 'English only',
  chatRule9: 'Respect mods, admins and other users',
  send: 'Send',
  expand: 'Expand',
  signIn: 'Sign In',
  cross: 'Cross',
  collapse: 'Collapse',
  emailPlaceholder: 'Enter your email address',
  removeFromFavourite: 'Remove from favourites',
  addToFavourite: 'Add to favourites',
  footerAboutSite: 'Copyright © 2022-2023 Gammastack.com is owned and operated by HNA Gaming N.V., a company registered and established under the laws of Curacao. HNA Gaming N.V. is licensed and regulated by Antillephone N.V. (license no. 8048/JAZ2023-005). HNA Gaming N.V.’s registration number is 162383 and its registered address is Schottegatweg Oost 10 Unit 1- 9, Bon Bini Business Center, Curaçao.\n        Gammastack Ltd. is a subsidiary of HNA Gaming N.V., acting as an Agent on behalf of HNA Gaming N.V., registered in Cyprus with registered address 34A Pireos Street, Strovolos,\n        2023 Nicosia, Cyprus and registration number ΗΕ 436088.\n        All payments are made via Gammastack. It is the player’s sole responsibility to inquire about the existing laws and regulations of the given jurisdiction for online gambling.',
  footerRightsReserved: '2023 Gammastack. All Rights Reserved"',
  signupFirstName: 'First Name',
  signupAddress: 'Address',
  signupCity: 'City',
  signupPostcode: 'Postcode ',
  signupCounty: 'Country',
  signupCurrency: 'Currency',
  signupGender: 'Gender',
  signupMan: 'Man',
  signupWomen: 'Women',
  signupOther: 'Other',
  signupLoginDetails: 'Login in Details',
  signupPersonalDetails: 'Personal Details',
  signupConfirm: 'Confirm',
  signupPrivacyPolicy: 'I have read and accepted the Privacy Policy',
  signupTermAndConditions: 'I agree with the processing of my personal data. I am above 18 years of age and have read and accepted all Terms and Conditions.',
  signupNewsLetter: 'I would like to receive bonuses, promotions, news or other relevant information periodically by News Letter.',
  signupSms: 'I would like to receive bonuses, promotions, news or other relevant information periodically by SMS.',
  // Common
  initialHeaderContent: 'Get an amazing 100%',
  secoundaryHeaderContent: 'Welcome bonus upto $100',
  playNowButton: 'Play Now',
  registerButtonContent: 'Register',
  results: 'Results',
  refresh: 'Refresh',
  cancel: 'Cancel',
  select: 'Select',
  current: 'Current',
  set: 'Set',
  remove: 'Remove',
  save: 'Save',
  not: 'not',
  before: 'Before',
  after: 'After',
  action: 'Action',
  withdrawal: 'Withdrawal',
  requested: 'Requested',
  withdraw: 'Withdraw',
  areYouSure: 'Are You Sure',
  yes: 'Yes',
  no: 'No',
  cancelled: 'Cancelled',
  approved: 'Approved',
  refunded: 'Refunded',
  chargeback: 'Chargeback',
  processedOn: 'Processed On',
  transaction: 'Transaction',
  dateTime: 'Date/Time',
  more: 'More',
  fromDate: 'From Date',
  toDate: 'To Date',
  percentage: 'Percentage',
  info: 'Info',
  min: 'Min',
  max: 'Max',
  change: 'Change',
  continueToSite: 'Continue To Site',
  choose: 'Choose',
  enterWithdrawalAmount: 'Choose or enter your Withdrawal Amount',
  capDeposit: 'DEPOSIT',
  capWithdrawal: 'WITHDRAWAL',
  capAmount: 'AMOUNT',
  minimum: 'Minimum',
  required: 'Required',
  of: 'of',
  isRequired: 'is Required',
  isAllowed: 'isAllowed',
  method: 'Method',
  capWithdraw: 'WITHDRAW',
  showLess: 'Show Less',
  clearFilter: 'Clear Filter',
  quantity: 'Quantity',
  reset: 'Reset',
  incorrect: 'Incorrect',
  load: 'Load',
  applied: 'Applied',
  ok: 'OK',
  list: 'List',
  all: 'All',
  favorite: 'Favorite',
  endPointNotFound: 'Something is wrong with the network connection',
  category: 'Category',
  seeAll: 'See All',

  // Login
  emailUserNameRequired: 'Email/Username Required',
  emailUserNamePlaceHolder: 'Enter your Email or Username',
  passwordRequired: 'Password is Required',
  newPasswordRequired: 'New Password is Required',
  accountFrozen: 'Your account is frozen till',
  resendEmail: 'Resend Email',
  resendLink: 'RESEND LINK',
  userLoggedOut: 'Logged Out Successfully',
  emailUserName: 'Enter your Email/Username',

  // SignUp and Account Info
  enterYourEmail: 'Enter your Email Address',
  emailAlreadyRegistered: 'This email is already registered',
  userNameAlreadyTaken: 'This username is already taken',
  fillAllFields: 'Fill all fields before moving to the next step',
  pleaseAccept: 'Please accept',
  acceptPrivacyPolicy: 'Privacy Policy',
  acceptTerms: 'Terms and Condition',
  modeOfComm: ' and mode of communication',
  beforeNextStep: 'before moving to the next step',
  userNamePlaceholder: 'Enter Username',
  min8Characters: 'Min 8 characters',
  min1Number: 'Min 1 Number',
  min1LowerCase: 'Min 1 lower case character',
  min1UpperCase: 'Min 1 upper case character',
  min1SpecialChar: 'Min 1 special character',
  passwordTip: 'Password must have at least one uppercase letter, one lowercase letter, one number and one special character',
  newPasswordTip: 'New password must have at least one uppercase letter, one lowercase letter, one number and one special character',
  commonWordsNotAllowed: 'Password should not be a common word like (\'password@123\', \'123456\', \'username\') or easily guessable sequence.',
  passwordPlaceholder: 'Enter Password',
  confirmPasswordPlaceholder: 'Enter Confirm Password',
  acceptAll: 'Accept All',
  firstNamePlaceholder: 'Enter First Name',
  lastNamePlaceholder: 'Enter Last Name',
  addressPlaceholder: 'Enter Address',
  cityPlaceholder: 'Enter City',
  postCodePlaceholder: 'Enter Post Code',
  phonePlaceholder: 'Enter Phone Number',
  invalidEmail: 'Invalid Email, Please enter a valid email address, like example@example.com',
  emailRequired: 'Email is Required',
  confirmPasswordRequired: 'Confirm Password is Required',
  firstNameRequired: 'Please enter your FirstName.',
  lastNameRequired: 'Please enter your Last Name.',
  phoneRequired: 'Phone is Required',
  dateOfBirthRequired: 'Date of birth is Required',
  genderRequired: 'Gender is Required',
  userNameRequired: 'Username is Required',
  addressRequired: 'Address is Required',
  cityRequired: 'City is Required',
  stateRequired: 'State is Required',
  countryRequired: 'Country is Required',
  postCodeRequired: 'Post Code Required',
  currencyCodeRequired: 'Currency Code Required',
  matchPassword: 'Passwords do not match. Please try again',
  fName3Chars: 'Your FirstName must be between 3 to 50 characters.',
  onlyAlphabet: 'Only Alphabets allowed',
  lName3Chars: 'Last Name must be atleast 3 characters',
  mustBe18: 'You Must be 18 Year old to get registered',
  validDOB: 'Please enter a valid Date of Birth',
  max20Characters: 'Maximum 20 Characters Allowed',
  min3Characters: 'Minimum 3 Characters Required',
  max100Characters: 'Maximum 100 Characters Allowed',
  max50Characters: 'Maximum 50 Characters Allowed',
  invalidNumber: 'Invalid Number',
  preferredLanguageRequired: 'Preferred Language Required',
  invalidPass: 'Invalid Password',
  prevBtn: 'Previous',
  newPasswordPlaceholder: 'Enter New Password',
  currentPasswordPlaceholder: 'Enter Current Password',

  // Account Info
  uploadImage: 'Upload Image',
  removeImage: 'Remove Image',
  preferredLanguage: 'Preferred Language',
  wantToChangePass: 'Do you want to Change Password ?',
  selectCountry: 'Select Country',
  selectPreferredLanguage: 'Select Preferred Language',
  fName50Chars: 'First Name must be atmost 50 characters',
  lName50Chars: 'Last Name must be atmost 50 characters',
  invalidDate: 'Invalid Date',
  DOBEarlyThanToday: 'Date Of Birth Must be Earlier than Today',
  enterValidPhone: 'Please enter valid phone number',

  // Account Verification
  accountStatus: 'Account Status',
  kycVerified: 'Your KYC had been verified successfully.',
  denied: 'Denied',
  chooseFile: 'Choose File',
  upload: 'Upload',
  countryCantChange: 'You are about to initiate KYC Verification. Confirm Your country before proceeding. Once the KYC has been initiated, you won\'t be able update your Country.',
  proceed: 'Proceed',
  update: ' ',

  // Bonus
  history: 'History',
  type: 'Type',
  amount: 'Amount',
  forfeited: 'Forfeited',
  expired: 'Expired',
  completed: 'Completed',
  zeroedOut: 'Zeroed Out',
  active: 'Active',
  lapsed: 'Lapsed',
  yourActiveBonus: 'Your Active Bonuses',
  currentActiveBonus: 'Current Active Bonuses',
  bonusStatus: 'Status',
  bonusWager: 'Wager',
  bonusActivate: 'Activate',
  bonusForfeit: 'Bonus Forfeit',
  notUsableBonus: 'If yes, you wont be able to use this bonus again.',
  bonusLapsed: 'You didnt have enough losses to get cashback. Therefore, bonus lapsed.',
  inProcess: 'In-Process',
  claiming: 'Claiming',

  // Loyalty
  loyaltyPopover: 'These Loyalty Points can be used for getting cashback. Find out more on our',
  loyaltyPage: 'Loyalty Page',
  loyaltyPerCurrency: 'Loyalty per currency',
  maxLevelReached: 'Max Level Reached',
  pointsToReach: 'points to reach ',
  loyaltyBannerHeading: 'You Deserve Our Brand-New Loyalty',
  loyaltyBannerDesc: '100% Welcome Bonus Upto $200',
  loyaltyHeadingOne: 'You are Always Rewarded at Gammastack',
  loyaltyHeadingOneDesc: "At Gammastack, we take loyalty to a whole new level of excitement, showering you with rewards at every twist and turn! Our thrilling loyalty program gives you points for each daring bet you place at our casino. These points propel you through our dazzling loyalty tiers, unlocking an array of incredible benefits that rain down upon you daily, weekly, and monthly. Now, let's dive into the exhilarating details of how it all works! Gammastack is thrilled to present our captivating loyalty program, featuring 10 unique levels that offer an array of enticing benefits for our cherished players. Here's a quick guide to help you understand and navigate your way to even greater rewards:",
  loyaltySubHeadOne: 'Earning Loyalty Points',
  loyaltySubHeadOneDesc: "For every €10 in cash bets placed at our casino, you'll earn 1 loyalty point. As soon as you earn points by placing bets on any of our exciting games, you'll be assigned to one of our 10 loyalty levels. Each level is designed to reward you with increasingly enticing benefits as you progress.",
  loyaltySubHeadTwo: 'Climbing the Loyalty Ladder',
  loyaltySubHeadTwoDesc: "The more points you accumulate, the higher you'll ascend through the loyalty levels, unlocking even more fantastic rewards along the way. Plus, you'll earn daily cashback based on your current level. The higher your loyalty level, the bigger the cashback percentage you'll receive daily.",
  loyaltySubHeadThree: 'Level up, get rewarded ',
  loyaltySubHeadThreeDesc: 'Elevate your gaming experience by progressing through our enticing reward tiers, where each level up unlocks an exhilarating array of free spins to maximize your chances of winning. As you climb higher, delight in the satisfaction of receiving instant cash rewards, a testament to your dedication and skill. Additionally, relish the benefits of our increasingly generous cashback offers, designed to make your journey even more rewarding, providing a safety net for your bets and an incentive to keep pushing forward.',
  loyaltyTestimonialHeadOne: 'AT Gammastack CASINO WE MAKE SURE THAT',
  loyaltyTestimonialHeadTwo: 'Every Spin Counts',
  loyaltyTestimonialDesc: "Every spin you take at the slots ain't just for fun, but also gets you closer to some seriously sweet rewards! Rack up those spins, and watch as the prizes and Cashbacks get bigger and better. It's a win-win, making your time at the casino a total blast, while you rake in those perks. So, spin away and let the good times roll!",
  loyaltyPoints: 'Points',
  headerLevel: 'level',
  loyaltyCashback: 'Cashback',
  loyaltyBannerBtn: 'Check Progress',
  loyaltyHeadingTwo: 'Unlock the Ultimate Gaming Experience: Gammastack VIP Program',
  loyaltyHeadingTwoDesc: "Once you reach Level 6 in our loyalty program, you'll unlock a world of exclusive privileges as a member of the prestigious Gammastack VIP Program. This elite club is designed to offer a superior gaming experience and pamper you with exceptional perks and benefits, including in examples below.",
  loyaltySubHeadFour: 'Higher Deposit and Withdrawal Limits',
  loyaltySubHeadFourDesc: 'Enjoy the flexibility of increased deposit and withdrawal limits as a VIP member.',
  loyaltySubHeadFive: 'Exclusive Bonuses and Promotions',
  loyaltySubHeadFiveDesc: 'Gain access to special bonuses and promotions tailor-made for our VIP players',
  loyaltySubHeadSix: 'Personal Account Manager',
  loyaltySubHeadSixDesc: 'Receive dedicated support and personalised assistance from your account manager.',
  loyaltySubHeadSeven: 'Faster Withdrawal Times',
  loyaltySubHeadSevenDesc: "As a VIP, you'll enjoy expedited withdrawal times, ensuring you can access your winnings even quicker.",
  loyaltySubHeadEight: 'Monthly Cashback Rewards',
  loyaltySubHeadEightDesc: 'Boost your bankroll with generous monthly cashback rewards exclusively for our VIP members.',
  loyaltySubHeadNine: 'Invitations to Exclusive Events and Tournaments',
  loyaltySubHeadNineDesc: 'Get your hands on coveted invites to exclusive events and tournaments, where you can mingle with fellow VIPs and compete for incredible prizes.',
  loyaltySubHeadTen: 'Birthday Bonuses',
  loyaltySubHeadTenDesc: 'Celebrate your special day in style with a delightful birthday bonus just for you!',
  loyaltySubHeadEleven: 'Luxury Gifts',
  loyaltySubHeadElevenDesc: 'Indulge in an exquisite selection of luxury gifts handpicked to reward our most valued players.',
  loyaltyTableHeading: 'Levels and Rewards Overview',
  loyaltyTableHeaderOne: 'Loyalty Level',
  loyaltyTableHeaderTwo: 'Loyalty Points',
  loyaltyTableHeaderThree: 'Daily Cashback',
  promReadMore: 'Read More',
  // Limits
  currentPortalBlock: 'if you want to block your account only for this portal.',
  allPortalBlock: 'to block your account from all portals.',
  limit24Reset: 'Once you set Wager, Loss, Deposit limits then it will be editable and removeable after 24hrs but limits can be decreased immediately.',
  enterAmount: 'Enter Amount',
  limit: 'Limit',
  loss: 'Loss',
  takeABreak: 'Take A Break',
  session: 'Session',
  selfExclusion: 'Self Exclusion',
  used: 'Used',
  edit: 'Edit',
  updatedAt: 'Updated At',
  cannotBeRemoved: 'Can not be removed before',
  timePeriod: 'Time Period',
  custom: 'Custom',
  hours: 'Hours',
  days: 'Days',
  timePeriodPlaceholder: 'Number of Days',
  months: 'Months',
  permanent: 'Permanent',
  onlyNumbers: 'Only Numbers allowed',
  notNumbers: 'Numbers Not Allowed',
  noSpaceAllow: 'Sapce is not allowed',
  userNameAllowed: 'Must Start with Alphabet. No Spaces allowed.',
  timePeriodRequired: 'Time Period Required',
  cannotBeIncreased: 'Can not be increased before',
  amountGTZero: 'Amount should be greater than 0',
  amountEqualOrLess: 'Amount should be equal or less than weekly and monthly',
  amountInBetween: 'Amount should be in between daily and monthly(inclusive)',
  amountEqualOIrGreater: 'Amount should be equal or greater than daily and monthly',
  limitRemoved: 'Your following limit will be removed.',
  limitSet24Hrs: 'You are about to set the following limit to your account. Please note, that in case you want to change the limit, you can do that after 24hrs.',
  takeABreakInfo: 'You are about to block the access to your account for a preset period of time. Please know that once you use the option, you will not be able to log in to your account to change the block period. If you would like to choose a different block period or extend the existing one, please contact us via Live Support.',
  sessionLimitInfo: 'You are about to set the following limit to your account. Please note, that in case you want to change the limit, it will take 24 hours for the limit to be changed after you set another limit amount.',
  limitCantSetBefore: 'Your session limit can not be set before',
  selfExclusionInfo: 'You are about to block the access to your account for a preset period of time. Please know that once you use the option, you will not be able to log in to your account to change the block period. If you would like to choose a different block period or extend the existing one, please contact us via Live Support.',

  // Bet History
  quickSearch: 'Quick Search',
  cash: 'Cash',
  nonCash: 'Non-Cash',
  userDetail: 'User Detail',
  gameIdentifier: 'Game Identifier',
  rollback: 'Rollback',
  rollbackBeforeBetWin: 'Rollback Before Bet Win',
  freeSpins: 'Free Spins',
  betInternal: 'Bet Internal',
  winInternal: 'Win Internal',

  // Transaction History
  addMoney: 'Add Money',
  removeMoney: 'Remove Money',
  addMoneyInternal: 'Add Money Internal',
  removeMoneyInternal: 'Remove Money Internal',
  depositInternal: 'Deposit Internal',
  withdrawInternal: 'Withdraw Internal',
  promotionTitle: 'Promotion Title',

  // Withdraw List
  cancelWithdrawRequest: 'You want to Cancel this request',
  bankname: 'Bank Name',
  selectBank: 'Select Your Bank',
  accountNumber: 'Account Number',
  enterAccountNumber: 'Enter account number',

  // Pagination Component
  rowsPerPage: 'Rows Per Page',

  // Deposit
  availedBonus: 'You already have an active bonus. To continue',
  capForfeit: 'FORFEIT',
  itFirst: 'it first.',
  selectYourBonus: 'Select Your Bonus',
  skip: 'SKIP',
  with: 'with',
  without: 'without',
  selectPaymentMethod: 'Please choose your Payment Method',
  paymentMethod: 'Payment Method',
  selectPaymentmethod: 'Select Payment Method',
  mobileNo: 'Mobile number',
  enterMobileNo: 'Enter mobile number',

  // GamesList
  volatility: 'Volatility',
  paylines: 'Paylines',
  theme: 'Theme',
  tryForFree: 'Try for Free',

  // Filter Games
  resultsFound: 'Results Found',
  games: 'Games',
  in: 'in',
  low: 'Low',
  medium: 'Medium',
  high: 'High',
  'medium-high': 'Medium High',
  'very-high': 'Very High',
  'low-medium': 'Low Medium',
  fantasy: 'Fantasy',
  ancient_civilizations: 'Ancient Civilizations',
  fruits: 'Fruits',
  africa: 'Africa',
  military: 'Military',
  joker: 'Joker',
  asia: 'Asia',
  luxurylife: 'Luxury Life',
  underwater_world: 'Underwater World',
  book_off: 'Bookoff',
  western: 'Western',
  retro: 'Retro',
  egypt: 'Egypt',
  party: 'Party',
  st_patrick_day: 'St Patrick Day',
  space: 'Space',
  easter: 'Easter',
  girls: 'Girls',
  branded: 'Branded',
  x_mas_and_new_year: 'Xmas and New Year',
  horrors: 'Horrors',
  other: 'Other',
  loginFirst: 'Please Login First To Add Game In Favorite',
  loginFirstPlay: 'Please Login First To Play this Game',
  sport: 'Sport',
  pirates: 'Pirates',
  sweets: 'Sweets',
  luxury_life: 'Luxury Life',
  st_valentines_day: 'St Valentines Day',
  halloween: 'Halloween',
  food: 'Food',

  // League List
  lobby: 'lobby',
  favourite: 'Favourite',
  playNGO: 'Play N Go',

  // Loyalty Bonus
  loyaltySystem: 'Loyalty System',
  progressionSystem: 'Progression system',
  startPoint: 'Start Point',
  endPoint: 'End Point',
  loyaltyBonus: 'Loyalty Bonus',

  // Not Found
  pageRemoved: 'This Page does not exist or was removed',
  suggestToBackHome: 'We suggest you back to home',
  backToHome: 'Back To Home',
  goHome: 'Go Home',
  oopps: 'Oopps',
  somethingNotWorking: 'We’re sorry, something is not working here!',

  // Promotions Page
  tournament: 'Tournament',
  none: 'None',
  validOnDays: 'Valid On Days',
  validTill: 'Valid Till',
  yourRequest: 'Your Request',
  withdrawalRequestPending: 'You have one withdrawal request in pending state. To activate a bonus you need to',

  // PaymentForm
  realBalance: 'Real Balance',
  bonusInCasino: 'Bonus In Casino',
  bonusSelected: 'Bonus Selected',
  payWith: 'Pay With',
  reached: 'Reached',
  receiptOfYour: 'Receipt of your',
  capEmail: 'EMAIL',
  capCreditCard: 'CREDITCARD NUMBER',
  capExpiry: 'EXPIRY',
  capCvv: 'CVV',
  expiryIncorrectFormat: 'EXPIRY is not in correct format',
  min13Numbers: 'Minimum 13 Numbers Required',
  max19Numbers: 'Maximum 19 Numbers Allowed',
  min3Numbers: 'Minimum 3 Numbers Required',
  max4Numbers: 'Maximum 4 Numbers Allowed',
  Other: 'Other',
  forfeit: 'Forfeit',

  // Withdraw
  withdrawRequestCreated: 'Withdraw Request Created Successfully',

  // ErrorMessages
  noBetHistory: 'No Bet History Found',
  noPromotions: 'No Promotions Found',
  noBonuses: 'No Bonuses Found',
  noHistory: 'No History Found',
  noWithdrawalHistory: 'No Withdrawal History Found',
  noCMS: 'No CMS Found',
  noProvidersFound: 'No Providers Found',
  noDataFound: 'No Data Found',

  // Country Blocked
  countryBlocked: 'COUNTRY BLOCKED',
  forbidden: 'FORBIDDEN',
  notAcceptingVisitors: 'We are deeply sorry about this, but shinywilds.com is not available in your country.',
  contactUsAt: 'If you think this was shown to you by error, please contact our support via email on',
  siteUnderMaintenance: 'Site Under Maintenance',
  weWillBeRightBack: 'We will be right back!',
  serviceUnavailable: 'Our service is temporarily unavailable. We are currently working to restore it.',
  tryAgainLater: 'Please try again later.',

  // Email verify
  checkYourEmail: 'Check your email',
  sentAVerification: 'We’ve sent a verification link',
  toYourEmail: 'to your email.',
  backToLogin: 'Back To Login',
  goToLogin: 'Go To Login',
  accountVerified: 'Account Verified',
  yourAccountVerified: 'Your Account has been verified',
  successfully: 'Successfully',
  verificationFailed: 'Verification failed',
  emailFailedContact: 'Verification Failed.If this is consistently happening, Please contact ',

  // Reset/Forgot Password
  setNewPass: 'Set a new password',
  passResetLink: 'We’ve sent a password reset link to',
  toYourAccount: 'To Your Account',
  sendPasswordResetLink: 'Reqest to Reset Password',
  // Play Game
  gamePlayWagerComplete: 'Congratulation, you have successfully completed wagering and bonus money won is greater than max win amount allowed. So, crediting max win amount to wallet',
  real: 'Real',
  demo: 'Demo',
  money: 'Money',

  // Toast
  formatRquired: '.png , .jpg, .jpeg format required',
  chooseImage: 'Please Choose an image',
  imageSize3MB: 'Enter Image Having Size Less than 3 MB',
  noProfileImage: 'No Profile Image available to remove',
  limitSetSuccess: 'limit set successfully',
  accountDisabled: 'Account Disabled Successfully',
  loginSuccess: 'Login Successful',
  signupSuccess: 'Sign Up Successful',
  linkExpired: 'Link Expired',
  linkSent: 'Link Sent',
  accAlreadyVerified: 'Account Already Verified. Please Login',
  profileImage: 'Profile Image',
  profileImageRemoved: 'Profile Image Removed Successfully',
  docs: 'Documents',
  updatedSuccess: 'Updated Successfully',

  // Bonus Types
  MATCH: 'MATCH',
  BALANCE: 'BALANCE',
  FREESPINS: 'FREESPINS',
  MATCH_1: 'MATCH',
  DEPOSIT: 'DEPOSIT',
  WAGERING: 'WAGERING',
  PROMOTION: 'PROMOTION',

  // Payment Category
  CREDITCARD: 'CREDIT CARD',
  WALLET: 'WALLET',
  CRYPTO: 'CRYPTO',
  OTHER: 'OTHER',
  INSTANTBANKING: 'INSTANT BANKING',

  // Cookie Consent
  cookieTitle: 'Cookies!',
  declineButton: 'Decline',
  acceptButton: 'Accept',
  cookieDeclinedMessage: 'For the best possible experience, cookies must be accepted to continue using this website. Please accept the cookies if you want to proceed.',
  cookieDescription: 'Welcome to Shinywilds.com! We use cookies to optimise your user experience. By clicking \'Accept,\' you agree to their use. You can opt-out by clicking \'Decline.',

  // Forfeit Messages
  forfeitMessageFirst: 'You currently have the following active bonus:',
  forfeitMessageSecond: 'You need to forfeit this bonus before claiming a new one.',
  forfeitButton: 'Forfeit',

  // Account info
  pleaseVerifyFirstText: "In order to be able to request a withdrawal, we first need to verify your email address. If you haven't received your a verification email before, please",
  clickHere: 'Click Here',
  pleaseVerifyLastText: 'so we can re-send you the verification email and proceed.',
  pleseWait: 'Please wait for',
  toResend: 'to resend the link',
  notVerified: 'You must verify your email address to withdraw money',
  verification: 'Verification',
  UPLOAD_IMG_MSG: 'Drag n drop files here, or click to select files',

  // Wallet Withdraw

  addressIsRequired: 'Address is required',
  withdrawRequestSent: 'Withdraw request sent to Admin, Please wait for approval!',
  withdrawAddressCannotBeSame: 'Withdraw address cannot be same as yours address',
  pasteYourCurrencyAddressHere: 'Paste your {{currency}} wallet address here...',

  // games
  noGamesAvailable: 'No games available',

  or: 'or',
  copyPasteNot: 'copy/paste is not allow',
  selectDob: 'Select date of birth',
  iAgreeWith: 'I agree with user agreement, and confirm that I am at least 18 years old!',
  iAgreeto: 'I agree to ',
  alreadyHave: 'Already have an account?',
  kyc: 'KYC',
  changePicture: 'Change Picture',
  selectGender: 'Select gender',
  male: 'Male',
  female: 'Female',
  zipCode: 'Zip code',
  selectCurrency: 'Select Your Currency',

  // new translation
  bannerTitle: 'Amazing Casino',
  bannerSubTitle: 'JUST A CLICK AWAY',
  address: 'Address',
  streetAddress: 'Street Address',
  signupAddressState: 'State',
  onlyNumberAllowed: '{{fieldName}} must contain only numbers',
  profileImageSizeFormat: 'Allow image size less than {{imageSize}} and format {{imageFormat}}', ///
  fileSizeFormat: 'Allow file size less than {{imageSize}} and format {{imageFormat}}', ///
  comingNewUpdate: 'We\'re working on something exciting. Stay tuned for updates',
  ooops: 'Ooops...',
  redirectingMessage: 'Redirecting in {{redirectTime}} seconds...',
  logIn: 'Log In',
  signUp: 'Sign Up',
  Username: 'Username',
  day: 'Day',
  month: 'Month',
  year: 'Year',
  forgetPassword: 'Forget Password',
  verifyEmail: 'Verify Email',
  loggedInUser: 'LoggedIn-User',
  favorites: 'Favorites',
  liveGames: 'Live games',
  videoSlots: 'Video Slots',
  beforeAmount: 'Before Amount',
  startDateCanNotGreterThanEnd: 'start date can not be greater than end date',
  Wallet: 'Wallet',
  Casino: 'Casino',

  // Affiliate Translation
  affiliate: 'Affiliate',
  affiliateTitle: 'Affiliate Program',
  affiliateDescription: ` Refer our brand and earn money through participating in Deuces Affiliate Program! You will receive a commission for users who register and play on Deuces through your links.
  Being a Deuces affiliate means you will earn commission through this program.`,
  affiliateDescriptionCondition: 'The affiliate amount will only be transferred if registered user\'s email is verified. (This should be visible only when user is an affiliate in affiliate management table)',
  affiliateButtonText: 'Become Affiliate',
  affiliateLink: 'Affiliate Link',
  affiliateLinkCopy: 'Link Copied Successfully',
  startDate: 'Start Date',
  endDate: 'End Date',
  affiliateEligibleDescription: 'You are not eligible to become affiliate, please complete KYC till Level 2 to become eligible for Affiliate',
  moveOnKycPage: 'to move on KYC page.',
  completeKycLevel: 'Please Complete level {{kycLevel}} KYC verification first',
  docDownload: 'Click Here to Download the Uploaded Document',
  documentUploadedSuccess: 'Document Uploaded SuccessFully',
  uploadFile: 'Upload File',
  affiliateStatus: 'Your Affiliate request is sent succesfully',
  currentStatus: 'and curent Affiliate status is {{status}}',
  fileAllowed: 'Please upload only {{files}} files',
  uploadImageError: 'You can not upload file once KYC Level is Completed or Document is Uploaded',
  reRequesteLink: 'Kindy request for Become Affiliate again',

  // Referral Translation
  referral: 'Referral',
  referralCode: 'Referral Code',
  requiredReferralCode: 'Referral Code is Required to apply',
  referralProgram: 'Referral Program',
  overview: 'Overview',
  referralDetails: 'Referral Details',
  referralIncentive: 'Refer our brand and earn money through participating in Deuces Referral Program! You will receive a commission for users who register and play on Deuces through your referral. Being a Deuces referrer means you will earn commission through this program.',
  copyTitleMessage: 'Please copy {{title}} to apply',
  isReferralCode: 'Referral Code (Otional)',
  greaterThan18years: 'and confirm that I am at least 18 years old!',
  userId: 'Userid',
  totalCommissionsRewards: 'Total Commissions Rewards',
  registrationDate: 'Registration Date',
  isReferralCheckbox: 'Deu to Affiliate Code, can\'t apply Referral Code ',
  notAvailable: 'N/A',
  serialNumber: 'SN',
  searchBy: 'Search by {{searchableKeyword}}',
  name: 'Name',
  noDataAvailable: 'No Data Available',
  reloadPage: 'reload page',
  commission: 'Commission',
  promotions: 'Promotions',
  sponsorships: 'Sponsorships',
  refreshYourPage: 'Refresh Your Page',
  latestPromotions: 'Latest Promotions',
  promotionsNotAvailable: 'Right now promotions details is not available ',
  luckyWinners: 'Lucky Winners',
  favoriteGames: 'Favorite Games',
  gameProviders: 'Game Providers',
  leadingCasinoTitle: 'Leading Online Crypto Casino',
  leadingCasinoDescription: "Discover endless excitement with Deuces' vast casino game collection. Enjoy a fair and entertaining online gambling adventure, featuring Slots, Live Casino, Blackjack, Baccarat, Roulette, and a plethora of classic favorites. Dive into the action straight from your browser.",
  casinoButtonTitle: 'Go to Casino',
  leadingSportsTitle: 'Best Crypto Sports Betting Online',
  leadingSportsDescription: 'Experience the thrill of sports betting with Deuces. Place bets on your beloved teams, players, and leagues worldwide via our cutting-edge platform. Explore a diverse array of sports markets, including MMA, Basketball, Soccer, and more, for an unparalleled sports betting journey.',
  sportsButtonTitle: 'Go to Sportsbook',
  welcomeBack: 'Welcome Back, {{userName}}',
  playSmarter: 'Play Smarter',
  loginNow: 'Login Now',
  yourKYCProgress: 'Your {{value}} Progress',
  copiedMessage: '{{title}} Copied Successfully',
  depositAddress: 'Deposit Address',
  emailVerified: 'Email Verified',
  verify: 'Verified',
  notVerify: 'Not Verify',
  winAmount: 'Win Amount',
  referralAmountMessage: "The referral amount will only be transferred if registered user's email is verified.",
  vipClub: 'VIP Club',
  bronze: 'Bronze',
  silver: 'Silver',
  gold: 'Gold',
  platinum: 'Platinum',
  vipRankingMsg: 'Deuces VIP Ranking System',
  level1: 'level 1',
  level2: 'level 2',
  level3: 'level 3',
  level4: 'level 4',
  level5: 'level 5',
  casinoLevelMessage: 'Casino - Wager amount {{amount}}',
  sportsLevelMessage: 'Sports - Wager amount {{amount}}',
  level5message: 'Player can achieve this level only given by super admin',
  totalReferredAmount: 'Total Referred Amount {{amount}}',
  invalidReferralCode: 'Invalid Referral Code',
  wantToLogin: 'Are you want to login?',
  totalWager: 'Total Wager',
  totalCommissionRewards: 'Total Commission Rewards',
  noGamesFound: 'No Games Found',
  bonusReferral: 'Bonus Referral',
  affiliateCode: 'Affiliate Code',
  bonus: 'Bonus',
  latestBonus: 'Latest Bonus',
  bonusNotAvailable: 'Right now bonus details is not available',
  lose: 'Lose',
  genderInvalid: 'Gender type should be only male or female',
  walletSettings: 'Wallet Settings',
  yearShouldBeBetween: 'Selected year should be between {{from}} - {{to}}',
  accessingSiteWarning: 'By accessing this site I attest that I am at least 18 years old and have read and agree with the Terms of Service.',
  rake_Back: '{{percent}} RAKE BACK',
  logoutSuccess: 'logout Successful',
  registerSuccess: 'Register Successful',
  forgetPasswordEmailVerifyMsg: 'Verification mail successfully sent to your registered email, password reset email is valid for {{time}} only',
  mailSentSuccess: 'Mail Sent Successfully',
  passwordChanged: 'Password Changed',
  passwordResetLogin: 'Your password changed successfully, Do you want to login',
  passwordChangedSuccess: 'Your password changed successfully',
  profileUpdate: 'Profile updated successfully',
  invalidCredentials: 'Invalid credentials',
  emailAlreadyExists: 'Email already exists',
  usernameAlreadyExists: 'Username already exists',
  emailNotExists: 'Email Not Exists',
  resetPassInvalidToken: 'Your reset password link is expire, please verify email again',
  IncorrectCurrentPassword: 'Current password is incorrect',
  formatIsRequired: '{{formatTypes}} format is required',
  anErrorOccurs: 'An error occurs, please try again',
  imageUploaded: 'Image uploaded successfully',
  insufficientBalance: 'Insufficient balance',
  withdrawApprovalReqMsg: 'Withdraw request sent successfully, the money will get transferred in sometime',
  hideZeroBalancesTitle: 'Hide zero balances',
  hideZeroBalancesSubTitle: 'Your zero balances won\'t appear in your wallet',
  displayCryptoInFiatTitle: 'Display crypto in fiat',
  displayCryptoInFiatSubTitle: 'All bets & transactions will be settled in the crypto equivalent',
  documentsAlreadyUploaded: 'Document already uploaded',
  documentsUploadedSuccessfully: 'Document uploaded successfully',
  gameAddedtoFavourite: 'Game added to favorites successfully',
  gameRemovedFromFavourite: 'Game removed from favorites successfully',
  favoriteGameExists: 'Favorite Game already exists',
  favoriteGameNotFound: 'Favorite Game not found',
  internalServerError: 'Internal server error, please contact with admin',
  realPlay: 'Real Play',
  funPlay: 'Fun Play',
  gameNotActive: 'Game is not active',
  captcha: 'This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.',
  signUpAndGet: 'Sign up and get',
  rakeDisc : '50% RAKE BACK',
  bannerFooter: 'by accessing this site I attest that I am at least 18 years old and have read and agree with the Terms of Service.',
  DeucesOriginal: 'Deuces Original',
  Promotion: 'Promotion',
  orLoginWith :'or login with',
  RegisterAnAccount: 'Register an account',
  MakeADepositAndPlay: 'Make a deposit and play',
  ReceiveRakeBack: 'RECEIVE 50% RAKEBACK',
  PreferNotToSay: 'Prefer not to say',

  // Privacy
  privacy: 'Privacy',
  ghostMode: 'Ghost Mode',
  ghostModeOn: 'Ghost Mode On',
  ghostModeOff: 'Ghost Mode Off',
  enableGhostMode: 'Enable Ghost Mode',
  yourBetsWontAppear: 'Your bets won\'t appear in public bet feed and bet preview'
}
