import React, { memo } from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material'
import { CheckCircleOutlineOutlined, ErrorOutlineOutlined, WarningOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

const AlertPopup = (props) => {
  const { t } = useTranslation()
  const {
    open,
    onClose,
    onConfirm,
    title,
    buttonTitle = t('ok'),
    message,
    type,
    width,
    height,
    isCancelButton
  } = props

  const getIcon = () => {
    switch (type) {
      case 'success':
        return <CheckCircleOutlineOutlined fontSize='large' style={{ color: 'green' }} />
      case 'error':
        return <ErrorOutlineOutlined fontSize='large' style={{ color: 'red' }} />
      case 'warning':
        return <WarningOutlined fontSize='large' style={{ color: 'orange' }} />
      default:
        return null
    }
  }

  const getTitleColor = () => {
    switch (type) {
      case 'success':
        return 'green'
      case 'error':
        return 'red'
      case 'warning':
        return 'orange'
      default:
        return 'black'
    }
  }

  const getButtonColor = () => {
    switch (type) {
      case 'success':
        return 'green'
      case 'error':
        return 'red'
      case 'warning':
        return 'orange'
      default:
        return 'blue'
    }
  }

  const dialogContentStyle = {
    width: width,
    height: height,
    display: 'flex',
    alignItems: 'center'
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <DialogTitle style={{ color: getTitleColor() }}>{title}</DialogTitle>
      {message &&
      (
        <DialogContent style={dialogContentStyle}>
          {getIcon()} &nbsp;
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {isCancelButton &&
        (
          <Button onClick={onClose} color='primary'>
            {t('cancel')}
          </Button>
        )}
        {/* onClick={onConfirm} */}
        <Button style={{ color: 'white', backgroundColor: getButtonColor() }}>
          {buttonTitle}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AlertPopup.defaultProps = {
  buttonTitle: 'OK',
  type: 'info',
  width: '400px',
  height: 'auto',
  isCancelButton: false
}

export default memo(AlertPopup)
