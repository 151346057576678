import React, { memo } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { CircularProgressBarStyle } from './style'
const CircularProgressBar = (props) => {
  const { value, size, thickness, style } = props
  
  return (
    <CircularProgressBarStyle>
      <Box className='circular-progressbar-container'>
        <CircularProgress size={size} sx={style} thickness={thickness} value={value} />
      </Box>
    </CircularProgressBarStyle>
  )
}

CircularProgressBar.defaultProps = {
  value: 0,
  size: 30,
  thickness: 3,
  style: { color: '#4caf50' }
}

export default memo(CircularProgressBar)
