import { Menu, styled } from '@mui/material'

export const StyledMenu = styled(Menu)(({ theme }) => {
  return {
    marginTop: theme.spacing(5.625),
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.primary.transparent,
      borderRadius: theme.spacing(0, 0, 1.25, 1.25),
      boxShadow: theme.shadows[4],
      marginTop: '20px',
      padding: '0'
    },
    '& .MuiList-root': {
      backgroundColor: theme.palette.primary.light,
      borderRadius: theme.spacing(0, 0, 1.25, 1.25),
      '& .MuiMenuItem-root': {
        color: theme.palette.primary.contrastText,
        fontSize: theme.spacing('1rem'),
        fontWeight: theme.typography.fontWeightRegular,
        padding: theme.spacing(1.25, 4),

        position: 'relative',
        transition: '0.3s all',
        '&:last-child': {
          // marginTop: theme.spacing(2.75),
          '&::after': {
            content: '""',
            width: theme.spacing('calc(100% - 40px)'),
            height: theme.spacing(0.25),
            backgroundColor: theme.palette.border.main,
            opacity: '0.5',
            position: 'absolute',
            top: theme.spacing(-1.375),
            left: theme.spacing(2.5)
          }
        },
        '& .icon-img': {
          width: theme.spacing(2.5),
          height: theme.spacing(2.5),
          marginRight: theme.spacing(1.875)
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.contrastTextActive,
          transition: '0.3s all'
        }
      }
    }
  }
})
