import * as Yup from 'yup'

export const verifyEmailSchema = (t) => Yup.object().shape({
  email: Yup.string().email(t('invalidEmail')).max(50, t('max50Characters')).required(t('emailRequired'))
})

export const verifyResetPasswordSchema = (t) => Yup.object().shape({
  password:
  Yup.string()
    .min(8, t('min8Characters'))
    .max(50, t('max50Characters'))
    .test('no-common-words', t('commonWordsNotAllowed'), (value) => {
      const commonWords = ['password@123', '123456', 'qwerty'] // Add more common words as needed
      return !commonWords.includes(value?.toLowerCase())
    })
    .test('no-spaces', t('noSpaceAllow'), (value) => {
      // Check if the password does not contain spaces
      return !/\s/.test(value)
    })
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
      t('passwordTip')
    )
    .required(t('passwordRequired')),

  confirmPassword:
    Yup.string()
      .oneOf([Yup.ref('password'), null], t('matchPassword'))
      .required(t('confirmPasswordRequired'))
})
