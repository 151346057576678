import React from 'react'
import { useTheme, Grid, Typography } from '@mui/material'
import { ToasterWrapper } from './style'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch } from 'react-redux'
import { setToaster } from 'redux-thunk/redux/slices/settings.slice'

const Toaster = ({ message }) => {
  const dispatch = useDispatch()
  const theme = useTheme()

  const toastCloseHandler = () => {
    dispatch(setToaster({ isVisible: false }))
  }
  return (
    <ToasterWrapper theme={theme}>
      <Grid className='toasterSection'>
        <Typography>
          {message}
        </Typography>

        <Grid>
          <CloseIcon onClick={toastCloseHandler} />
        </Grid>
      </Grid>
    </ToasterWrapper>
  )
}

export default Toaster
