import { Box, styled } from '@mui/material'

export const SocialMediaLoginStyle = styled(Box)(({ theme }) => {
  return {
    '& .social-media-box': {
      display: 'flex',
      justifyContent : 'center',
      gap: theme.spacing(1),
      margin: theme.spacing(2, 0),

    }
  }
})

export const AllSocialLoginStyle = styled(Box)(({ theme }) => {
  return {
    '& .social-media-icon': {
      background: '#0d1b32',
      textAlign: 'center',
      padding: '10px',
      borderRadius: theme.spacing(0.625),
      cursor: 'pointer'

    }
  }
}
)
