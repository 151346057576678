import { Box, styled, Switch } from '@mui/material'
import { makeStyles } from '@mui/styles/index'

export const WalletModalStyle = styled(Box)(({ theme }) => {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: theme.spacing(62.5),
    width: '100%',
    backgroundColor: theme.palette.primary.dark,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    maxHeight: '100vh',
    overflow: 'scroll',
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.scrollbar.transparent,
      borderRadius: theme.spacing(0)
    },
    '&::-webkit-scrollbar': {
      width: theme.spacing(0),
      height: theme.spacing(0)
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.scrollbar.thumb,
      borderRadius: theme.spacing(0)
    },

    '& .wallet-modal': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      '& .wallet-modal-heading': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1, 1.25),
        marginBottom: theme.spacing(1),

        '& .modal-heading': {
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(1),
          fontSize: theme.spacing(1.75),
          color: theme.palette.primary.contrastText,
          fontWeight: theme.typography.fontWeightRegular,
          textTransform: 'capitalize'
        },

        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1, 0)
        }
      }

      // '.or-divider': {
      //   textAlign: 'start',
      //   padding: theme.spacing(0, 0, 2, 0),

      //   '& .custom-divider': {
      //     color: theme.palette.primary.contrastTextDark,
      //     // padding: theme.spacing(0, 1),
      //     position: 'relative',
      //     '&::before': {
      //       content: '""',
      //       width: theme.spacing(70),
      //       height: theme.spacing(0.125),
      //       background: theme.palette.primary.contrastTextDark,
      //       position: 'absolute',
      //       top: '50%',
      //       left: '100%'
      //     }
      //   }
      // }
    },
    '& .wallet-setting-headings': {
      width: '100%',
      color: theme.palette.primary.contrastText,
      fontSize: theme.spacing(3.25),

      '& .MuiFormControlLabel-root': {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1.5),
        margin: '0',
        padding: theme.spacing(1, 1.25),
        marginBottom: theme.spacing(1),

        '& .MuiSwitch-sizeMedium': {
          margin: '0'
        },

        '& .walle-subheading': {
          fontSize: theme.spacing(1.5),
          fontWeight: theme.typography.fontWeightSemiBold,
          color: theme.palette.primary.contrastText
        },
        '& .wallet-setting-subheading': {
          color: theme.palette.primary.contrastTextDark,
          fontSize: theme.spacing(1.5),
          fontWeight: theme.typography.fontWeightRegular
        },

        '& .MuiSwitch-root': {
          '& .MuiSwitch-track': {
            backgroundColor: theme.palette.primary.checkboxSvg
          },

          '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
            background: theme.palette.button.mainGradient
          }
        }

      }

    },
    '& .wallet-setting-switch': {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2),

      '& .MuiFormControl-root': {
        width: '100%',
        '& .MuiFormGroup-root': {
          display: 'flex',
          justifyContent: 'start',
          textAlignItems: 'center',
          flexDirection: 'row',
          flexWrap: 'wrap',

          '& .MuiFormControlLabel-root': {
            width: 'calc(100% / 4)',
            display: 'flex',
            alignItems: 'center',
            margin: '0',

            '& .MuiButtonBase-root': {
              paddingLeft: '0',
              '&>span': {
                boxShadow: 'none',
                backgroundColor: 'transparent',
                backgroundImage: 'none',
                border: '2px solid',
                borderColor: theme.palette.primary.checkboxSvg,
                width: '21px',
                height: '21px',

                '&::before': {
                  content: 'none'
                }

              },
              '&.Mui-checked': {
                '&>span': {
                  backgroundColor: theme.palette.primary.radioButton,
                  border: '5px solid',
                  borderColor: theme.palette.primary.checkboxSvg
                }
              }
            },

            '& .MuiTypography-root': {
              display: 'flex',
              alignItems: 'center',
              color: theme.palette.secondary.textGray,
              fontWeight: theme.typography.fontWeightSemiBold,
              fontSize: theme.spacing(1.625)
            },

            [theme.breakpoints.down('md')]: {
              width: 'calc(100% / 3)'
            },

            [theme.breakpoints.down('sm')]: {
              width: 'calc(100% / 2)'
            }
          }
        },
        '& .disabled-label': {
          color: theme.palette.primary.contrastTextDark
        }
      }

    },
    '& .wallet-setting-footer': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: theme.spacing(1.5),
      paddingTop: theme.spacing(2),
      color: theme.palette.primary.contrastTextDark,
      borderTop: '1px solid',
      borderColor: theme.palette.primary.contrastTextDark,

      '& .MuiTypography-root': {
        fontSize: theme.spacing(1.75),
        fontWeight: theme.typography.fontWeightRegular
      },

      '& button': {
        fontSize: theme.spacing(1.75),
        fontWeight: theme.typography.fontWeightMedium,
        marginTop: '0'
      }
    }
  }
})

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}))

export const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5'
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)'
  }
}))

export const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""'
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3'
  }
})

export const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))
