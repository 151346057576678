import React, { memo } from 'react'
import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { currencySymbol } from 'utils/currencySymbol' // Assuming currencySymbol is imported from the correct location

const SymbolComponents = memo((props) => {
  const { option } = props
  const userData = useSelector((state) => state?.user?.user?.getUser)
  return (
    <Typography>
      {userData?.other?.displayCryptoInFlat
        ? currencySymbol?.[option?.symbol === 'Rp' ? 'Rp' : userData?.other?.faitCurrencyCode]
        : currencySymbol?.[option?.symbol]}
    </Typography>
  )
})

export default SymbolComponents
