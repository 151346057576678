import { createSlice } from '@reduxjs/toolkit'
import { currencyCodeUpdateRequest, depositAmountRequest } from 'redux-thunk/thunk/user.thunk'
import { getAllBanks, getLiminalApproxFee, getPaymentCode, getPaymentMethods } from 'redux-thunk/thunk/wallet.thunk'

const initialState = {
  depositData: null,
  showWalletPopup: false,
  depositCurrency: 'default',
  walletCurrency: 'default',
  updateCurrencyCodeData: [],
  updateCurrencyCodeDataLoader: false,
  walletCurrencyLimit: null,
  allBanks: {},
  allPaymentMethods: {},
  getPaymentCodeData: {},
  depositePaymentMethod: '',
  withdrawalFee: undefined,
  filteredCurrencData: [],
  withdrawalFeeLoader: false
}
const {
  actions: {
    setDepositData,
    setShowWalletPopup,
    setDepositCurrency,
    setWalletCurrency,
    setWalletCurrencyLimit,
    setPaymentCodeData,
    setDepositePaymentMethod,
    setWithdrawalFee,
    setFilteredCurrencyData,
    setUpdateCurrencyCodeDataLoader

  },
  reducer
} = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setShowWalletPopup: (state, action) => ({
      ...state,
      showWalletPopup: action.payload
    }),
    setDepositData: (state, action) => ({
      ...state,
      depositData: action.payload
    }),
    setDepositCurrency: (state, action) => ({
      ...state,
      depositCurrency: action?.payload
    }),
    setWalletCurrency: (state, action) => ({
      ...state,
      walletCurrency: action?.payload
    }),
    setWalletCurrencyLimit: (state, action) => ({
      ...state,
      walletCurrencyLimit: action?.payload
    }),
    setPaymentCodeData: (state, action) => ({
      ...state,
      getPaymentCodeData: {}
    }),
    setDepositePaymentMethod: (state, action) => ({
      ...state,
      depositePaymentMethod: action?.payload
    }),
    setWithdrawalFee: (state, action) => ({
      ...state,
      withdrawalFee: action?.payload,
      withdrawalFeeLoader: false
    }),
    setFilteredCurrencyData: (state, action) => ({
      ...state,
      filteredCurrencData: action?.payload || []
    }),
    setUpdateCurrencyCodeDataLoader: (state, action) => ({
      ...state,
      updateCurrencyCodeDataLoader: action.payload
    })

  },
  extraReducers: (builder) => {
    builder
      .addCase(depositAmountRequest.fulfilled, (state, action) => {
        return {
          ...state,
          depositData: action.payload
        }
      })
      .addCase(currencyCodeUpdateRequest.fulfilled, (state, action) => {
        return {
          ...state,
          updateCurrencyCodeData: action.payload
        }
      })
      .addCase(getAllBanks.fulfilled, (state, action) => {
        return {
          ...state,
          allBanks: action?.payload
        }
      })
      .addCase(getAllBanks.rejected, (state, action) => {
        return {
          ...state,
          allBanks: action?.payload
        }
      })
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        return {
          ...state,
          allPaymentMethods: action.payload
        }
      })
      .addCase(getPaymentMethods.rejected, (state, action) => {
        return {
          ...state,
          allPaymentMethods: action.payload
        }
      })
      .addCase(getPaymentCode.fulfilled, (state, action) => {
        return {
          ...state,
          getPaymentCodeData: action.payload
        }
      })
      .addCase(getPaymentCode.rejected, (state, action) => {
        return {
          ...state,
          getPaymentCodeData: action.payload
        }
      })
      .addCase(getLiminalApproxFee.fulfilled, (state, action) => {
        return {
          ...state,
          withdrawalFee: action?.payload?.approxFees,
          withdrawalFeeLoader: false
        }
      })
      .addCase(getLiminalApproxFee.rejected, (state, action) => {
        return {
          ...state,
          withdrawalFee: undefined,
          withdrawalFeeLoader: false
        }
      })
      .addCase(getLiminalApproxFee.pending, (state, action) => {
        return {
          ...state,
          withdrawalFeeLoader: true
        }
      })
  }
})

export default reducer
export {
  setDepositData,
  setShowWalletPopup,
  setDepositCurrency,
  setWalletCurrency,
  setWalletCurrencyLimit,
  setPaymentCodeData,
  setDepositePaymentMethod,
  setWithdrawalFee,
  setFilteredCurrencyData,
  setUpdateCurrencyCodeDataLoader
}
