import React, { memo, useCallback, useState } from 'react'
import { Box, TextField } from '@mui/material'
import { SearchNewIcon } from 'assets/icons/index'
import { useDispatch, useSelector } from 'react-redux'
import { setFilteredCurrencyData } from 'redux-thunk/redux/slices/wallet.slice'

const filterData = (originalData, searchedValue) => {
  return originalData?.filter((data) =>
    data?.code?.toLowerCase().trim().includes(searchedValue?.toLowerCase().trim())
  )
}

const SearchComponent = memo((props) => {
  const userData = useSelector((state) => state?.user?.user?.getUser)
  const [searchedValue, setSearchedValue] = useState('')
  const dispatch = useDispatch()

  const arrForRenderList = (arr) => {
    const mappedArrData = arr?.map((currencydata) => {
      const result = {
        ...currencydata?.currency,
        amount: currencydata.amount
      }
      return result
    })
    return mappedArrData
  }

  const renderCurrencyArr = useCallback(() => {
    if (userData?.other?.hideZero) {
      const filterZeroAmountCurrency = userData?.userWallets && userData?.userWallets?.filter((currencyCodeDetails, index) => currencyCodeDetails?.amount !== 0)
      return arrForRenderList(filterZeroAmountCurrency)
    }
    return arrForRenderList(userData?.userWallets)
  }, [userData])

  const handleSearch = useCallback((event) => {
    const newSearchedValue = event.target.value
    setSearchedValue(newSearchedValue)
    const filteredData = filterData(renderCurrencyArr(), newSearchedValue)
    dispatch(setFilteredCurrencyData(filteredData))
  }, [setSearchedValue, renderCurrencyArr, filterData])

  return (
    <Box className='search-wrap'>
      <Box className='input-holder'>
        <TextField
          value={searchedValue}
          onChange={handleSearch}
          placeholder='Search Currencies'
          onClick={(e) => e.stopPropagation()}
        />
        <Box className='icon-box'>
          <SearchNewIcon />
        </Box>
      </Box>
    </Box>
  )
})

export default SearchComponent
