import { Box, styled } from '@mui/material'

export const FooterMenuWrapper = styled(Box)(({ theme }) => {
  return {
    '& .footer-menu-list': {
      backgroundColor: '#0b0116',
      position: "fixed",
      bottom: "0",
      zIndex: 999,
      left: "0",
      width: "100%",
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",

      'span' :{
        color: 'white',
        fontSize: '10px',
      },

      'button' :{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },

      '& button':{
        height:'60px',
        borderRadius:theme.spacing(0.5),

        '& svg':{
          width:'15px',
          height:'15px',
          filter: 'invert(100%) sepia(100%) saturate(2%) hue-rotate(40deg) brightness(108%) contrast(100%)',
        }
      },
    },

    [theme.breakpoints.up(1024)]: {
      display: 'none',
    }
  }
})
