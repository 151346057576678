import { useTheme } from '@mui/material';
import { StyledMenu } from './style';

export const CustomMainMenu = ({ id, open, onClose, children, transformOrigin, anchorOrigin, anchorEl }) => {
  const theme = useTheme();
  return (
    <StyledMenu id={id} anchorEl={anchorEl} theme={theme} open={open} transformOrigin={transformOrigin} anchorOrigin={anchorOrigin} onClose={onClose}>
      {children}
    </StyledMenu>
  );
};
