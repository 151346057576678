import React, { useEffect, useState, lazy } from 'react'
import { Buffer } from 'buffer'
import { useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { browserFingerPrint } from 'utils/browserFingerprint'
import { userSigninSchema } from './schema'
import { userSignUp } from 'redux-thunk/thunk/auth.thunk'


const AuthStep = lazy(() => import('./SignupWizard/AuthStep'));

const Signup = () => {
  const [acceptConditions, setAcceptConditions] = useState({
    termsAndConditions: false,
    userAgreement: false
  })
  const [affiliateCode, setAffiliateCode] = useState('')
  const [alertBoxopen, setAlertBoxOpen] = useState(false)
  const [apiError, setApiError] = useState({
    name: '',
    message: ''
  })
  const dispatch = useDispatch()

  const onSignupError = (error) => {
    if (error?.[0]?.name === 'EmailAlreadyExists') {
      setApiError({ name: error?.[0]?.name, message: t('emailAlreadyExists') })
    } else if (error?.[0]?.name === 'UserNameExists') {
      setApiError({ name: error?.[0]?.name, message: t('usernameAlreadyExists') })
    } else {
      setApiError({ name: error?.[0]?.name, message: t(error?.[0]?.description) })
    }
    setAlertBoxOpen(true)
  }

  const { t } = useTranslation()

  const handleSubmitValidate = (formValues) => {
    const formDataFields = { ...formValues }
    if (!(formDataFields?.termsAndConditions)) {
      const message = t('Please check Conditions / Agreement before signup')
      setAlertBoxOpen(true)
      setApiError({ name: 'termsAndConditions', message: t(message) })
      return
    }
    setAlertBoxOpen(false)
    const encryptedPass = Buffer.from(formDataFields.password).toString('base64')
    formDataFields.password = encryptedPass
    formDataFields.confirmPassword && (formDataFields.confirmPassword = encryptedPass)
    formDataFields.uniqueKey = browserFingerPrint()
    formDataFields.termsAndConditions = acceptConditions.termsAndConditions
    formDataFields.code = affiliateCode || formDataFields.referralCode || ''
    signUpHandler(formDataFields)
  }

  const signUpHandler = (formDataFields) => {
    const signupPayload = { userDetails: formDataFields, t, onSignupError }
    dispatch(userSignUp(signupPayload))
  }

  useEffect(() => {
    const storedData = localStorage.getItem('affiliateCode')
    if (storedData) {
      setAffiliateCode(storedData)
    }
  }, [])

  const isRequired = (fieldName) => fieldName === 2

  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={{
          email: '',
          password: '',
          username: '',
          confirmPassword: '',
          countryCode: '',
          dateOfBirth: '',
          day: '',
          month: '',
          year: '',
          referralCode: ''
        }}
        validationSchema={userSigninSchema(t)}
        validateOnMount
        validateOnBlur
        validateOnChange
        onSubmit={(formValues) => handleSubmitValidate(formValues)}
      >
        {({
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          errors,
          touched,
          setFieldValue,
          setFieldTouched
        }) => {
          return (
            <Form autoComplete='off'>
              <AuthStep
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                isRequired={isRequired}
                acceptConditions={acceptConditions}
                setAcceptConditions={setAcceptConditions}
                apiError={apiError}
                setAlertBoxOpen={setAlertBoxOpen}
                alertBoxopen={alertBoxopen}
              />
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default Signup
