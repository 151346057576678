import * as Yup from 'yup'
import moment from 'moment'

export const userSigninSchema = (t) =>
  Yup.object().shape({
    email:
      Yup.string().email(t('invalidEmail')).max(50).required(t('emailRequired')),
    password:
      Yup.string()
        .min(8, t('min8Characters'))
        .max(50, t('max50Characters'))
        .test('no-common-words', t('commonWordsNotAllowed'), (value) => {
          const commonWords = ['password@123', '123456', 'qwerty'] // Add more common words as needed
          return !commonWords.includes(value?.toLowerCase())
        })
        .test('no-spaces', t('noSpaceAllow'), (value) => {
          // Check if the password does not contain spaces
          return !/\s/.test(value)
        })
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
          t('passwordTip')
        )
        .required(t('passwordRequired')),

    confirmPassword:
      Yup.string()
        .oneOf([Yup.ref('password'), null], t('matchPassword'))
        .required(t('confirmPasswordRequired')),

    username: Yup.string().max(20, t('max20Characters'))
      .min(3, t('min3Characters'))
      .matches(/^[A-Za-z][^\s]*$/, t('userNameAllowed'))
      .required(t('userNameRequired')),

    dateOfBirth: Yup.string()
      .required(t('dateOfBirthRequired'))
      .test('is-valid-date', t('invalidDate'), function (value) {
        const dateOfBirth = moment(value, 'MM/DD/YYYY', true)
        return dateOfBirth.isValid()
      })
      .test('is-over-18', t('mustBe18'), function (value) {
        const dateOfBirth = moment(value, 'MM/DD/YYYY', true)
        const eighteenYearsAgo = moment().subtract(18, 'years')
        return dateOfBirth.isBefore(eighteenYearsAgo)
      }),

    year: Yup.string()
      .test('valid-date', t('yearShouldBeBetween', {
        from: new Date().getFullYear() - 100,
        to: new Date().getFullYear() - 18
      }), function (value) {
        if (!value) {
          return true
        }
        const currentYear = new Date().getFullYear()
        const minAllowedYear = currentYear - 100
        const maxAllowedYear = currentYear - 18
        const enteredYear = parseInt(value, 10)
        return (
          !isNaN(enteredYear) &&
          enteredYear >= minAllowedYear &&
          enteredYear <= maxAllowedYear
        )
      }),
    countryCode: Yup.string().required(t('countryRequired'))
  })
