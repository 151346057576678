export const currencySymbol = {
  USDT: '₮',
  DOGE: 'Ð',
  Rp: 'Rp',
  IDR: 'Rp',
  ETH: 'Ξ',
  LTC: 'Ł',
  BTC: '₿',
  TRX: 'T',
  INR: '₹',
  USD: '$',
  JPY: '¥',
  EUR: '€',
  TRY: '₺'
}
